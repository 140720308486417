<template>
<transition name="modal">
<div class="modal-container">
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" v-if="showHeader">
          <button v-if="showTopDismissButton"
              :class="topDismissButtonClass"
              @click="$emit('dismiss')">
            {{topDismissButtonLabel}}
          </button>
          <slot name="header">
            <h2 class="title">{{title}}</h2>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="showFooter">
          <slot name="footer-top"></slot>
          <slot name="footer">
            <button class="btn btn-lg"
                :class="bottomCloseButtonClass"
                :disabled="isCloseButtonBusy"
                @click="$emit('close')"
                v-if="showBottomCloseButton">
              <i class="fa fa-spinner fa-spin"
                v-show="isCloseButtonBusy"></i>
              <i class="fa" v-if="showBottomCloseButtonIcon"
                :class="bottomCloseButtonIconClass"
                v-show="!isCloseButtonBusy"></i>
              <span v-show="!isCloseButtonBusy">
                {{bottomCloseButtonLabel}}
              </span>
            </button>
            <button class="btn btn-lg btn-default"
              @click="$emit('dismiss')"
              v-if="showBottomDismissButton">
              {{bottomDismissButtonLabel}}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop">
  </div>
</div>
</transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },

    showHeader: {
      type: Boolean,
      default: true,
    },
    showTopDismissButton: {
      type: Boolean,
      default: false,
    },
    topDismissButtonLabel: {
      type: String,
      default: 'x',
    },
    topDismissButtonClass: {
      type: String,
      default: 'close'
    },
    isCloseButtonBusy: {
      type: Boolean,
      default: false,
    },

    showFooter: {
      type: Boolean,
      default: true,
    },
    showBottomCloseButton: {
      type: Boolean,
      default: true,
    },
    bottomCloseButtonLabel: {
      type: String,
      default: 'OK',
    },
    bottomCloseButtonClass: {
      type: String,
      default: 'btn-primary',
    },
    showBottomCloseButtonIcon: {
      type: Boolean,
      default: false,
    },
    bottomCloseButtonIconClass: {
      type: String,
      default: 'fa-trash',
    },
    showBottomDismissButton: {
      type: Boolean,
      default: true,
    },
    bottomDismissButtonLabel: {
      type: String,
      default: 'キャンセル',
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-container {
    font-size: 18px;
  }
  .modal {
    display: block;
  }
  .title {
    margin: 6px 0;
  }
  .modal-backdrop {
    opacity: 0.5;
  }

  .modal-enter {
    opacity: 0;
  }
  .modal-enter-active {
    transition: opacity .0s ease;
  }
  .modal-enter-to {
    opacity: 1.0;
  }
  .modal-leave-active {
    opacity: 0;
  }
</style>
