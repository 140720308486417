<template src="./template.html"></template>

<script>
import Vue from 'vue'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import templateApi from '@/api/template'
import { mapState } from 'vuex'

export default {
  name: 'damageTypeTemplateDetail',
  data() {
    return {
      list: [],

      showCreateModal: false,
      showUpdateModal: false,
      createEntry: {},

      showDeleteConfirmModal: false,
      deleteEntry: {},

      selectedEntry: {},
      selectedIndex: null,

      errors: {},

      splitMark: '@@',
    }
  },
  computed: {
    ...mapState('user', {
      hasRoleSuperAdmin: state => state.has_role_super_admin,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const acc = []
      const tmpMap = {}
      const ret = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      acc.forEach(e => {
        if (tmpMap[e]) { return }
        tmpMap[e] = true
        ret.push(e)
      })
      return ret
    },
  },
  mounted() {
    this.waitForMasters().then(() => {
      this.list = this.convDataByTemplate(window.master.damage_type_templates)
    })
  },
  mixins: [masterMixin, errorHandleMixin],
  methods: {
    convDataByTemplate(entry) {
      let objEntry = Object.assign(entry)
      objEntry = objEntry.slice()
      // 各列の表示文字列を変換
      objEntry.forEach(row => {
        if (row.template_name !== '') {
          // 編集モードかどうか
          row.isEditMode = false

          row.disp_order_disp = row.disp_order
          row.template_name_disp = row.template_name
          row.template_value_disp = row.template_value
          const spletValue = row.template_value.split(this.splitMark)
          entry.template_value_1 = ''
          entry.template_value_2 = ''
          entry.template_value_3 = ''
          entry.template_value_4 = ''
          entry.template_value_5 = ''
          entry.template_value_6 = ''
          entry.template_value_1_disp = ''
          entry.template_value_2_disp = ''
          entry.template_value_3_disp = ''
          entry.template_value_4_disp = ''
          entry.template_value_5_disp = ''
          entry.template_value_6_disp = ''
          if (spletValue.length > 0) {
            row.template_value_1 = spletValue[0]
            row.template_value_1_disp = spletValue[0]
          }
          if (spletValue.length > 1) {
            row.template_value_2 = spletValue[1]
            row.template_value_2_disp = spletValue[1]
          }
          if (spletValue.length > 2) {
            row.template_value_3 = spletValue[2]
            row.template_value_3_disp = spletValue[2]
          }
          if (spletValue.length > 3) {
            row.template_value_4 = spletValue[3]
            row.template_value_4_disp = spletValue[3]
          }
          if (spletValue.length > 4) {
            row.template_value_5 = spletValue[4]
            row.template_value_5_disp = spletValue[4]
          }
          if (spletValue.length > 5) {
            row.template_value_6 = spletValue[5]
            row.template_value_6_disp = spletValue[5]
          }
        }
      })
      return objEntry
    },
    async doCSVDownload() {
      try {
        const { data } = await templateApi.downloadCSV()
        const timestamp = Vue.filter('dtFormat')(new Date(), 'yyyymmddHHMMSS')
        const filename = `点検結果定型文(緊急点検)一覧_${timestamp}.csv`
        this.downloadBlobAsFile(data, filename)
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    downloadBlobAsFile(blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    },
    clearErrors() {
      this.errors = {}
    },
    checkEntry(entry) {
      this.clearErrors()
      let ret = true
      if (!entry.template_name_edit) {
        this.errors.key = ['テンプレート名は必須項目です']
        ret = false
      }
      if (!(entry.template_value_2_edit || entry.template_value_3_edit ||
        entry.template_value_4_edit || entry.template_value_5_edit)) {
        this.errors.key = ['調査方法、調査範囲、調査結果、応急処理のいずれかを入力してください']
        ret = false
      }

      return ret
    },
    getReqObjByTemplate(entry) {
      const dataObj = {}
      dataObj.id = entry.id
      entry.disp_order = entry.disp_order_edit
      entry.disp_order_disp = entry.disp_order_edit
      dataObj.disp_order = entry.disp_order_edit
      dataObj.disp_order_disp = entry.disp_order_edit
      entry.template_name = entry.template_name_edit
      entry.template_name_disp = entry.template_name_edit
      dataObj.template_name = entry.template_name_edit
      dataObj.template_name_disp = entry.template_name_edit
      if (entry.template_value_1_edit === undefined) {
        entry.template_value_1_edit = ''
      }
      if (entry.template_value_2_edit === undefined) {
        entry.template_value_2_edit = ''
      }
      if (entry.template_value_3_edit === undefined) {
        entry.template_value_3_edit = ''
      }
      if (entry.template_value_4_edit === undefined) {
        entry.template_value_4_edit = ''
      }
      if (entry.template_value_5_edit === undefined) {
        entry.template_value_5_edit = ''
      }
      if (entry.template_value_6_edit === undefined) {
        entry.template_value_6_edit = ''
      }
      entry.template_value = entry.template_value_1_edit + this.splitMark +
        entry.template_value_2_edit + this.splitMark +
        entry.template_value_3_edit + this.splitMark +
        entry.template_value_4_edit + this.splitMark +
        entry.template_value_5_edit + this.splitMark +
        entry.template_value_6_edit

      entry.template_value_disp = entry.template_value
      dataObj.template_value = entry.template_value
      dataObj.template_value_disp = entry.template_value
      dataObj.created_at = entry.created_at_edit
      dataObj.updated_at = entry.updated_at_edit
      return {
        id: dataObj.id,
        data: dataObj,
      }
    },
    reOrderList() {
      this.list = this.list.sort((a, b) => {
        const v1 = a.disp_order
        const v2 = b.disp_order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
    },
    showAddEntryModal() {
      this.clearErrors()
      this.createEntry = {
        created_at_edit: new Date(),
        updated_at_edit: new Date(),
      }
      this.showCreateModal = true
    },
    doAddEntry() {
      if (!this.checkEntry(this.createEntry)) { return }
      const reqObj = this.getReqObjByTemplate(this.createEntry)
      templateApi.create({ data: reqObj.data })
        .then(({ data }) => {
          reqObj.data.id = data.id
          this.list.push(reqObj.data)
          this.reOrderList()
          this.showCreateModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    startEditEntry(entry, i) {
      this.clearErrors()
      entry.disp_order_edit = entry.disp_order
      entry.template_name_edit = entry.template_name
      const spletValue = entry.template_value.split(this.splitMark)
      entry.template_value_1_edit = ''
      entry.template_value_2_edit = ''
      entry.template_value_3_edit = ''
      entry.template_value_4_edit = ''
      entry.template_value_5_edit = ''
      entry.template_value_6_edit = ''

      if (spletValue.length > 0) {
        entry.template_value_1_edit = spletValue[0]
      }
      if (spletValue.length > 1) {
        entry.template_value_2_edit = spletValue[1]
      }
      if (spletValue.length > 2) {
        entry.template_value_3_edit = spletValue[2]
      }
      if (spletValue.length > 3) {
        entry.template_value_4_edit = spletValue[3]
      }
      if (spletValue.length > 4) {
        entry.template_value_5_edit = spletValue[4]
      }
      if (spletValue.length > 5) {
        entry.template_value_6_edit = spletValue[5]
      }
      entry.created_at_edit = entry.created_at
      entry.updated_at_edit = new Date()
      this.createEntry = entry
      this.selectedIndex = i
      this.showUpdateModal = true
    },
    saveEntry() {
      if (!this.checkEntry(this.createEntry)) { return }
      this.createEntry.updated_at = new Date()
      const reqObj = this.getReqObjByTemplate(this.createEntry)
      templateApi.update({ id: reqObj.data.id, data: reqObj.data })
        .then(({ data }) => {
          this.endEditEntry(this.createEntry)
          Vue.set(this.list, this.selectedIndex, this.createEntry)
          this.reOrderList()
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
      this.createEntry.isEditMode = false
      this.showUpdateModal = false
    },
    endEditEntry(entry) {
      entry.isEditMode = false
    },
    confirmDeleteEntry(entry) {
      this.clearErrors()
      this.deleteEntry = entry
      this.showDeleteConfirmModal = true
    },
    doDeleteEntry() {
      const reqObj = {
        id: this.deleteEntry.id
      }
      templateApi.delete(reqObj)
        .then(({ data }) => {
          let idx = 1
          this.list.forEach((ent, i) => {
            if (ent.template_name === this.deleteEntry.template_name) {
              idx = i
            }
          })
          if (idx !== -1) {
            Vue.delete(this.list, idx)
          }
          this.deleteEntry = {}
          this.showDeleteConfirmModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    cutDisplayString(value) {
      let tmpValue = value
      if ((typeof tmpValue) === 'string')
      {
        if (tmpValue.length >= 15) {
          tmpValue = tmpValue.substring(0, 15) + '...'
        }
        tmpValue = tmpValue.replaceAll(this.splitMark, ':')
      }
      return tmpValue
    },
    writeToClipboard(elementId) {
      const copy_target = document.querySelector('#' + elementId)
      const clipData = copy_target.value
      copy_target.value = '${' + clipData + '}'
      copy_target.select()
      document.execCommand('copy')
      copy_target.value = clipData
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped>
</style>
