<template src="./template.html"></template>

<script>
import Vue from 'vue'
import workResultTachographApi from '@/api/workResultTachograph'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'workResultTachographDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const ret = {
      report: {
        other_props: {},
        report_header: {},
      },
      showUpdateCompleteModal: false,

      crewNumber: '1',

      reportApi: workResultTachographApi,

      tachographArticles: [],
      articleNumDisps: ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'],

      errors: {},
    }
    return Object.assign({}, ret)
  },
  computed: {
    dt1Disp() {
      return this.report.report_header.dt
    },
    dt2Disp() {
      return this.getNightShiftDate(
        this.report.report_header.timeslot,
        this.report.report_header.dt
      )
    },
    hasInputTeam2() {
      return !!this.report.report_header.inspector2
    },
    inspectorDisp() {
      return this.report.report_header[`inspector${this.crewNumber}`]
    },
    driverDisp() {
      return this.report.report_header[`driver${this.crewNumber}`]
    },
    workStartDisp() {
      return this.report.report_header[`work_start${this.crewNumber}`]
    },
    workEndDisp() {
      return this.report.report_header[`work_end${this.crewNumber}`]
    },
    article1FreeInputMetaInfo() {
      return this.getArticleFreeInputMetaInfo('article1')
    },
    article2FreeInputMetaInfo() {
      return this.getArticleFreeInputMetaInfo('article2')
    },
    articleSelectInputMaxNum() {
      return this.articleNumDisps.length
    },
  },
  mounted() {
    this.getMasters()
    this.getReport_()
  },
  mixins: [masterMixin, redDetailPageMixin, errorHandleMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.tachographArticles = lovs.tachograph_article.vals_inuse
      })
    },
    convReport_(data) {
      data.other_props = data.other_props || {}

      this.getNightShiftDate(data, 'dt2',
        data.report_header.timeslot,
        data.report_header.dt)

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows]) => {
        const prop = `${propPart}_free_input`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        const rowCountProp = `${prop}_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)
        if (
          propPart === 'article1' ||
          propPart === 'article2'
        ) {
          // 記事
          // 1行目(高速番号)と2行目以降に分割する
          const metaInfoProp = `${prop}_meta_info`
          const metaInfo = {
            hwNumTextInput: rows[0], // 1行目(高速番号)
            selectInputs: rows.slice(1), // 2行目以降
          }
          data.other_props[metaInfoProp] = metaInfo
        }
      })

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })
    },
    getFreeInputMetaParams() {
      return [
        ['inspection_route1', 4],
        ['inspection_time1', 2],
        ['mileage1', 2],
        ['article1', 3],
        ['inspection_route2', 4],
        ['inspection_time2', 2],
        ['mileage2', 2],
        ['article2', 3],
      ]
    },
    async saveReport() {
      // 記事
      // 1行目(高速番号)と2行目以降(自由入力)を結合する
      const articlePropParts = ['article1', 'article2']
      articlePropParts.forEach(propPart => {
        const articleFreeInputMetaInfo = this.getArticleFreeInputMetaInfo(propPart)
        const val1 = articleFreeInputMetaInfo.hwNumTextInput
        const val2 = articleFreeInputMetaInfo.selectInputs
        const prop = `${propPart}_free_input`
        this.report[prop] = [val1, val2].flat().join('\n')
      })

      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    onClickSwitchTeam() {
      Vue.set(this, 'crewNumber', this.crewNumber === '1' ? '2' : '1')
    },
    isCrew1() {
      return this.crewNumber === '1'
    },
    isCrew2() {
      return !this.isCrew1()
    },
    addArticle(propPart) {
      const articleFreeInputMetaInfo = this.getArticleFreeInputMetaInfo(propPart)
      if (articleFreeInputMetaInfo.selectInputs.length === this.articleSelectInputMaxNum) { return }

      const defaultArticleName = this.tachographArticles[0].name
      articleFreeInputMetaInfo.selectInputs.push(defaultArticleName)
    },
    removeArticle(propPart, idx) {
      const articleFreeInputMetaInfo = this.getArticleFreeInputMetaInfo(propPart)
      if (articleFreeInputMetaInfo.selectInputs.length === 0) { return }

      articleFreeInputMetaInfo.selectInputs.splice(idx, 1)
    },
    getArticleFreeInputMetaInfo(propPart) {
      const metaInfoProp = `${propPart}_free_input_meta_info`
      return this.report.other_props[metaInfoProp] || {
        hwNumTextInput: '',
        selectInputs: [],
      }
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
