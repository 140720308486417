<template src="./template.html"></template>

<script>
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'
let api

export default {
  name: 'print-photos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reporteeDepMap: {},

      meta: {},

      photos: [],
      photoMap: [],
      pages: [],
    }
  },
  computed: {
    showHeader() {
      return [
        'emergencyInspectReport',
      ].includes(this.apiName)
    },
  },
  async mounted() {
    api = require(`@/api/${this.apiName}`).default
    if (this.apiName === 'emergencyInspectReport') {
      await this.waitForMasters()
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
    }
    this.getData()
  },
  mixins: [masterMixin, errorHandleMixin],
  methods: {
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    getData() {
      return api.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.photos.forEach(photo => {
            if (photo.used_index !== null) {
              photo.idx = photo.used_index
            }
            photo.orig_caption = photo.caption
          })
          if (this.apiName === 'emergencyInspectReport') {
            data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)
            if (data.reportee_dep2) {
              data.reporteeDepDisp2 = this.reporteeDepDisp(data.reportee_dep2)
            }
          }
          this.meta = data
          this.photos = data.photos
          this.photoMap = this.photos.reduce((obj, photo) => {
            obj[photo.id] = photo; return obj
          }, {})
          this.pages = this.getPages(data)
        })
    },
    getEmptyPage(idx) {
      return {
        idx: idx,
        is_empty: true,
        caption: '',
        orig_caption: '',
      }
    },
    getPages(data) {
      const ret = []
      const usedPhotoIds = this.photos.reduce((arr, photo) => {
        if (photo.used_index !== null) {
          arr[photo.used_index] = photo.id
        }
        return arr
      }, [])
      const len = usedPhotoIds.length
      if (len === 0) {
        ret.push({
          photos: [
            this.getEmptyPage(0),
            this.getEmptyPage(1),
          ]
        })
        return ret
      }

      const halfLen = Math.ceil(len / 2)
      for (let i = 0; i < halfLen; i++) {
        const idxBase = i * 2
        let photo1 = this.photoMap[usedPhotoIds[idxBase]]
        if (!photo1) {
          photo1 = this.getEmptyPage(idxBase)
        }
        let photo2 = (idxBase + 1 < len)
          ? this.photoMap[usedPhotoIds[idxBase + 1]]
          : this.getEmptyPage(idxBase + 1)
        if (!photo2) {
          photo2 = this.getEmptyPage(idxBase + 1)
        }

        ret.push({photos: [photo1, photo2]})
      }
      return ret
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
