<template src="./template.html"></template>

<script>
import Vue from 'vue'
import emergencyContactReportApi from '@/api/emergencyContactReport'
import EVT from '@/consts/eventNames'
import Stream from '@/lib/Stream'
import masterMixin from '@/mixin/masterMixin'
import detailPageMixin from '@/mixin/detailPageMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'emergencyContactDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const detailPageFlagSet = this.getDetailPageFlagSet()
    const ret = {
      // streams
      stream: new Stream(), // mine
      reportHeaderStream: new Stream(),

      report: {
        other_props: {},
        vehiclesUsedConv: [],
      },
      reporteeGroups: [],
      reporteeGroupMap: {},
      reporteeDeps: [],
      reporteeDepMap: {},
      ranks: [],
      ranksTexts: {},
      targetFacilities: [],
      facilityStates: [],
      recoveryActions: [],
      requestorGroups: [],
      requestorOffices: [],
      repairCompanyGroups: [],
      repairCompanyServiceTypes: [],
      personOfCauses: [],
      digestResults: [],
      regulationTypes: [],
      vehicles: [],
      weathers: [],
      highwayNames: [],
      highwayDirections: [],
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      showConfirmDigestOverwriteModal: false,

      reportApi: emergencyContactReportApi,

      errors: {},

      keishous: ['', '氏', '殿'],
    }
    return Object.assign({}, detailPageFlagSet, ret)
  },
  computed: {
    reporteeDepDisp1() {
      return (this.reporteeDepMap[this.report.reportee_dep1] || {}).name
    },
    reporteeDep1ForFilter() {
      return this.reporteeDepMap[this.report.reportee_dep1]
    },
    reportNumPrefixDisp() {
      if (!this.report.damage_rank) { return }

      const selectedRankPrf = this.report.damage_rank.substring(0, 1)
      const reporteeDepInfo = this.reporteeDepMap[this.report.reportee_dep1]
      if (!reporteeDepInfo) { return }
      const reporteeGrpInfo = this.reporteeGroupMap[reporteeDepInfo.reportee_group]
      if (!reporteeGrpInfo) { return }

      return selectedRankPrf === 'A' || selectedRankPrf === 'B' ? reporteeGrpInfo.disp1 : reporteeGrpInfo.disp2
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
    showRequestorOffice() {
      return this.report.requestorGroup === 'office'
    },
  },
  mounted() {
    this.getMasters()
    this.setListeners()

    if (this.$route.name === 'emergencyContactNew') {
      this.setNewDetailPageMode(true)
      this.prepareNewReport_()
    } else if (this.$route.name === 'emergencyContactCopy') {
      this.setNewDetailPageMode(true)
      this.setCopyMode(true)
      this.getCopiedNewReport_()
    } else {
      this.setNewDetailPageMode(false)
      this.getReport_()
    }

    this.connectStreams()
  },
  mixins: [masterMixin, detailPageMixin, errorHandleMixin, validatorMixin, dateMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.reporteeGroups = lovs.reportee_group.vals_inuse
        this.reporteeGroupMap = lovs.reportee_group.map
        this.reporteeDeps = lovs.reportee_dep.vals_inuse
        this.reporteeDepMap = lovs.reportee_dep.map
        this.targetFacilities = lovs.target_facility.vals_inuse
        this.facilityStates = lovs.facility_state.vals_inuse
        this.recoveryActions = lovs.recovery_action.vals_inuse
        // 名前に「営業所」を含まない依頼組織のみを抽出
        this.requestorGroups = lovs.requestor_facility.vals_inuse.filter(e => !e.name.includes('営業所'))
        // 名前に「営業所」を含む依頼組織のみを抽出
        this.requestorOffices = lovs.requestor_facility.vals_inuse.filter(e => e.name.includes('営業所'))
        this.repairCompanyGroups = lovs.repair_company_group.vals_inuse
        this.repairCompanyServiceTypes =
         lovs.repair_company_service_type.vals_inuse
        this.personOfCauses = lovs.person_of_cause.vals_inuse
        this.digestResults = lovs.digest_result.vals_inuse
        this.regulationTypes = lovs.regulation_type.vals_inuse
        this.vehicles = lovs.vehicles_used.vals_inuse
        this.weathers = lovs.weather.vals_inuse
        this.highwayNames = lovs.highway.vals_inuse
        this.highwayDirections = lovs.highway_direction_type.vals_inuse
        this.digestResults = lovs.digest_result.vals_inuse

        // ランク内容をランクごとにオブジェクトに保存
        const ranksTexts = {}
        lovs.damage_rank_text.vals_inuse.forEach(e => {
          const mapKey = e.damage_rank
          if (!ranksTexts[mapKey]) { ranksTexts[mapKey] = [] }
          ranksTexts[mapKey].push(e)
        })
        this.ranksTexts = ranksTexts

        // ランク内容が定義されてるランクのみを抽出
        const rankKeys = Object.keys(ranksTexts)
        this.ranks = lovs.damage_rank.vals_inuse.filter(e => {
          return rankKeys.indexOf(e.key) !== -1
        })
      })
    },
    connectStreams() {
      const reportHeader = this.reportHeaderStream
      const me = this.stream
      reportHeader.chain(EVT.RH.SHOW_REPORT_BODY).to(me).end()
      reportHeader.chain(EVT.RH.UPDATE_COMPLETE).to(me).end()
    },
    setListeners() {
      this.stream.recv(EVT.RH.SHOW_REPORT_BODY, () => {
        this.detailPageOnShowReportBody()
      })
      this.stream.recv(EVT.RH.UPDATE_COMPLETE, obj => {
        this.detailPageOnReportHeaderUpdateComplete(obj)
      })
    },
    prepareNewReport_() {
      const obj = {}

      const now = new Date()

      obj.report_num1 = ''
      obj.report_header_id = -1
      obj.report_header = {}
      this.setDateProp(obj, 'report_dt', now)
      obj.reportee_dep1 = ''
      obj.manager_inspector_civil = ''
      obj.manager_inspector_facility = ''
      obj.crew_number = 1
      obj.inspector = ''

      obj.reportee_position_title = ''

      obj.target_facility = ''
      obj.facility_state = ''
      obj.recovery_action = ''

      this.setDateTimeProp(obj, 'occurence_dt', now)
      this.setDateTimeProp(obj, 'receive_dt', now)
      obj.incident_summary = ''

      obj.requestor_group = ''
      obj.requestorGroup = ''
      obj.requestorOffice = ''
      obj.requestorNamePart1 = ''
      obj.requestorNamePart2 = ''

      obj.occur_highway1_on_off_type = 'on'
      obj.occur_highway1_name = ''
      obj.occur_highway1_direction = ''
      obj.occur_highway1_info = {}

      obj.repairCompanyGroup1 = ''
      obj.repairCompanyName1Part1 = ''
      obj.repairCompanyName1Part2 = ''

      obj.repairCompanyGroup2 = ''
      obj.repairCompanyName2Part1 = ''
      obj.repairCompanyName2Part2 = ''

      obj.repair_company_service_type = ''
      obj.repair_company_request_dt = null
      obj.complete_dt = null
      obj.person_of_cause = ''
      obj.regulation_type = ''
      obj.vehicles_used = ''
      obj.vehiclesUsedConv = []
      obj.damage_rank = 'C1'
      obj.damage_rank_text = '後日補修依頼'
      obj.damageRankConv = `${obj.damage_rank}#${obj.damage_rank_text}`
      obj.digest_result = ''

      obj.result_free_input = ''
      obj.digest_free_input = ''
      const otherProps = {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows, defaultShow]) => {
        otherProps[`${propPart}_free_input_rows`] = defaultDispRows
        otherProps[`show_${propPart}_free_input`] = defaultShow
      })
      obj.other_props = otherProps

      obj.weather = ''

      this.report = obj
    },
    convReport_(data) {
      this.setDateProp(data, 'report_dt', data.report_dt)

      data.manager_inspector_civil =
        data.report_header.manager_inspector_civil
      data.manager_inspector_facility =
        data.report_header.manager_inspector_facility
      data.inspector =
        data.report_header[`inspector${data.crew_number}`]

      this.setDateTimeProp(data, 'report_dt', data.report_dt)
      this.setDateTimeProp(data, 'occurence_dt', data.occurence_dt)
      this.setDateTimeProp(data, 'receive_dt', data.receive_dt)
      this.setDateTimeProp(data, 'repair_company_request_dt', data.repair_company_request_dt)
      this.setDateTimeProp(data, 'complete_dt', data.complete_dt)

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      data.other_props = data.other_props || {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows, defaultShow]) => {
        const prop = `${propPart}_free_input`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        const rowCountProp = `${prop}_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)

        const showProp = `show_${prop}`
        if (!Object.prototype.hasOwnProperty.call(data.other_props, showProp)) {
          data.other_props[showProp] = defaultShow
        }
      })

      const vehiclesUsed = data.vehicles_used || ''
      data.vehiclesUsedConv = vehiclesUsed.split(',')

      const damageRank = data.damage_rank || ''
      const damageRankText = data.damage_rank_text || ''
      data.damageRankConv = `${damageRank}#${damageRankText}`

      const tmpRequestorGroup = data.requestor_group || ''
      let requestorGroup = ''
      let requestorOffice = ''
      const requestorFacilityDisp = Vue.filter('requestorFacilityDisp')
      if (requestorFacilityDisp(tmpRequestorGroup).includes('営業所')) {
        requestorGroup = 'office'
        requestorOffice = tmpRequestorGroup
      } else {
        requestorGroup = tmpRequestorGroup
      }
      data.requestorGroup = requestorGroup
      data.requestorOffice = requestorOffice

      // 途中からいれたので、古い形式のデータを救済
      const requestorName = data.requestor_name || ''
      const requestorNameParts = requestorName.split('###')
      if (requestorNameParts.length !== 2) {
        data.requestorNamePart1 = data.requestor_name
        data.requestorNamePart2 = ''
      } else {
        const [part1, part2] = requestorNameParts
        data.requestorNamePart1 = part1 || ''
        data.requestorNamePart2 = part2 || ''
      }

      // 途中からいれたので、古い形式のデータを救済
      const repairCompanyGroup = data.repair_company_group || ''
      const [repairCompanyGroup1, repairCompanyGroup2] = repairCompanyGroup.split(',')
      data.repairCompanyGroup1 = repairCompanyGroup1 || ''
      data.repairCompanyGroup2 = repairCompanyGroup2 || ''

      // 途中からいれたので、古い形式のデータを救済
      const repairCompanyName = data.repair_company_name || ''
      const repairCompanyNames = repairCompanyName.split(',')
      Array.from({ length: 2 }).forEach((_, idx) => {
        const repairCompanyName = repairCompanyNames[idx] || ''
        const repairCompanyNameParts = repairCompanyName.split('###')
        const [part1, part2] = repairCompanyNameParts
        data[`repairCompanyName${idx + 1}Part1`] = part1 || ''
        data[`repairCompanyName${idx + 1}Part2`] = part2 || ''
      })

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })
    },
    getCopiedNewReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.id = -1
          data.report_header_id = -1
          data.report_header = {}

          data.repair_company_request_dt = null
          data.complete_dt = null

          const now = new Date()
          const dateProps = [
            'report_dt',
            'occurence_dt',
            'receive_dt',
          ]
          dateProps.forEach(prop => {
            data[prop] = now
          })

          this.report = this.convReport_(data)
        })
    },
    onHighwayInputChange(hwObj) {
      const pfx = hwObj.pfx
      const origHwOnOffType = this.report[`${pfx}_on_off_type`]
      this.report[`${pfx}_on_off_type`] = hwObj.on_off_type
      this.report[`${pfx}_name`] = hwObj.name
      this.report[`${pfx}_direction`] = hwObj.direction
      this.report[`${pfx}_info`] = hwObj.info
      if (origHwOnOffType !== this.report[`${pfx}_on_off_type`]) {
        this.onHighwayOnOffTypeChange(hwObj)
      }
    },
    onHighwayOnOffTypeChange(hwObj) {
      // nothing to do
    },
    onRequestorGroupChange() {
      if (this.report.requestorGroup === 'office') {
        const requestorOffice = this.requestorOffices[0]
        this.report.requestorOffice = requestorOffice ? requestorOffice.key : ''
      } else {
        this.report.requestorOffice = ''
      }
      this.report.requestor_group =
        this.report.requestorOffice || this.report.requestorGroup
    },
    checkInputs({ isNew }) {
      this.errors = {}
      let prop

      prop = 'reportee_dep1'
      this.checkRequired(this.report[prop], prop, '報告先', this.errors)

      prop = 'report_dt'
      this.checkRequired(this.report[prop], prop, '報告日', this.errors)

      prop = 'occurence_dt'
      this.checkRequired(this.report[prop], prop, '発生時刻', this.errors)

      prop = 'receive_dt'
      this.checkRequired(this.report[prop], prop, '受信時刻', this.errors)

      prop = 'damage_rank'
      this.checkRequired(this.report[prop], prop, 'ランク', this.errors)

      prop = 'repair_company_request_dt'
      this.checkRequired(this.report[prop], prop, '依頼時刻', this.errors)

      prop = 'complete_dt'
      this.checkRequired(this.report[prop], prop, '終了時刻', this.errors)

      return Object.keys(this.errors).length === 0
    },
    convData_(report) {
      report.report_dt = Vue.filter('dtFormat')(report.report_dt, 'yyyy-mm-dd')

      report.vehicles_used = report.vehiclesUsedConv.filter(e => !!e).join(',')

      report.requestor_name = [
        (report.requestorNamePart1 || ''),
        (report.requestorNamePart2 || ''),
      ].join('###')

      const repairCompanyGroups = []
      const repairCompanyNames = []
      Array.from({ length: 2 }).forEach((_, idx) => {
        const repairCompanyGroup = report[`repairCompanyGroup${idx + 1}`] || ''
        let repairCompanyName = ''
        if (
          report[`repairCompanyName${idx + 1}Part1`] ||
          report[`repairCompanyName${idx + 1}Part2`]
        ) {
          repairCompanyName = [
            (report[`repairCompanyName${idx + 1}Part1`] || ''),
            (report[`repairCompanyName${idx + 1}Part2`] || ''),
          ].join('###')
        }

        repairCompanyGroups.push(repairCompanyGroup)
        repairCompanyNames.push(repairCompanyName)
      })
      report.repair_company_group = repairCompanyGroups.join(',')
      report.repair_company_name = repairCompanyNames.join(',')

      // conv free inputs
      const freeInputs = ['page2']
      freeInputs.forEach(propPart => {
        const prop = `${propPart}_free_input`
        if (!report.other_props[`show_${propPart}_free_input`]) {
          report[prop] = ''
        }
        report[prop] = (report[prop] || '')
      })
    },
    async createReport() {
      if (!this.checkInputs({isNew: true})) {
        return false
      }
      // 報告日を入れる
      this.report.report_dt = this.getNearestReportDt(this.report.repair_company_request_dt)

      this.convData_(this.report)
      const obj = { data: this.report }
      try {
        const { data } = await this.reportApi.create(obj)
        this.report = this.convReport_(data)
        this.showCreateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    async saveReport() {
      if (!this.checkInputs({isNew: false})) {
        return false
      }

      this.convData_(this.report)
      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    closeCreateCompleteModal() {
      this.$router.push({
        name: 'emergencyContactList',
      })
    },
    freeInputShow(propPart) {
      this.report.other_props[`show_${propPart}_free_input`] = true
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    freeInputHide(propPart) {
      this.report.other_props[`show_${propPart}_free_input`] = false
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    getFreeInputMetaParams() {
      return [
        ['result', 12, true],
        ['digest', 6, true],
        ['page2', 12, false],
      ]
    },
    onDamageRankChange() {
      const [damageRank, damageRankText] = this.report.damageRankConv.split('#')
      this.report.damage_rank = damageRank
      this.report.damage_rank_text = damageRankText
    },
    onClickWriteAccident() {
      this.report.result_free_input.replace(/(\r\n)+$/g, '')
      this.report.result_free_input = this.report.result_free_input +
        '\n' + Vue.filter('dtFormat')(this.report.occurence_dt, 'HH:MM') +
        ')　事故(障害)発生\n'
    },
    onClickWriteTurnoutRequest() {
      this.report.result_free_input.replace(/(\r\n)+$/g, '')
      const requestorFacilityDisp = Vue.filter('requestorFacilityDisp')
      this.report.result_free_input = this.report.result_free_input +
        '\n' + Vue.filter('dtFormat')(this.report.receive_dt, 'HH:MM') + ')　' +
        requestorFacilityDisp(this.report.requestor_group) + ' （' +
        this.report.requestorNamePart1 + this.report.requestorNamePart2 + '）より出動要請\n'
    },
    onClickWriteTurnoutRequest4ChargeCompany() {
      this.report.result_free_input.replace(/(\r\n)+$/g, '')
      const repairCompanyGroupDisp = Vue.filter('repairCompanyGroupDisp')
      const repairCompanies = []
      Array.from({ length: 2 }).forEach((_, idx) => {
        if (
          !this.report[`repairCompanyGroup${idx + 1}`] &&
          !this.report[`repairCompanyName${idx + 1}Part1`] &&
          !this.report[`repairCompanyName${idx + 1}Part2`]
        ) { return }

        repairCompanies.push(repairCompanyGroupDisp(this.report[`repairCompanyGroup${idx + 1}`]) + ' （' +
          this.report[`repairCompanyName${idx + 1}Part1`] + this.report[`repairCompanyName${idx + 1}Part2`] + '）')
      })

      this.report.result_free_input = this.report.result_free_input + '\n' +
        Vue.filter('dtFormat')(this.report.repair_company_request_dt, 'HH:MM') + ')　' +
        repairCompanies.join('、') + 'へ出動依頼\n'
    },
    onClickWriteEndReport() {
      this.report.result_free_input.replace(/(\r\n)+$/g, '')
      const requestorFacilityDisp = Vue.filter('requestorFacilityDisp')
      this.report.result_free_input = this.report.result_free_input + '\n' +
        Vue.filter('dtFormat')(this.report.complete_dt, 'HH:MM') +
        ')　終了報告を出動者より受信(' +
        requestorFacilityDisp(this.report.requestor_group) + ' （' +
        this.report.requestorNamePart1 + this.report.requestorNamePart2 + '）へ報告)\n'
    },
    onClickCreateDigest() {
      this.showConfirmDigestOverwriteModal = false
      if (!this.checkInputs({isNew: true})) {
        return
      }

      const highwayNameDisp = Vue.filter('highwayNameFacilityDisp')
      const highwayDirectionDisp = Vue.filter('highwayDirectionFacilityDisp')
      const highwayLaneDisp = Vue.filter('highwayLaneDisp')
      const highwayLrDisp = Vue.filter('highwayLrDisp')
      const highwayPlace2TextDisp = Vue.filter('highwayPlace2TextDisp')
      const recoveryActionDisp = Vue.filter('recoveryActionDisp')
      const repairCompanyGroupDisp = Vue.filter('repairCompanyGroupDisp')
      const repairCompanyServiceTypeDisp2 = Vue.filter('repairCompanyServiceTypeDisp2')
      const digestResultDisp = Vue.filter('digestResultDisp')

      const highwayInfoObj = this.report.occur_highway1_info || {}
      const highwayTextArr = []
      if (this.report.occur_highway1_name) {
        highwayTextArr.push(highwayNameDisp(this.report.occur_highway1_name))
      }
      if (this.report.occur_highway1_direction) {
        highwayTextArr.push('(' + highwayDirectionDisp(this.report.occur_highway1_direction) + ')')
      }
      const place2Text = highwayPlace2TextDisp(highwayInfoObj.place2_type, highwayInfoObj.place2_text)
      if (place2Text) {
        highwayTextArr.push(place2Text)
        let tmpText = ''
        if (highwayInfoObj.place2_text_other1) {
          tmpText += highwayInfoObj.place2_text_other1
        }
        if (highwayInfoObj.place2_text_other2) {
          tmpText += highwayInfoObj.place2_text_other2
        }
        if (tmpText) { highwayTextArr.push(tmpText) }
      } else if (highwayInfoObj.other_text) {
        highwayTextArr.push(highwayInfoObj.other_text)
      }
      if (highwayInfoObj.lane) {
        highwayTextArr.push(highwayLaneDisp(highwayInfoObj.lane))
      }
      if (highwayInfoObj.lr) {
        highwayTextArr.push(highwayLrDisp(highwayInfoObj.lr) + '側')
      }
      const highwayText = highwayTextArr.join(' ')

      const repairCompanies = []
      Array.from({ length: 2 }).forEach((_, idx) => {
        if (!this.report[`repairCompanyGroup${idx + 1}`]) { return }
        repairCompanies.push(repairCompanyGroupDisp(this.report[`repairCompanyGroup${idx + 1}`]))
      })

      this.report.digest_free_input =
        Vue.filter('dtFormat')(this.report.receive_dt, 'HH:MM') +
        ')\n' +
        '   a、' + highwayText + '\n' +
        '   b、' + this.report.target_facility + ' ' +
        this.report.facility_state +
        'による' +
        recoveryActionDisp(this.report.recovery_action) +
        '\n' +
        '   c、' +
        repairCompanies.join('、') +
        repairCompanyServiceTypeDisp2(this.report.repair_company_service_type) +
        'で出動し' +
        digestResultDisp(this.report.digest_result) +
        '。\n' +
        '   (別紙) ' + this.reportNumPrefixDisp + ' ' +
        this.getReportNumDisp() + '\n'
    },
    getReportNumDisp() {
      return reportNumPaddedDisp(this.report.report_num1)
    },
    confirmDigestOverwrite() {
      // 何も入力されていない場合は確認無しで作成
      if (!this.report.digest_free_input.replace(/^\s*$/, '')) {
        this.onClickCreateDigest()
      } else {
        this.showConfirmDigestOverwriteModal = true
      }
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
