export default {
  methods: {
    getDetailPageFlagSet() {
      return {
        // 報告書が新規作成モードかどうか
        isNew: false,
        // 報告書ヘッダーが新規作成モードかどうか
        isReportHeaderNew: false,
        // 報告書ヘッダーを表示しているかどうか
        showReportHeader: false,
        // 報告書がコピーモードかどうか
        isCopy: false,
      }
    },
    setNewDetailPageMode(flg) {
      this.isNew = flg
      this.isReportHeaderNew = flg
      this.showReportHeader = flg
    },
    setCopyMode(flg) {
      this.isCopy = flg
    },
    detailPageOnShowReportBody() {
      // body部を表示
      this.showReportHeader = false
      // bodyが表示されるということは報告書ヘッダーは確定している
      this.isReportHeaderNew = false
    },
    detailPageOnReportHeaderUpdateComplete(rh) {
      this.report.report_header_id = rh.id
      this.report.report_header = rh
      // 報告日は勤務情報の日付とずれるかもしれないなーと(夜とか)
      // いうことで、とりあえず新規作成時だけはりつける
      if (this.isNew) {
        this.report.report_dt = new Date(rh.dt)
      }
      // 総括
      this.report.manager_inspector_civil = rh.manager_inspector_civil
      this.report.manager_inspector_facility = rh.manager_inspector_facility
      // グループ番号
      this.report.crew_number = rh.crew_number
      // 点検調査員
      this.report.inspector = rh[`inspector${rh.crew_number}`]

      // 編集の場合はこの時点でグループ番号を保存
      if (!this.isNew) {
        const rh = {
          id: this.report.id,
          data: {
            crew_number: this.report.crew_number,
          }
        }
        this.reportApi.update(rh)
          .catch(err => {
            this.handleErrorResponse(err)
          })
      }
    },
    setDateProp(obj, prop, dt) {
      if (!dt) { return }
      dt = new Date(dt)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      const dtD = dt.getDate()
      obj[prop] = new Date(dtY, dtM, dtD, 0, 0, 0)
    },
    setDateTimeProp(obj, prop, dt) {
      if (!dt) { return }
      dt = new Date(dt)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      const dtD = dt.getDate()
      const dtHour = dt.getHours()
      const dtMinute = dt.getMinutes()
      obj[prop] = new Date(dtY, dtM, dtD, dtHour, dtMinute, 0)
    },
    _setWorkTimeProp(obj, prop, dt) {
      // 中身なかったら画面上クリアされてるかないか、
      // そもそもないか、なのでこちらもクリア
      if (!dt) {
        obj[prop] = null
        return
      }
      // 時間をセット
      const hours = dt.getHours()
      const minutes = dt.getMinutes()
      obj[prop] = hours * 100 + minutes
    },
    updateInspectorOutsideWorkTime({
      hwOnOffType, dt1From, dt1To, dt2From, dt2To
    }) {
      // 出動日時と点検調査員作業時間を連動させる.
      const arr = []
      if (hwOnOffType === 'on') {
        // 1個目が高速上の場合は、高速上の時刻にセット
        arr.push([dt1From, 'inspector_outside_work1_from'])
        arr.push([dt1To, 'inspector_outside_work1_to'])
        // 次は高速外の時刻にセット
        arr.push([dt2From, 'inspector_outside_work2_from'])
        arr.push([dt2To, 'inspector_outside_work2_to'])
      } else {
        // 1個目が高速外の場合は、高速上の時刻をクリアして、
        arr.push([null, 'inspector_outside_work1_from'])
        arr.push([null, 'inspector_outside_work1_to'])
        // 高速外の時刻にセット
        arr.push([dt1From, 'inspector_outside_work2_from'])
        arr.push([dt1To, 'inspector_outside_work2_to'])
      }

      arr.forEach(([dt, prop]) => {
        this._setWorkTimeProp(this.report, prop, dt)
      })
    },
    updateInsideWorkTime({ isMgrInspector } = {}) {
      const rh = this.report.report_header
      const props = [
        'inspector_outside_work1_to',
        'inspector_outside_work2_to',
      ]
      if (isMgrInspector) {
        props.push('mgr_inspector_outside_work1_to')
        props.push('mgr_inspector_outside_work2_to')
      }
      const dtFromArr = props.map(prop => {
        return this.timeIntegerToDate(this.report[prop], rh.dt, rh.timeslot)
      }).sort((a, b) => {
        return b < a ? -1 : 1
      })
      const dtFrom = dtFromArr[0]
      const dtTo = dtFrom ? new Date(dtFrom.valueOf() + 60 * 60 * 1000) : null

      const arr = []
      if (isMgrInspector) {
        arr.push([dtFrom, 'mgr_inspector_inside_work1_from'])
        arr.push([dtTo, 'mgr_inspector_inside_work1_to'])
      } else {
        arr.push([dtFrom, 'inspector_inside_work1_from'])
        arr.push([dtTo, 'inspector_inside_work1_to'])
      }

      arr.forEach(([dt, prop]) => {
        this._setWorkTimeProp(this.report, prop, dt)
      })
    },
  }
}
