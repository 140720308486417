import { fabric } from './extFabric'

function _calcPolylineCoords(obj) {
  const orgDims = {
    left: obj.left,
    top: obj.top,
    width: obj.width,
    height: obj.height,
  }
  const newDims = obj._calcDimensions()
  const deltaWidth = orgDims.width - newDims.width
  const deltaHeight = orgDims.height - newDims.height
  const deltaleft = orgDims.left - newDims.left
  const deltaTop = orgDims.top - newDims.top
  const pathOffsetX = obj.pathOffset.x - (deltaWidth / 2 + deltaleft)
  const pathOffsetY = obj.pathOffset.y - (deltaHeight / 2 + deltaTop)
  return {
    left: newDims.left,
    top: newDims.top,
    width: newDims.width,
    height: newDims.height,
    pathOffset: {x: pathOffsetX, y: pathOffsetY}
  }
}

class Drawer {
  constructor(drawOptions) {
    this.drawOptions = drawOptions
    this.defaultSize = 100
  }

  static _newInstance(drawOptions) {}

  static getInstance(drawOptions) {
    if (!this._instance) {
      this._instance = this._newInstance(drawOptions)
    } else {
      this._instance.drawOptions = drawOptions
    }
    return this._instance
  }

  make(x, y) {}

  resize(obj, x, y) {}

  lineTypeToStrokeDashArray(lineType) {
    return lineType === 'dashed' ? [10, 5] : []
  }
}

class LineDrawer extends Drawer {
  make(x, y) {
    return new fabric.Line([x, y, x, y], {
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
    })
  }

  resize(obj, x, y) {
    obj.set({ x2: x, y2: y })
    return true
  }

  static _newInstance(drawOptions) {
    return new LineDrawer(drawOptions)
  }
}

class RectDrawer extends Drawer {
  make(x, y) {
    return new fabric.Rect({
      left: x,
      top: y,
      fill: this.drawOptions.fillColor,
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
    })
  }

  resize(obj, x, y) {
    obj.set({ width: x - obj.left, height: y - obj.top })
    return true
  }

  static _newInstance(drawOptions) {
    return new RectDrawer(drawOptions)
  }
}

class LineArrow extends Drawer {
  make(x, y) {
    return new fabric.LineArrow([x, y, x, y], {
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
    })
  }

  resize(obj, x, y) {
    obj.set({ x2: x, y2: y })
    return true
  }

  static _newInstance(drawOptions) {
    return new LineArrow(drawOptions)
  }
}

class LineDoubleArrow extends Drawer {
  make = (x, y) => {
    return new fabric.LineDoubleArrow([x, y, x, y], {
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
    })
  }

  resize(obj, x, y) {
    obj.set({ x2: x, y2: y })
    return true
  }

  static _newInstance(drawOptions) {
    return new LineDoubleArrow(drawOptions)
  }
}

class EllipseDrawer extends Drawer {
  make(x, y) {
    return new fabric.Ellipse({
      left: x,
      top: y,
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
      fill: this.drawOptions.fillColor,
    })
  }

  resize(obj, x, y) {
    obj.set({
      originX: x < obj.left ? 'right' : 'left',
      originY: y < obj.top ? 'bottom' : 'top',
      rx: Math.abs(x - obj.left) / 2,
      ry: Math.abs(y - obj.top) / 2,
    })
    return true
  }

  static _newInstance(drawOptions) {
    return new EllipseDrawer(drawOptions)
  }
}

class PolylineDrawer extends Drawer {
  make(x, y) {
    return new fabric.Polyline([{ x, y }, { x, y }], {
      left: x,
      top: y,
      fill: this.drawOptions.fillColor,
      stroke: this.drawOptions.strokeColor,
      strokeWidth: this.drawOptions.strokeWidth,
      strokeDashArray: this.lineTypeToStrokeDashArray(this.drawOptions.lineType),
      selectable: false,
    })
  }

  resize(obj, x, y) {
    const points = obj.points
    points[points.length - 1].x = x
    points[points.length - 1].y = y
    obj.set({
      points,
      dirty: true
    })
    return true
  }

  addPoint(obj, x, y) {
    const points = obj.points
    points.push({x, y})
    obj.points = points
    return obj
  }

  done(obj, x, y) {
    const points = obj.points
    const startPoint = points[0]
    const secondPoint = points[1]
    const diffX = Math.abs(x - startPoint.x)
    const diffY = Math.abs(y - startPoint.y)
    const closeCondition = Math.max(obj.strokeWidth, 5)
    // 多角形の点は3個以上ある場合のみ判断する
    if (points.length < 3 || diffX > closeCondition || diffY > closeCondition) {
      return false
    }
    // 最後にクリックした点が始点と重なるよう調整.
    points[points.length - 2].x = startPoint.x
    points[points.length - 2].y = startPoint.y
    // 終点を始点の位置で終わらせると枠線部分が微妙に重ならず図形が綺麗に閉じなかったので、
    // 最後に始点から次の点の方向へ少し進んだ座標を入れる.
    const distance = 1
    const radian = Math.atan2((secondPoint.y - startPoint.y), (secondPoint.x - startPoint.x))
    points[points.length - 1].x = startPoint.x + (Math.cos(radian) * distance)
    points[points.length - 1].y = startPoint.y + (Math.sin(radian) * distance)
    const newCoords = _calcPolylineCoords(obj)
    obj.set({
      points,
      left: newCoords.left,
      top: newCoords.top,
      width: newCoords.width,
      height: newCoords.height,
      pathOffset: newCoords.pathOffset,
      selectable: true,
    })
    obj.setCoords()
    return true
  }

  forceDone(obj) {
    const points = obj.points
    // 強制終了の場合、最後の線を削除する
    points.pop()
    const newCoords = _calcPolylineCoords(obj)
    obj.set({
      points,
      left: newCoords.left,
      top: newCoords.top,
      width: newCoords.width,
      height: newCoords.height,
      pathOffset: newCoords.pathOffset,
      selectable: true,
    })
    obj.setCoords()
  }

  static _newInstance(drawOptions) {
    return new PolylineDrawer(drawOptions)
  }
}

class ExtITextDrawer extends Drawer {
  make(x, y) {
    const extITextObj = new fabric.ExtIText('', {
      left: x,
      top: y,
      padding: 5,
      editingBorderColor: '#b2ccff',
      // TextBoxのfill=backgroud,fill=stroke
      backgroundColor: this.drawOptions.fillColor,
      fill: this.drawOptions.strokeColor,
      fontFamily: 'sans-serif',
      fontSize: this.drawOptions.fontSize,
      writingMode: this.drawOptions.writingMode,
      angle: this.drawOptions.writingMode === 'vertical' ? 90 : 0,
    })
    extITextObj.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      // ml: false,
      // mr: false,
      mt: false,
      tl: false,
      tr: false,
    })
    // デフォルトだとwritingModeはstatePropertiesに含まれていない.
    // writingModeも履歴管理する必要があるのでstatePropertiesに追加する.
    extITextObj.stateProperties.push('writingMode')

    return extITextObj
  }

  resize(obj, x, y) {
    obj.set({ width: x - obj.left, height: y - obj.top })
    return true
  }

  static _newInstance(drawOptions) {
    return new ExtITextDrawer(drawOptions)
  }
}

function createDrawer(type, drawOptions) {
  if (type === 'line') {
    return LineDrawer.getInstance(drawOptions)
  } else if (type === 'lineArrow') {
    return LineArrow.getInstance(drawOptions)
  } else if (type === 'lineDoubleArrow') {
    return LineDoubleArrow.getInstance(drawOptions)
  } else if (type === 'rect') {
    return RectDrawer.getInstance(drawOptions)
  } else if (type === 'ellipse') {
    return EllipseDrawer.getInstance(drawOptions)
  } else if (type === 'polyline') {
    return PolylineDrawer.getInstance(drawOptions)
  } else if (type === 'ext-i-text') {
    return ExtITextDrawer.getInstance(drawOptions)
  }

  return null
}

export {
  createDrawer,
}
