<template src="./template.html"></template>

<script>
import Vue from 'vue'
import roadRegulationReportApi from '@/api/roadRegulationReport'
import EVT from '@/consts/eventNames'
import Stream from '@/lib/Stream'
import masterMixin from '@/mixin/masterMixin'
import detailPageMixin from '@/mixin/detailPageMixin'
import roadRegulationMixin from '@/mixin/roadRegulationMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'roadRegulationDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const detailPageFlagSet = this.getDetailPageFlagSet()
    const ret = {
      // streams
      stream: new Stream(), // mine
      reportHeaderStream: new Stream(),

      report: { other_props: {} },
      reporteeDeps: [],
      reporteeDepMap: {},
      constructionNames: [],
      roadRegulationContractees: [],
      roadRegulationInspectType: [],
      nendoSelect: this.getNendoSelect(),
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,

      onHighwayChecklistItems: [],
      onHighwayChecklistItemMap: [],
      offHighwayChecklistItems: [],
      offHighwayChecklistItemMap: [],

      checklistValueCands: ['○', '－', '是正'],
      checklistItemCountMax: 20,
      onHighwayNoticeItems: [],
      noticeItemCountMax: 6,
      offHighwayNoticeItems: [],

      reportApi: roadRegulationReportApi,

      errors: {},
    }
    return Object.assign({}, detailPageFlagSet, ret)
  },
  computed: {
    reporteeDepDisp1() {
      return (this.reporteeDepMap[this.report.reportee_dep1] || {}).name
    },
    reporteeDep1ForFilter() {
      return this.reporteeDepMap[this.report.reportee_dep1]
    },
    isReporteeDepDisp1Civil() {
      const reporteeDepInfo = this.reporteeDepMap[this.report.reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'civil'
    },
    isReporteeDepDisp1Facility() {
      const reporteeDepInfo = this.reporteeDepMap[this.report.reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'facility'
    },
    onOffTypeDisp1() {
      const hwOnOffType = this.report.regulate_highway1_on_off_type
      return hwOnOffType === 'on' ? '高速上' : '街路上'
    },
    kenmeiDisp() {
      if (!this.report.reportee_dep1 || !this.report.construction_nendo) {
        return '※報告先と工事年度を選択してください'
      }
      return this.getKenmeiDisp1(
        this.report.construction_nendo,
        this.reporteeDepMap[this.report.reportee_dep1]
      )
    },
    checklistItems() {
      const checklistInfos = this.report.checklist_infos || []
      const hwOnOffType = this.report.regulate_highway1_on_off_type
      const checklistItemMap = hwOnOffType === 'on' ? this.onHighwayChecklistItemMap : this.offHighwayChecklistItemMap
      return this.getChecklistItemsByChecklistInfos(checklistInfos, checklistItemMap, this.checklistItemCountMax)
    },
    noticeItems() {
      const hwOnOffType = this.report.regulate_highway1_on_off_type
      return hwOnOffType === 'on' ? this.onHighwayNoticeItems : this.offHighwayNoticeItems
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  async mounted() {
    await this.getMasters()
    this.setListeners()

    if (this.$route.name === 'roadRegulationNew') {
      this.setNewDetailPageMode(true)
      this.prepareNewReport_()
      this.prepareDispArrays()
    } else if (this.$route.name === 'roadRegulationCopy') {
      this.setNewDetailPageMode(true)
      this.setCopyMode(true)
      this.getCopiedNewReport_()
    } else {
      this.setNewDetailPageMode(false)
      this.getReport_()
    }

    this.connectStreams()
  },
  mixins: [
    masterMixin, detailPageMixin, roadRegulationMixin,
    errorHandleMixin, validatorMixin, dateMixin
  ],
  methods: {
    getMasters() {
      return window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.reporteeDeps = lovs.reportee_dep.vals_inuse
        this.reporteeDepMap = lovs.reportee_dep.map_inuse
        this.constructionNames = lovs.construction_name.vals_inuse
        this.roadRegulationContractees = lovs.road_regulation_contractee.vals_inuse
        this.roadRegulationInspectType =
          lovs.road_regulation_inspect_type.vals_inuse
        this.onHighwayChecklistItems =
          lovs.road_regulation_on_highway_checklist_item.vals_inuse
        this.onHighwayChecklistItemMap =
          lovs.road_regulation_on_highway_checklist_item.map
        this.offHighwayChecklistItems =
          lovs.road_regulation_off_highway_checklist_item.vals_inuse
        this.offHighwayChecklistItemMap =
          lovs.road_regulation_off_highway_checklist_item.map
      })
    },
    connectStreams() {
      const reportHeader = this.reportHeaderStream
      const me = this.stream
      reportHeader.chain(EVT.RH.SHOW_REPORT_BODY).to(me).end()
      reportHeader.chain(EVT.RH.UPDATE_COMPLETE).to(me).end()
    },
    setListeners() {
      this.stream.recv(EVT.RH.SHOW_REPORT_BODY, () => {
        this.detailPageOnShowReportBody()
      })
      this.stream.recv(EVT.RH.UPDATE_COMPLETE, obj => {
        this.detailPageOnReportHeaderUpdateComplete(obj)
      })
    },
    prepareNewReport_() {
      const obj = {}

      const now = new Date()

      obj.report_num1 = ''
      obj.report_header_id = -1
      obj.report_header = {}
      this.setDateProp(obj, 'report_dt', now)
      obj.reportee_dep1 = ''
      obj.manager_inspector_civil = ''
      obj.manager_inspector_facility = ''
      obj.crew_number = 1

      obj.inspect_dt1_from = null
      obj.inspect_dt1_to = null
      obj.construction_name = ''

      let nendo = now.getFullYear()
      nendo = (now.getMonth() + 1) < 4 ? nendo - 1 : nendo
      obj.construction_nendo = nendo

      obj.inspection_type = ''
      obj.contractee = ''

      obj.genba_dairinin = ''
      obj.genba_sekininsha = ''

      obj.regulate_highway1_on_off_type = 'on'
      obj.regulate_highway1_name = ''
      obj.regulate_highway1_direction = ''
      obj.regulate_highway1_info = {}

      this.setChecklistInfos(obj)

      obj.mgr_inspector_outside_work1_from = null
      obj.mgr_inspector_outside_work1_to = null
      obj.mgr_inspector_outside_work2_from = null
      obj.mgr_inspector_outside_work2_to = null
      obj.mgr_inspector_inside_work1_from = null
      obj.mgr_inspector_inside_work1_to = null
      obj.inspector_outside_work1_from = null
      obj.inspector_outside_work1_to = null
      obj.inspector_outside_work2_from = null
      obj.inspector_outside_work2_to = null
      obj.inspector_inside_work1_from = null
      obj.inspector_inside_work1_to = null

      const otherProps = {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([
        propSuffix, defaultDispRows, defaultShow
      ]) => {
        otherProps[`free_input_${propSuffix}_rows`] = defaultDispRows
        otherProps[`show_free_input_${propSuffix}`] = defaultShow
      })
      obj.other_props = otherProps

      this.report = obj
    },
    convReport_(data) {
      this.setDateProp(data, 'report_dt', data.report_dt)

      data.manager_inspector_civil =
        data.report_header.manager_inspector_civil
      data.manager_inspector_facility =
        data.report_header.manager_inspector_facility
      data.inspector =
        data.report_header[`inspector${data.crew_number}`]

      this.setDateTimeProp(data, 'report_dt', data.report_dt)
      this.setDateTimeProp(data, 'inspect_dt1_from', data.inspect_dt1_from)
      this.setDateTimeProp(data, 'inspect_dt1_to', data.inspect_dt1_to)

      Array.from(Array(2).keys()).forEach(i => {
        const num = i + 1
        this.setDateTimeProp(data,
          `inspect_dt${num}_from`, data[`inspect_dt${num}_from`])
        this.setDateTimeProp(data,
          `inspect_dt${num}_to`, data[`inspect_dt${num}_to`])
      })

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      data.request_content =
        splitByLineBreak(data.request_content).join('\n')

      if (!data.checklist_infos) {
        this.setChecklistInfos(data)
      }

      data.other_props = data.other_props || {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([
        propPreffix, defaultDispRows, defaultShow
      ]) => {
        const prop = `${propPreffix}_free_input`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        const rowCountProp = `${propPreffix}_free_input_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)
      })

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
          this.prepareDispArrays()
        })
    },
    getCopiedNewReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.id = -1
          data.report_header_id = -1
          data.report_header = {}

          data.inspect_dt1_from = null
          data.inspect_dt1_to = null

          const now = new Date()
          const dateProps = [
            'report_dt',
          ]
          dateProps.forEach(prop => {
            data[prop] = now
          })

          this.report = this.convReport_(data)
          this.prepareDispArrays()
        })
    },
    onHighwayInputChange(hwObj) {
      const pfx = hwObj.pfx
      const origHwOnOffType = this.report[`${pfx}_on_off_type`]
      this.report[`${pfx}_on_off_type`] = hwObj.on_off_type
      this.report[`${pfx}_name`] = hwObj.name
      this.report[`${pfx}_direction`] = hwObj.direction
      this.report[`${pfx}_info`] = hwObj.info
      if (origHwOnOffType !== this.report[`${pfx}_on_off_type`]) {
        this.onHighwayOnOffTypeChange(hwObj)
      }
    },
    onHighwayOnOffTypeChange(hwObj) {
      this.updateWorkTime()
      this.setChecklistInfos(this.report)
    },
    onWorkTimeInput(obj) {
      this.report[obj.prop] = obj.val
    },
    checkInputs({ isNew }) {
      this.errors = {}
      let prop

      prop = 'reportee_dep1'
      this.checkRequired(this.report[prop], prop, '報告先', this.errors)

      prop = 'report_dt'
      this.checkRequired(this.report[prop], prop, '報告日', this.errors)

      prop = 'inspect_dt1_from'
      this.checkRequired(this.report[prop], prop, '点検日時(開始)', this.errors)

      prop = 'inspect_dt1_to'
      this.checkRequired(this.report[prop], prop, '点検日時(終了)', this.errors)

      return Object.keys(this.errors).length === 0
    },
    async createReport() {
      if (!this.checkInputs({isNew: true})) {
        return false
      }
      // 報告日を入れる
      this.report.report_dt = this.getNearestReportDt(this.report.inspect_dt1_from)

      // conv date
      this.report.report_dt = Vue.filter('dtFormat')(
        this.report.report_dt, 'yyyy-mm-dd')

      const obj = { data: this.report }
      try {
        const { data } = await this.reportApi.create(obj)
        this.report = this.convReport_(data)
        this.showCreateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    async saveReport() {
      if (!this.checkInputs({isNew: false})) {
        return false
      }

      // conv date
      this.report.report_dt = Vue.filter('dtFormat')(
        this.report.report_dt, 'yyyy-mm-dd')

      // conv free inputs
      const freeInputs = ['correction', 'inspection']
      freeInputs.forEach(propPart => {
        const prop = `${propPart}_free_input1`
        this.report[prop] = (this.report[prop] || '')
      })

      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    closeCreateCompleteModal() {
      this.$router.push({
        name: 'roadRegulationList',
      })
    },
    freeInputShow(propSuffix) {
      this.report.other_props[`show_${propSuffix}_free_input1`] = true
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    freeInputHide(propSuffix) {
      this.report.other_props[`show_${propSuffix}_free_input1`] = false
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    getFreeInputMetaParams() {
      return [
        ['pre1', 1, true],
        ['method1', 4, false],
        ['scope1', 4, false],
        ['result1', 4, true],
        ['first_aid1', 2, true],
        ['post1', 1, true],
      ]
    },
    isOnHighway() {
      return this.report.regulate_highway1_on_off_type === 'on'
    },
    isOffHighway() {
      return !this.isOnHighway()
    },
    onInspectDtInput() {
      this.updateWorkTime()
    },
    updateWorkTime() {
      this.updateInspectorOutsideWorkTime({
        hwOnOffType: this.report.regulate_highway1_on_off_type,
        dt1From: this.report.inspect_dt1_from,
        dt1To: this.report.inspect_dt1_to,
      })
    },
    setChecklistInfos(obj) {
      const hwOnOffType = obj.regulate_highway1_on_off_type
      const checklistItems = hwOnOffType === 'on' ? this.onHighwayChecklistItems : this.offHighwayChecklistItems
      obj.checklist_infos = checklistItems.map(e => {
        return { key: e.key, val: '' }
      })
    },
    markAllAsChecked() {
      this.report.checklist_infos.forEach(e => {
        e.val = '○'
      })
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
