export default {
  // 自動押印設定 true:する false:しない
  IMPRINT_SETTINGS: {
    // 苦情処理状況調書
    ENABLE_COMPLAINTHANDLING: true,
    // 緊急連絡報告書
    ENABLE_EMERGENCYCONTACT: true,
    // 緊急点検報告書
    ENABLE_EMERGENCYINSPECT: true,
    // 保安規制の状況チェックリスト
    ENABLE_ROADREGULATION: true,
    // 緊急点検業務日誌
    ENABLE_ALLREPORTSSUMMARY: true,
    // 緊急連絡業務日誌
    ENABLE_EMERGENCYCONTACTSUMMERY: true,
    // 安全管理日誌
    ENABLE_SECURITYCHECKLIST: true,
    // 工程表
    ENABLE_WORKRESULTTABLE: true,
    // 緊急点検出動報告書（タコグラフ）
    ENABLE_WORKRESULTTACHOGRAPH: true,
  }
}
