<template src="./template.html"></template>

<script>
import Vue from 'vue'
import emergencyInspectReportApi from '@/api/emergencyInspectReport'
import EVT from '@/consts/eventNames'
import Stream from '@/lib/Stream'
import masterMixin from '@/mixin/masterMixin'
import detailPageMixin from '@/mixin/detailPageMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'emergencyInspectDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const detailPageFlagSet = this.getDetailPageFlagSet()
    const ret = {
      // streams
      stream: new Stream(), // mine
      reportHeaderStream: new Stream(),

      report: {
        other_props: {},
        repairCompaniesConv: [],
      },
      reporteeDeps: [],
      reporteeDepMap: {},
      requestors: [],
      requestorKeishous: [],
      requestContentKinds: [],
      requestContentOthers1: [],
      repairCompanies: [],
      causesOfInspectionTimeDelay: [],
      specialNotesOnInspection: [],
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,

      reportApi: emergencyInspectReportApi,

      errors: {},
      // 新規追加
      isTemplatePreviewMode: false,
      placeholders_all: [],
      placeholders: [],
      ReplaceValue: [],
      Damage_template: {},
      TemplateValue: [],
      templateValueBuf: [],
      InputParams: ['pre1', 'method1', 'scope1', 'result1', 'first_aid1', 'post1'],
      inspect_template_data_all: '',
      inspect_template_data_rows_all: 10,
      inspect_template_data: [],
      GetTemplateData: '',

      selectedTemplateId: null,

      showReporteeDep2: false,
      showHighwayInput2: false,
    }
    return Object.assign({}, detailPageFlagSet, ret)
  },
  computed: {
    reporteeDepDisp1() {
      return (this.reporteeDepMap[this.report.reportee_dep1] || {}).name
    },
    reporteeDepDisp2() {
      return (this.reporteeDepMap[this.report.reportee_dep2] || {}).name
    },
    reporteeDep1ForFilter() {
      return this.reporteeDepMap[this.report.reportee_dep1]
    },
    reporteeDep2ForFilter() {
      return this.report.reportee_dep2 ? this.reporteeDepMap[this.report.reportee_dep2] : null
    },
    hasReporteeDepCivil() {
      const reporteeDepInfo1 = this.reporteeDepMap[this.report.reportee_dep1]
      const reporteeDepInfo2 = this.reporteeDepMap[this.report.reportee_dep2]
      return (reporteeDepInfo1 && reporteeDepInfo1.reportee_type === 'civil') ||
        (reporteeDepInfo2 && reporteeDepInfo2.reportee_type === 'civil')
    },
    hasReporteeDepFacility() {
      const reporteeDepInfo1 = this.reporteeDepMap[this.report.reportee_dep1]
      const reporteeDepInfo2 = this.reporteeDepMap[this.report.reportee_dep2]
      return (reporteeDepInfo1 && reporteeDepInfo1.reportee_type === 'facility') ||
        (reporteeDepInfo2 && reporteeDepInfo2.reportee_type === 'facility')
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
    isTemplatePreviewBtnDisabled() {
      return !this.selectedTemplateId || this.isTemplatePreviewMode
    },
  },
  mounted() {
    this.getMasters()
    this.setListeners()

    if (this.$route.name === 'emergencyInspectNew') {
      this.setNewDetailPageMode(true)
      this.prepareNewReport_()
    } else if (this.$route.name === 'emergencyInspectCopy') {
      this.setNewDetailPageMode(true)
      this.setCopyMode(true)
      this.getCopiedNewReport_()
    } else {
      this.setNewDetailPageMode(false)
      this.getReport_()
    }

    this.connectStreams()
  },
  mixins: [masterMixin, detailPageMixin, errorHandleMixin, validatorMixin, dateMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.reporteeDeps = lovs.reportee_dep.vals_inuse
        this.reporteeDepMap = lovs.reportee_dep.map_inuse
        this.requestors = lovs.requestor.vals_inuse
        this.requestorKeishous = lovs.requestor_keishou.vals_inuse
        this.requestContentKinds = lovs.request_content_kind.vals_inuse
        this.requestContentOthers1 = lovs.request_content_other1.vals_inuse
        this.repairCompanies = lovs.repair_company.vals_inuse
        this.causesOfInspectionTimeDelay = lovs.cause_of_inspection_time_delay.vals_inuse
        this.specialNotesOnInspection = lovs.special_note_on_inspection.vals_inuse
        // テンプレートテーブル（新規追加）
        this.Damage_template = window.master.damage_type_templates
      })
    },
    connectStreams() {
      const reportHeader = this.reportHeaderStream
      const me = this.stream
      reportHeader.chain(EVT.RH.SHOW_REPORT_BODY).to(me).end()
      reportHeader.chain(EVT.RH.UPDATE_COMPLETE).to(me).end()
    },
    setListeners() {
      this.stream.recv(EVT.RH.SHOW_REPORT_BODY, () => {
        this.detailPageOnShowReportBody()
      })
      this.stream.recv(EVT.RH.UPDATE_COMPLETE, obj => {
        this.detailPageOnReportHeaderUpdateComplete(obj)
      })
    },
    prepareNewReport_() {
      const obj = {}

      const now = new Date()

      obj.report_num1 = ''
      obj.report_num2 = ''
      obj.report_header_id = -1
      obj.report_header = {}
      obj.reportee_dep1 = ''
      obj.reportee_dep2 = ''
      this.setDateProp(obj, 'report_dt', now)
      obj.manager_inspector_civil = ''
      obj.manager_inspector_facility = ''
      obj.crew_number = 1
      obj.inspector = ''

      obj.requestorPart1 = ''
      obj.requestorPart2 = ''
      obj.requestorPart3 = ''
      this.setDateTimeProp(obj, 'request_dt', now)
      obj.request_highway1_on_off_type = 'on'
      obj.request_highway1_name = ''
      obj.request_highway1_direction = ''
      obj.request_highway1_info = {}
      obj.requestContentKind1 = ''
      obj.requestContentKind2 = ''
      obj.requestContentDetailPart1 = ''
      obj.requestContentDetailPart2 = ''

      obj.repair_company = ''
      obj.repairCompaniesConv = []

      obj.mgr_inspector_outside_work1_from = null
      obj.mgr_inspector_outside_work1_to = null
      obj.mgr_inspector_outside_work2_from = null
      obj.mgr_inspector_outside_work2_to = null
      obj.mgr_inspector_inside_work1_from = null
      obj.mgr_inspector_inside_work1_to = null
      obj.inspector_outside_work1_from = null
      obj.inspector_outside_work1_to = null
      obj.inspector_outside_work2_from = null
      obj.inspector_outside_work2_to = null
      obj.inspector_inside_work1_from = null
      obj.inspector_inside_work1_to = null

      // 入れとかないとプラスボタンが出っぱなし
      obj.inspect_highway1_on_off_type = 'on'

      const otherProps = {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([
        propSuffix, defaultDispRows, defaultShow
      ]) => {
        otherProps[`free_input_${propSuffix}_rows`] = defaultDispRows
        otherProps[`show_free_input_${propSuffix}`] = defaultShow
      })
      obj.other_props = otherProps

      this.report = obj
    },
    convReport_(data) {
      this.setDateProp(data, 'report_dt', data.report_dt)

      data.manager_inspector_civil =
        data.report_header.manager_inspector_civil
      data.manager_inspector_facility =
        data.report_header.manager_inspector_facility
      data.inspector =
        data.report_header[`inspector${data.crew_number}`]

      this.showReporteeDep2 = data.reportee_dep2

      const repairCompany = data.repair_company || ''
      data.repairCompaniesConv = repairCompany.split(',')

      // 途中からいれたので、古い形式のデータを救済
      const requestorParts = data.requestor.split('###')
      if (requestorParts.length !== 3) {
        data.requestorPart1 = ''
        data.requestorPart2 = data.requestor
        data.requestorPart3 = ''
      } else {
        const [requestorPart1, requestorPart2, requestorPart3] = requestorParts
        data.requestorPart1 = requestorPart1 || ''
        data.requestorPart2 = requestorPart2 || ''
        data.requestorPart3 = requestorPart3 || ''
      }

      // 途中からいれたので、古い形式のデータを救済
      const requestContentKind = data.request_content_kind || ''
      const requestContentKinds = requestContentKind.split(',')
      const [requestContentKind1, requestContentKind2] = requestContentKinds
      data.requestContentKind1 = requestContentKind1 || ''
      data.requestContentKind2 = requestContentKind2 || ''

      // 途中からいれたので、古い形式のデータを救済
      const requestContentDetail = data.request_content_detail || ''
      const requestContentDetailParts = requestContentDetail.split('###')
      if (requestContentDetailParts.length !== 2) {
        data.requestContentDetailPart1 = ''
        data.requestContentDetailPart2 = data.request_content_detail
      } else {
        const [part1, part2] = requestContentDetailParts
        data.requestContentDetailPart1 = part1 || ''
        data.requestContentDetailPart2 = part2 || ''
      }

      this.setDateTimeProp(data, 'request_dt', data.request_dt)

      Array.from(Array(2).keys()).forEach(i => {
        const num = i + 1
        this.setDateTimeProp(data,
          `inspect_dt${num}_from`, data[`inspect_dt${num}_from`])
        this.setDateTimeProp(data,
          `inspect_dt${num}_to`, data[`inspect_dt${num}_to`])
      })

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      data.request_content =
        splitByLineBreak(data.request_content).join('\n')

      data.other_props = data.other_props || {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([
        propSuffix, defaultDispRows, defaultShow
      ]) => {
        const prop = `inspect_free_input_${propSuffix}`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        // 初期表示状態保持（新規追加） str
        if (prop === 'inspect_free_input_pre1') {
          this.templateValueBuf[this.InputParams[0]] = data[prop]
        }
        if (prop === 'inspect_free_input_method1') {
          this.templateValueBuf[this.InputParams[1]] = data[prop]
        }
        if (prop === 'inspect_free_input_scope1') {
          this.templateValueBuf[this.InputParams[2]] = data[prop]
        }
        if (prop === 'inspect_free_input_result1') {
          this.templateValueBuf[this.InputParams[3]] = data[prop]
        }
        if (prop === 'inspect_free_input_first_aid1') {
          this.templateValueBuf[this.InputParams[4]] = data[prop]
        }
        if (prop === 'inspect_free_input_first_post1') {
          this.templateValueBuf[this.InputParams[5]] = data[prop]
        }
        // 初期表示状態保持（新規追加） end
        const rowCountProp = `free_input_${propSuffix}_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)
      })
      // あとから足したので..
      if (!data.other_props.show_free_input_page2) {
        data.other_props.show_free_input_page2 = false
      }

      if (data.inspect_highway2_name) {
        this.setShowHighwayInput2(true)
      }

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })
    },
    getCopiedNewReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.id = -1
          data.report_header_id = -1
          data.report_header = {}

          data.inspect_dt1_from = null
          data.inspect_dt1_to = null
          data.inspect_dt2_from = null
          data.inspect_dt2_to = null

          const now = new Date()
          const dateProps = [
            'report_dt',
            'request_dt',
          ]
          dateProps.forEach(prop => {
            data[prop] = now
          })

          this.report = this.convReport_(data)
        })
    },
    // その内共通化対象な予感がする
    onHighwayInputChange(hwObj) {
      const pfx = hwObj.pfx
      const origHwOnOffType = this.report[`${pfx}_on_off_type`]
      this.report[`${pfx}_on_off_type`] = hwObj.on_off_type
      this.report[`${pfx}_name`] = hwObj.name
      this.report[`${pfx}_direction`] = hwObj.direction
      this.report[`${pfx}_info`] = hwObj.info
      if (origHwOnOffType !== this.report[`${pfx}_on_off_type`]) {
        this.onHighwayOnOffTypeChange(hwObj)
      }
    },
    // その内共通化対象な予感がする
    onHighwayOnOffTypeChange(hwObj) {
      this.updateWorkTime()
    },
    onRepairCompanyChange(evt) {
      if (!evt.target.checked) { return }

      // 「なし」選択時: 「なし」以外のチェックを全てはずす
      // 「なし」以外選択時: 「なし」のチェックをはずす
      const repairCompanyNone = 'なし'
      let repairCompanies = []
      if (evt.target.value === repairCompanyNone) {
        repairCompanies = [repairCompanyNone]
      } else {
        repairCompanies = this.report.repairCompaniesConv.filter(e => e !== repairCompanyNone)
      }
      this.report.repairCompaniesConv = repairCompanies
    },
    onReporteeDepChange(reporteeDepKey, prop) {
      if (!reporteeDepKey) {
        this.report[prop] = ''
        return
      }

      let requestContentKindName = this.report[prop]
      if (!requestContentKindName) { return }

      const requestContentKinds = this.getFilteredRequestContentKinds(reporteeDepKey)
      const requestContentKind = requestContentKinds.find(e => e.name === requestContentKindName)
      if (!requestContentKind) {
        requestContentKindName = ''
      }
      this.report[prop] = requestContentKindName
    },
    onWorkTimeInput(obj) {
      this.report[obj.prop] = obj.val
    },
    checkInputs({ isNew }) {
      this.errors = {}
      let prop

      prop = 'reportee_dep1'
      this.checkRequired(this.report[prop], prop, '報告先', this.errors)

      prop = 'report_dt'
      this.checkRequired(this.report[prop], prop, '報告日', this.errors)

      prop = 'request_dt'
      this.checkRequired(this.report[prop], prop, '依頼日時', this.errors)

      prop = 'inspect_dt1_from'
      this.checkRequired(this.report[prop], prop, '出動日時(開始)', this.errors)

      prop = 'inspect_dt1_to'
      this.checkRequired(this.report[prop], prop, '出動日時(終了)', this.errors)

      if (this.report.reportee_dep2 &&
        this.report.reportee_dep1 === this.report.reportee_dep2
      ) {
        this.errors.reportee_dep2 = ['報告先1と2は別々のものを選択してください']
      }

      // ・指示時間と出動時間の差 <= 5分 の場合 -> NG
      // ・5分 < 指示時間と出動時間の差 <= 10分 の場合 -> OK
      // ・10分 < 指示時間と出動時間の差 且つ 点検結果欄最上段未記入の場合 -> NG
      // ・10分 < 指示時間と出動時間の差 且つ 点検結果欄最上段記入の場合 -> OK
      if (this.report.request_dt && this.report.inspect_dt1_from) {
        prop = 'inspect_dt1_from'
        // 高速上と高速外の両方で出動している場合は、出動日時が早い方を使う
        if (
          this.report.inspect_dt2_from &&
          this.report.inspect_dt1_from > this.report.inspect_dt2_from
        ) {
          prop = 'inspect_dt2_from'
        }
        const secDiff = (this.report[prop].valueOf() - this.report.request_dt.valueOf()) / 1000
        if (secDiff <= 300) {
          this.errors.request_dt = ['指示時間と出動時間の差を適切に設定してください。']
          this.errors[prop] = ['指示時間と出動時間の差を適切に設定してください。']
        } else if (secDiff > 600) {
          if (!this.report.inspect_free_input_pre1) {
            this.errors.request_dt = ['10分以上経過の説明文を記載してください。']
            this.errors[prop] = ['10分以上経過の説明文を記載してください。']
          }
        }
      }

      // 更新時のみ
      if (!isNew) {
        if (this.isTemplatePreviewMode) {
          this.errors.free_input_state = ['定型文が適用されていません。']
        }
      }

      return Object.keys(this.errors).length === 0
    },
    convData_(report) {
      // conv date
      report.report_dt = Vue.filter('dtFormat')(
        report.report_dt, 'yyyy-mm-dd')

      report.repair_company = report.repairCompaniesConv.filter(e => !!e).join(',')

      report.requestor = [
        (report.requestorPart1 || ''),
        (report.requestorPart2 || ''),
        (report.requestorPart3 || ''),
      ].join('###')

      report.request_content_kind = [
        (report.requestContentKind1 || ''),
        (report.requestContentKind2 || ''),
      ].join(',')

      report.request_content_detail = [
        (report.requestContentDetailPart1 || ''),
        (report.requestContentDetailPart2 || ''),
      ].join('###')

      // conv free inputs
      let freeInputs = ['method1', 'scope1', 'result1', 'first_aid1', 'page2']
      freeInputs.forEach(propSuffix => {
        const prop = `inspect_free_input_${propSuffix}`
        if (!report.other_props[`show_free_input_${propSuffix}`]) {
          report[prop] = ''
        }
        report[prop] = (report[prop] || '')
      })
      freeInputs = ['pre1', 'post1']
      freeInputs.forEach(propSuffix => {
        const prop = `inspect_free_input_${propSuffix}`
        report[prop] = (report[prop] || '')
      })
    },
    async createReport() {
      if (!this.checkInputs({isNew: true})) {
        return false
      }
      // 報告日を入れる
      this.report.report_dt = this.getNearestReportDt(this.report.inspect_dt1_from)

      this.convData_(this.report)
      const obj = { data: this.report }
      try {
        const { data } = await this.reportApi.create(obj)
        this.report = this.convReport_(data)
        this.showCreateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
    },
    async saveReport() {
      if (!this.checkInputs({isNew: false})) {
        return false
      }

      this.convData_(this.report)
      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    closeCreateCompleteModal() {
      this.$router.push({
        name: 'emergencyInspectList',
      })
    },
    freeInputShow(propSuffix) {
      this.report.other_props[`show_free_input_${propSuffix}`] = true
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    freeInputHide(propSuffix) {
      this.report.other_props[`show_free_input_${propSuffix}`] = false
      this.report.other_props = JSON.parse(JSON.stringify(this.report.other_props))
    },
    getFreeInputMetaParams() {
      return [
        ['pre1', 1, true],
        ['method1', 4, false],
        ['scope1', 4, false],
        ['result1', 4, true],
        ['first_aid1', 2, true],
        ['post1', 1, true],
        ['page2', 8, false],
      ]
    },
    onInspectDtInput() {
      this.updateWorkTime()
    },
    updateWorkTime() {
      this.updateInspectorOutsideWorkTime({
        hwOnOffType: this.report.inspect_highway1_on_off_type,
        dt1From: this.report.inspect_dt1_from,
        dt1To: this.report.inspect_dt1_to,
        dt2From: this.report.inspect_dt2_from,
        dt2To: this.report.inspect_dt2_to,
      })
    },
    // 新規追加
    // テンプレート選択
    changeTemplate() {
      if (!this.selectedTemplateId) { return }

      const selectedTemplate = this.Damage_template.find(e => e.id === this.selectedTemplateId)
      if (!selectedTemplate || !selectedTemplate.template_value) { return }

      this.placeholders_all = []
      this.placeholders = []
      // 項目別に分ける
      const values = selectedTemplate.template_value.split('@@', this.InputParams.length)
      this.ReplaceValue = []
      for (let i = 0; i < this.InputParams.length; i++) {
        if (values[i]) {
          this.TemplateValue[this.InputParams[i]] = values[i]
        } else {
          this.TemplateValue[this.InputParams[i]] = []
        }
      }
      // 初期表示状態保持
      this.templateValueBuf[this.InputParams[0]] = this.report.inspect_free_input_pre1
      this.templateValueBuf[this.InputParams[1]] = this.report.inspect_free_input_method1
      this.templateValueBuf[this.InputParams[2]] = this.report.inspect_free_input_scope1
      this.templateValueBuf[this.InputParams[3]] = this.report.inspect_free_input_result1
      this.templateValueBuf[this.InputParams[4]] = this.report.inspect_free_input_first_aid1
      this.templateValueBuf[this.InputParams[5]] = this.report.inspect_free_input_post1

      this.setTemplateData()
    },
    // プレビュー選択
    setTemplateData() {
      this.placeholders = []
      this.placeholders_all = []
      this.ReplaceValue = []
      this.inspect_template_data_all = ''
      this.inspect_template_data = []
      for (let i = 0; i < this.InputParams.length; i++) {
        if (this.TemplateValue[this.InputParams[i]].length !== 0) {
          // テンプレート情報あり：テンプレートデータセット
          this.setPlaceholder(this.InputParams[i], this.TemplateValue[this.InputParams[i]])
          this.inspect_template_data_all = this.inspect_template_data_all + this.TemplateValue[this.InputParams[i]] + '\n'
          this.GetTemplateData = this.inspect_template_data_all
        }
      }
      // console.log(this.inspect_template_data_all.length)
    },
    // テキストＢＯＸ入力変換
    onChangeTemplatePlaceholder() {
      let str = ''
      str = this.GetTemplateData
      this.placeholders_all.forEach(findt => {
        if (this.ReplaceValue[findt.name]) {
          str = str.replace('${' + findt.name + '}', this.ReplaceValue[findt.name])
        }
      })
      this.inspect_template_data_all = str
      // 項目別に分ける
      for (let i = 0; i < this.InputParams.length; i++) {
        this.inspect_template_data[this.InputParams[i]] = ''
        if (this.TemplateValue[this.InputParams[i]]) {
          str = this.TemplateValue[this.InputParams[i]]
          if (str.length !== 0) {
            this.placeholders[this.InputParams[i]].forEach(findt => {
              if (this.ReplaceValue[findt.name]) {
                str = str.replace('${' + findt.name + '}', this.ReplaceValue[findt.name])
              }
            })
            this.inspect_template_data[this.InputParams[i]] = str
          }
        }
        // console.log(this.inspect_template_data[this.InputParams[i]])
      }
    },
    // 適用／キャンセルボタン選択
    setFreeInput(kind) {
      this.selectedTemplateId = null
      this.isTemplatePreviewMode = false
      this.errors = {}
      // 適用ボタン
      if (kind === 'true') {
        // データセット
        if (this.inspect_template_data[this.InputParams[0]]) {
          this.report.inspect_free_input_pre1 = this.inspect_template_data[this.InputParams[0]]
          this.freeInputChg(this.InputParams[0], this.inspect_template_data[this.InputParams[0]])
        }
        if (this.inspect_template_data[this.InputParams[1]]) {
          this.report.inspect_free_input_method1 = this.inspect_template_data[this.InputParams[1]]
          this.freeInputChg(this.InputParams[1], this.inspect_template_data[this.InputParams[1]])
        }
        if (this.inspect_template_data[this.InputParams[2]]) {
          this.report.inspect_free_input_scope1 = this.inspect_template_data[this.InputParams[2]]
          this.freeInputChg(this.InputParams[2], this.inspect_template_data[this.InputParams[2]])
        }
        if (this.inspect_template_data[this.InputParams[3]]) {
          this.report.inspect_free_input_result1 = this.inspect_template_data[this.InputParams[3]]
          this.freeInputChg(this.InputParams[3], this.inspect_template_data[this.InputParams[3]])
        }
        if (this.inspect_template_data[this.InputParams[4]]) {
          this.report.inspect_free_input_first_aid1 = this.inspect_template_data[this.InputParams[4]]
          this.freeInputChg(this.InputParams[4], this.inspect_template_data[this.InputParams[4]])
        }
        if (this.inspect_template_data[this.InputParams[5]]) {
          this.report.inspect_free_input_post1 = this.inspect_template_data[this.InputParams[5]]
          this.freeInputChg(this.InputParams[5], this.inspect_template_data[this.InputParams[5]])
        }
      }
    },
    freeInputChg(propSuffix, tempValue) {
      if (tempValue === '') {
        this.freeInputHide(propSuffix)
      } else {
        this.freeInputShow(propSuffix)
      }
    },
    // テキスト入力情報テーブルセット
    setPlaceholder(propSuffix, tempValue) {
      this.placeholders[propSuffix] = []
      const getdt = tempValue.match(/[$]{.+?}/g)
      if (getdt !== null) {
        for (let i = 0; i < getdt.length; i++) {
          getdt[i] = getdt[i].substring(2, getdt[i].length - 1)
          this.placeholders[propSuffix].push({ name: getdt[i], type: 'text' })
          this.placeholders_all.push({ name: getdt[i], type: 'text' })
          this.ReplaceValue.push('')
        }
      }
      this.inspect_template_data[propSuffix] = tempValue
    },
    setShowReporteeDep2(flag) {
      if (flag) {
        this.showReporteeDep2 = true
      } else {
        this.report.reportee_dep2 = ''
        this.report.requestContentKind2 = ''
        this.showReporteeDep2 = false
      }
    },
    setShowHighwayInput2(flag) {
      if (flag) {
        this.showHighwayInput2 = true
      } else {
        const pfx = 'inspect_highway2'
        this.report[`${pfx}_on_off_type`] = 'off'
        this.report[`${pfx}_name`] = ''
        this.report[`${pfx}_direction`] = ''
        this.report[`${pfx}_info`] = {}

        this.report.inspect_dt2_from = null
        this.report.inspect_dt2_to = null
        this.onInspectDtInput()

        this.$refs.highwayInputInspect2.onReport()
        this.showHighwayInput2 = false
      }
    },
    copyHighwayInfo1() {
      // 報告書欄の場所を指示欄の場所にコピーする
      this.$refs.highwayInputRequest1.copyHighwayInfo1(0)
    },
    getFilteredRequestContentKinds(reporteeDepKey) {
      if (!reporteeDepKey) { return this.requestContentKinds }

      const reporteeDep = this.reporteeDepMap[reporteeDepKey]
      if (!reporteeDep) { return this.requestContentKinds }

      // 雑だが、履行区分名でざっくり引っ掛けて絞り込む.
      const requestContentKindNamesFacility = [
        '施設物件関連',
      ]
      if (reporteeDep.reportee_type === 'civil') {
        return this.requestContentKinds.filter(e => {
          return !requestContentKindNamesFacility.some(name => e.name.startsWith(name))
        })
      } else if (reporteeDep.reportee_type === 'facility') {
        return this.requestContentKinds.filter(e => {
          return requestContentKindNamesFacility.some(name => e.name.startsWith(name))
        })
      }

      return this.requestContentKinds
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
