<template src="./template.html"></template>

<script>
import renumberingReportApi from '@/api/renumberingReport'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'

export default {
  name: 'renumberingList',
  data() {
    return {
      list: [],
      listItemsPerPage: 20,
      currentPage: 1,
      isSearchResultEmpty: false,
      renumbering: this.reNumbering,
      no_selected: this.noSelected,
      reporteeDeps: [],
      reporteeGroups: [],
      reporteeDepMap: {},
      reportKindList: [
        { code: 'ei', name: '緊急点検報告書' },
        { code: 'ch', name: '苦情処理状況報告書' },
        { code: 'rr', name: '保安規制実施状況報告書' },
        { code: 'ec', name: '緊急連絡報告書' }
      ],
      reportYearList: [],
      reportKind: '',
      reporteeDep: -1,
      reportYear: -1,
      rankType: 0,
      checkTargetAll: 0,
      reNumbering: 0,
      noSelected: 0
    }
  },
  computed: {
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmRenumberingModal() {
      return this.renumbering !== 0
    },
    showNoSelectedModal() {
      return this.no_selected !== 0
    },
    hasConditionError() {
      return this.reportKind === '' || this.reporteeDep === -1 || this.reportYear === -1
    }
  },
  async mounted() {
    this.rankType = 1
    this.checkTargetAll = 0
    this.renumbering = 0
    this.no_selected = 0
    this.initYearCombo()

    await this.ensureUserAndMasters()
    const lovs = window.master.lovs
    this.reporteeDeps = lovs.reportee_dep.vals_inuse
    this.reporteeDepMap = lovs.reportee_dep.map_inuse
    this.reporteeGroups = lovs.reportee_group.vals_inuse

    this.reportKind = this.reportKindList[0].code
    this.reportYear = this.reportYearList[this.reportYearList.length - 1].key
    this.reporteeDep = this.reporteeDeps[0].key
  },
  mixins: [masterMixin, dateMixin, listPageMixin],
  methods: {
    reportNumPrefixDisp(damageRank, reporteeDep) {
      // see EmergencyContactReport
      if (!damageRank) { return }
      const selectedRankPrf = damageRank.substring(0, 1)
      const reporteeDepInfo = this.reporteeDeps.find(e => e.key === reporteeDep)
      const reporteeGrpInfo = this.reporteeGroups.find(e => e.key === reporteeDepInfo.reportee_group)
      return selectedRankPrf === 'A' || selectedRankPrf === 'B' ? reporteeGrpInfo.disp1 : reporteeGrpInfo.disp2
    },
    onKindSelected() {
      this.list = []
      this.rankType = 1
      this.isSearchResultEmpty = false
    },
    doSearch() {
      if (this.hasConditionError) { return }
      const data = {
        reportKind: this.reportKind,
        reportDep: this.reporteeDep,
        reportYear: this.reportYear,
        rankType: this.rankType
      }

      this.isSearchResultEmpty = false
      this.list = []
      this.currentPage = 1
      this.checkTargetAll = 0

      renumberingReportApi.index(data)
        .then(this.waitForMasters)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            this.isSearchResultEmpty = true
            return
          }
          this.list = data
        })
    },
    confirmRenumbering() {
      // 選択中データの有無をチェック
      let selNum = 0
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].renum_check) {
          selNum = 1
          break
        }
      }

      if (selNum === 0) {
        this.no_selected = 1
      } else {
        this.renumbering = 1
      }
    },
    doRenumber() {
      const entries = this.list.map(e => {
        return {
          id: e.id,
          renum_check: e.renum_check === true ? 1 : 0,
          report_num_prop: e.report_num_prop,
          current_report_num: e[e.report_num_prop],
          num: e.num,
        }
      })

      // キー作成（帳票種別_年度_報告先）
      let key = this.reportKind + '-' + this.reportYear + '-' + this.reporteeDep
      // 緊急連絡の場合、ランク追加
      if (this.reportKind === 'ec') key = key + (this.rankType === 1 ? '-rankAB' : '-rankCD')
      //
      const params = { rep_key: key, entries }
      // API呼び出し
      renumberingReportApi.update({ data: params })
        .then(({ data }) => {
          this.doSearch()
          this.renumbering = 0
        })
        .catch(err => {
          this.handleErrorResponse(err)
          this.renumbering = 0
        })
    },
    initYearCombo() {
      const today = new Date()
      let currentNendo = today.getFullYear()
      const currentMonth = today.getMonth() + 1
      if (currentMonth < 4) { currentNendo-- }

      this.reportYearList.push({ key: currentNendo, name: currentNendo })
      if (currentMonth === 4) {
        // 4月になったばかりなら前年度のデータを直す可能性もあるだろう
        const prevNendo = currentNendo - 1
        this.reportYearList.push({ key: prevNendo, name: prevNendo })
      }
    },
    onChangeRenumberTarget() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].renum_check = this.checkTargetAll
      }
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
