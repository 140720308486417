<template>
<div class="photo-selector" ref="photoSelector"
    :class="{'overflow-y-scroll': true}">
  <div v-if="hasUploadFiles && !isVideoPhoto">
    <!-- 写真登録モード -->
    <div class="photo-add photo">
      <div class="photo-add-preview-area">
        <div class="thumbnail" v-for="photo in uploadFiles">
          <img class="photo-content" :src="photo.src">
        </div>
      </div>
      <div class="photo-add-bottom-bar ta-center">
        <button class="btn btn-lg btn-primary"
            :disabled="isUploadingPhoto"
            @click="addPhotos">
          <i class="fa fa-spinner fa-spin"
            v-show="isUploadingPhoto"></i>
          決定
        </button>
        <button class="btn btn-lg btn-default ml-8" @click="cancelAddPhoto">
          キャンセル
        </button>
      </div>
    </div>
  </div>
  <div v-if="hasUploadFiles && isVideoPhoto">
    <!-- 動画登録モード -->
    <div class="photo-add video-photo">
      <div class="canvas-wrapper">
        <div class="select-video">
          <video controls id="photo-video">
            <source :src="uploadFile.src">
          </video>
          <div class="photo-add-bottom-bar ta-center">
            <button class="btn btn-lg btn-primary" @click="drawVideo">
              切り出し
            </button>
            <button class="btn btn-lg btn-default" @click="cancelAddPhoto">
              キャンセル
            </button>
          </div>
          <table id="preview-table">
            <thead id="preview-header">
              <tr>
                <th v-show="!isVideoButton">切り出しプレビュー</th>
                <div id="close-button" class="xbutton" v-show="false">
                  <span id="close-span-1"></span>
                  <span id="close-span-2"></span>
                </div>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
          <canvas id="photo-canvas-realsize" v-show="false" ref="hiddenRealSizeCanvas"></canvas>
        </div>
        <div class="photo-add-bottom-bar ta-center" v-show="!isVideoButton">
          <button class="btn btn-lg btn-primary"
              :disabled="isVideoButton"
              @click="addVideoPhoto">
            <i class="fa fa-spinner fa-spin"
              v-show="isUploadingPhoto"></i>
            決定
          </button>
          <button class="btn btn-lg btn-default ml-8" @click="cancelAddPhoto">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isEditingPhoto">
    <!-- 編集モード -->
    <photo-editor
      :id="id"
      :photo="selectedPhoto"
      :api-name="apiName"
      @done="doneEditPhoto"
      @cancel="cancelEditPhoto"
      @should-handle-error-response="emitHandleErrorResponse">
    </photo-editor>
  </div>
  <div v-if="isNormalMode">
    <!-- 通常モード -->
    <div class="photo-list">
      <div class="row row1">
        <div class="thumbnail" v-for="photo in photos" :key='photo.id'
             :class="{
               selected: photo.id == selectedPhotoId,
               unselectable: !isSelectablePhoto(photo)
             }">
          <http-img class="photo-content" :src="photo.photo_url_medium"
            @click="selectPhoto(photo)">
          </http-img>
        </div>
        <div class="btn-add-photo">
          <label class="btn btn-lg btn-default" for="imgFileInput">
            写真<br>登録
          </label>
          <input type="file" @change="imgFileSelected"
            accept=".jpg, .jpeg, .png"
            style="display:none;" id="imgFileInput"
            multiple>
        </div>
        <div class="btn-add-photo">
          <label class="btn btn-lg btn-default" for="movieFileInput">
            動画<br>登録
          </label>
          <input type="file" @change="movieFileSelected"
                 accept=".mp4"
                 style="display:none;" id="movieFileInput">
        </div>
      </div>
    </div>
    <hr>
    <div class="selected-photo-area row2" v-if="selectedPhoto">
      <div class="col-xs-6">
        <div>
          <http-img class="photo-img" :src="selectedPhoto.photo_url_original"></http-img>
        </div>
        <div class="mt-4">
          <http-link class="btn btn-sm btn-default"
              :href="selectedPhoto.photo_url_original">
            <i class="fa fa-download"></i>
            ダウンロード
          </http-link>
        </div>
      </div>
      <div class="col-xs-6">
        <div v-if="showDeleteConfirm">
          <!-- 削除確認モード -->
          <div class="mb-10">
            <p>
              写真を削除します。よろしいですか？
            </p>
          </div>
          <div class="ta-center">
            <button class="btn btn-lg btn-primary"
                @click="deletePhoto">
              OK
            </button>
            <button class="btn btn-lg btn-default"
                @click="showDeleteConfirm = false">
              キャンセル
            </button>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-lg btn-primary" @click="usePhoto">
            <i class="fa fa-chain"></i>
            決定
          </button>
          <button class="btn btn-lg btn-default ml-8" @click="editPhoto">
            <i class="fa fa-edit"></i>
            編集
          </button>
          <button class="btn btn-lg btn-danger ml-8"
              @click="showDeleteConfirm = true"
              v-show="!isDefaultSelectedPhoto(selectedPhoto)">
            <i class="fa fa-trash"></i>
            削除
          </button>
          <button class="btn btn-lg btn-default ml-8" @click="unusePhoto"
              v-show="isDefaultSelectedPhoto(selectedPhoto)">
            <i class="fa fa-chain-broken"></i>
            解除
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import EVT from '@/consts/eventNames'
import imageMixin from '@/mixin/imageMixin'
let api

const MODE_NORMAL = 'normal'
const MODE_ADD = 'add'
const MODE_ADD_VIDEO = 'addVideo'
const MODE_EDIT = 'edit'

export default {
  name: 'photo-selector',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
    photos: {
      type: Array,
      required: true,
    },
    defaultSelectedPhoto: {
      type: Object,
    },
    stream: {
      type: Object,
    }
  },
  data() {
    return {
      uploadFile: null,
      uploadFiles: [],
      isVideoPhoto: false,
      isVideoButton: true,
      selectedPhotoId: null,
      isUploadingPhoto: false,
      isEditingPhoto: false,
      showDeleteConfirm: false,
      onResizeFunc: () => {},
    }
  },
  computed: {
    selectedPhoto() {
      return this.photos.find(p => p.id === this.selectedPhotoId)
    },
    hasUploadFiles() {
      return this.uploadFile || this.uploadFiles.length > 0
    },
    isNormalMode() {
      return !this.hasUploadFiles && !this.isEditingPhoto
    },
  },
  watch: {
    defaultSelectedPhoto() {
      if (!this.defaultSelectedPhoto ||
          !this.defaultSelectedPhoto.id) {
        this.selectedPhotoId = null
        return
      }
      this.selectedPhotoId = this.defaultSelectedPhoto.id
    },
    uploadFile() {
      if (!this.uploadFile) { return }
      this.loadImageWithExif(this.uploadFile.src)
        .then(image => {
          const previewCanvas = this.$refs.previewCanvas
          const hiddenRealSizeCanvas = this.$refs.hiddenRealSizeCanvas
          const maxHeight = window.innerHeight - 280
          const maxWidth = this.$refs.photoSelector.offsetWidth
          this.writeImageToCanvas(image, previewCanvas,
            { maxHeight, maxWidth })
          this.writeImageToCanvas(image, hiddenRealSizeCanvas)
        })
    },
  },
  mounted() {
    api = require(`@/api/${this.apiName}`).default
    this.setListeners()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResizeFunc)
  },
  mixins: [imageMixin],
  methods: {
    setMaxHeight() {
      this.$refs.photoSelector.style.maxHeight =
        (window.innerHeight - (60 + 70 + 50)) + 'px'
    },
    setListeners() {
      this.stream.recv(EVT.PHOTO.SELECTOR_RESET, () => {
        this.uploadFile = null
        this.uploadFiles = []
        this.isVideoPhoto = false
        this.isVideoButton = true
        this.isUploadingPhoto = false
        this.isEditingPhoto = false
        this.showDeleteConfirm = false
      })

      this.onResizeFunc = () => {
        this.setMaxHeight()
      }
      window.addEventListener('resize', this.onResizeFunc)
      this.onResizeFunc()
    },
    emitModeTransition(mode) {
      let titleStr = ''
      if (mode === MODE_NORMAL) {
        titleStr = '写真選択'
      } else if (mode === MODE_ADD) {
        titleStr = '写真登録'
      } else if (mode === MODE_ADD_VIDEO) {
        titleStr = '写真切り出し登録'
        this.isVideoButton = true
      } else if (mode === MODE_EDIT) {
        titleStr = '写真編集'
      }
      this.$emit('mode-change', {
        mode: mode,
        title: titleStr,
      })
    },
    isSelectablePhoto(photo) {
      return photo.used_index === null ||
        photo.used_index === (this.defaultSelectedPhoto || {}).used_index
    },
    isDefaultSelectedPhoto(photo) {
      return photo.id === (this.defaultSelectedPhoto || {}).id
    },
    selectPhoto(photo) {
      if (!this.isSelectablePhoto(photo)) { return }
      this.selectedPhotoId =
        this.selectedPhotoId === photo.id ? null : photo.id
    },
    _onFileSelected(evt, mode) {
      const f = evt.target.files[0]
      this.uploadFile = {
        name: f.name,
        type: f.type,
        file: f,
        src: window.URL.createObjectURL(f),
      }
      this.emitModeTransition(mode)
    },
    _onFilesSelected(evt, mode) {
      const uploadFiles = []
      Array.from(evt.target.files).forEach(f => {
        const uploadFile = {
          name: f.name,
          type: f.type,
          file: f,
          src: window.URL.createObjectURL(f),
        }
        uploadFiles.push(uploadFile)
      })
      this.uploadFiles = uploadFiles
      this.emitModeTransition(mode)
    },
    imgFileSelected(evt) {
      if (!evt.target.files || evt.target.files.length === 0) {
        return
      }
      this.isVideoPhoto = false
      this._onFilesSelected(evt, MODE_ADD)
    },
    movieFileSelected(evt) {
      if (!evt.target.files || evt.target.files.length === 0) {
        return
      }
      this.isVideoPhoto = true
      this._onFileSelected(evt, MODE_ADD_VIDEO)
    },
    cancelAddPhoto() {
      this.uploadFile = null
      this.uploadFiles = []
      this.emitModeTransition(MODE_NORMAL)
    },
    async addPhotos() {
      this.isUploadingPhoto = true
      try {
        const formData = new FormData()
        this.uploadFiles.forEach(e => {
          formData.append('photos[]', e.file, e.name)
        })
        const reqObj = {
          id: this.id,
          formData: formData,
        }
        await api.addPhotos(reqObj)
        this.isUploadingPhoto = false
        this.uploadFiles = []
        this.emitModeTransition(MODE_NORMAL)
        this.emitRefresh()
      } catch (err) {
        this.emitHandleErrorResponse(err)
        this.isUploadingPhoto = false
      }
    },
    addVideoPhoto() {
      const previewTable = document.getElementById('preview-table')
      this.isUploadingPhoto = true
      const formData = new FormData()
      for (let i = 1; i < previewTable.rows.length; i++) {
        const drawPreview = previewTable.rows[i].cells[0].children[2]
        const previewPhoto = this.$refs.hiddenRealSizeCanvas
        previewPhoto.width = drawPreview.width
        previewPhoto.height = drawPreview.height
        previewPhoto.getContext('2d').drawImage(drawPreview, 0, 0, drawPreview.width, drawPreview.height)
        this.uploadFile.name = this.uploadFile.name.replace('.mp4', `_${i}.jpg`)
        this.uploadFile.type = 'image/jpg'
        formData.append(
          'photos[]',
          this.canvasToBlob(this.$refs.hiddenRealSizeCanvas),
          this.uploadFile.name
        )
      }
      const reqObj = {
        id: this.id,
        formData: formData,
      }
      api.addPhotos(reqObj).then(() => {
        this.uploadFile = null
        this.isUploadingPhoto = false
        this.emitModeTransition(MODE_NORMAL)
        this.emitRefresh()
      }).catch(err => {
        this.emitHandleErrorResponse(err)
        this.isUploadingPhoto = false
      })
    },
    emitRefresh() {
      this.$emit('should-refresh')
    },
    emitHandleErrorResponse(err) {
      this.$emit('should-handle-error-response', err)
    },
    usePhoto() {
      if (!this.selectedPhoto) { return }
      this.$emit('use-photo', this.selectedPhoto)
    },
    unusePhoto() {
      if (!this.selectedPhoto) { return }
      this.$emit('unuse-photo', this.selectedPhoto)
    },
    editPhoto() {
      if (!this.selectedPhoto) { return }
      this.isEditingPhoto = true
      this.emitModeTransition(MODE_EDIT)
    },
    doneEditPhoto(photo) {
      this.isEditingPhoto = false
      this.emitModeTransition(MODE_NORMAL)
      this.emitRefresh()
      this.selectedPhotoId = photo.id
    },
    cancelEditPhoto() {
      this.isEditingPhoto = false
      this.emitModeTransition(MODE_NORMAL)
    },
    deletePhoto() {
      if (!this.selectedPhoto) { return }
      api.deletePhoto({
        id: this.id,
        photo: {
          id: this.selectedPhoto.id,
        }
      }).then(() => {
        this.selectedPhotoId = null
        this.showDeleteConfirm = false
        this.emitRefresh()
      }).catch(err => {
        this.emitHandleErrorResponse(err)
      })
    },
    downloadPhoto() {
    },
    drawVideo() {
      const canvas = document.createElement('canvas')
      const cell = document.getElementById('preview-table').insertRow(-1).insertCell(-1)
      const video = document.getElementById('photo-video')
      const canvasReal = document.getElementById('photo-canvas-realsize').cloneNode()
      const xButton = document.getElementById('close-button').cloneNode()
      const closeSpan1 = document.getElementById('close-span-1').cloneNode()
      const closeSpan2 = document.getElementById('close-span-2').cloneNode()
      xButton.style = 'display: true;'
      xButton.addEventListener('click', this.drawVideoCloseButtonClick)
      xButton.eventParam = cell
      xButton.appendChild(closeSpan1)
      xButton.appendChild(closeSpan2)
      // 切り出し動画サムネイルサイズ
      canvas.width = 640
      canvas.height = 360
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height)
      canvasReal.width = video.videoWidth
      canvasReal.height = video.videoHeight
      canvasReal.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      canvasReal.id = 'drawCanvas'
      canvasReal.ref = 'drawCanvas'
      cell.appendChild(canvas)
      cell.appendChild(xButton)
      cell.appendChild(canvasReal)
      this.isVideoButton = false
    },
    drawVideoCloseButtonClick(e) {
      let previewTable
      if (e.target.id === 'close-button') {
        previewTable = e.target.parentElement.parentElement.parentElement
        e.target.parentElement.parentElement.remove()
      } else {
        previewTable = e.target.parentElement.parentElement.parentElement.parentElement
        e.target.parentElement.parentElement.parentElement.remove()
      }

      if (previewTable.rows.length === 1) {
        this.isVideoButton = true
      } else {
        this.isVideoButton = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.overflow-y-scroll {
  overflow-y: scroll;
}
.photo-list {
  max-height: 350px;
  overflow: hidden scroll;
}
.row1 {
  margin: 0 -4px;
}
.row2 {
  margin: 0 -10px;
}
.thumbnail {
  width: 200px;
  height: 200px;
  float: left;
  margin: 4px 8px;
  border-color: #dddddd;
  cursor: pointer;
  &.selected {
    border-color: red;
  }
  &.unselectable {
    opacity: 0.5;
    cursor: default;
  }

  .photo-content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.btn-add-photo {
  float: left;
  margin: 4px 8px;
}
.photo-img {
  max-height: 300px;
  max-width: 100%;
}
.photo-add-bottom-bar {
  margin-top: 8px;
}
.select-video {
  text-align: center !important;
  video {
    width: 80% !important;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  video::-webkit-media-controls-play-button {
    display: true;
   }
  video::-webkit-media-controls-timeline {
    display: true;
  }
  video::-webkit-media-controls-current-time-display {
    display: true;
   }
  video::-webkit-media-controls-time-remaining-display {
    display: true;
   }
  video::-webkit-media-controls-mute-button {
    display: true;
   }
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: true;
   }
  video::-webkit-media-controls-volume-slider {
    display: true;
   }
  table {
    width: 100% !important;
  }
  td, th {
    text-align: center !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  canvas {
    width: 100% !important;
  }
}
.xbutton {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  vertical-align: top;
  text-align: left;
  margin-left: -50px;
  cursor: pointer;
  span::before, span::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 84%;
    height: 16%;
    margin: -8% 0 0 -42%;
    background: #E91E63;
  }
  span::before {
    transform: rotate(-45deg);
  }
  span::after {
    transform: rotate(45deg);
  }
}
.photo-add.photo {
  display: grid;
  .photo-add-preview-area {
    .thumbnail {
      cursor: default;
    }
  }
}
</style>
