<template>
<div class="report-header-wrapper">
  <div class="top-bar ta-center">
    <label class="top-bar-lbl top-left">
      勤務情報
    </label>
    <div>
      <template v-if="isReportHeaderNew">
        <button class="btn btn-lg btn-default mr-8"
            @click="$router.go(-1)">
          戻る
        </button>
        <button class="btn btn-lg btn-info mr-8"
            @click="createOrUpdateReportHeader({ showCompleteModal: true, shouldGoBackToTop: true })">
          保存
        </button>
        <button class="btn btn-lg btn-primary"
            @click="createOrUpdateReportHeader()">
          報告書入力
        </button>
      </template>
      <template v-else>
        <button class="btn btn-lg btn-default mr-8"
            @click="showReportBody">
          報告書を表示
        </button>
        <button class="btn btn-lg btn-primary"
            @click="updateReportHeader({ showCompleteModal: true })">
          保存
        </button>
      </template>
    </div>
    <div v-show="hasError" class="err-msg ta-left">
      <div v-for="msg in errorMessages">・{{ msg }}</div>
    </div>
  </div>

  <div class="report-header">
    <div class="row1 mb-8">
      <div class="ib" style="width:70mm;">
        <span class="lbl ib mr-4">日付：</span>
        <my-calendar v-show="isReportHeaderNew"
          :class="{vm: true}"
          :date-value="dt"
          @date-input="dt = $event; onDateTimeslotInput();"
          :input-class-obj="{
            'bg-blue': true,
            'is-error': this.errors.dt,
          }"
          width="140px"
          :clear-button2-disabled="true"
          :readonly="true"
          :max-future-days="0"
          :max-past-days="20"
        ></my-calendar>
        <span class="lbl" style="font-weight:bold;" v-show="!isReportHeaderNew">
          {{dt | dtFormatYMDA1}}
        </span>
      </div>
      <div class="ib">
        <span class="lbl ib" style="margin-right:-8px;">時間帯：</span>
        <label class="radio-inline my-radio" v-for="e in timeslots"
            :disabled="!isReportHeaderNew">
          <input type="radio" :name="'radio-g1-' + _uid" class="radio"
            :value="e.key" :checked="timeslot === e.key"
            :disabled="!isReportHeaderNew"
            @change="onDateTimeslotInput"
            v-model="timeslot">
          <i class="fa fa-circle-o fa-2x"></i>
          <i class="fa fa-dot-circle-o fa-2x"></i>
          <span class="lbl">{{e.name}}</span>
        </label>
      </div>
    </div>
    <div class="row2 mb-8">
      <div class="ib" style="width:90mm;">
        <span class="lbl ib mr-12">総括調査員(土木)</span>
        <select class="ib vm bg-blue form-control"
            :class="{'is-error': errors.manager_inspector_civil}"
            style="width:170px;"
            v-model="manager_inspector_civil">
          <option v-for="elem in managerInspectorCivils"
                  v-bind:value="elem.name">
            {{elem.name}}
          </option>
        </select>
      </div>
      <div class="ib">
        <span class="lbl ib mr-12">総括調査員(施設)</span>
        <select class="ib vm bg-blue form-control"
            :class="{'is-error': errors.manager_inspector_facility}"
            style="width:170px;"
            v-model="manager_inspector_facility">
          <option v-for="elem in managerInspectorFacilities"
                  v-bind:value="elem.name">
            {{elem.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row3">
      <table class="table">
        <tr class="t-header-row">
          <th width="18mm" colspan="2" style="padding-left:10px;">グループ</th>
          <th width="40mm">勤務時間</th>
          <th width="32mm">点検調査員名</th>
          <th width="32mm">運転手名</th>
        </tr>
        <tr v-for="num in dispCrewNumbers" class="t-row"
            :class="{unselected: crew_number !== num}">
          <td width="4mm" style="padding-left:8px;">
            <label class="radio2 my-radio">
              <input type="radio" :name="'radio-g2-' + _uid" class="radio"
                :value="num" :checked="crew_number === num"
                v-model="crew_number">
              <i class="fa fa-circle-o fa-2x"></i>
              <i class="fa fa-dot-circle-o fa-2x"></i>
              <span class="lbl"></span>
            </label>
          </td>
          <td width="16mm">
            <span class="ib" style="font-weight:bold;">{{num}}</span>
          </td>
          <!--
          <td>
            <time-input :value="crews[`work_start${num}_h`]" mode="hour"
              :class="{'is-error': errors[`work_start${num}`]}"
              @input="onTimeInput(`work_start${num}`, 'h', $event)">
            </time-input>
            <span class="colon ib ml-2 mr-2">:</span>
            <time-input :value="crews[`work_start${num}_m`]" mode="minute"
              :class="{'is-error': errors[`work_start${num}`]}"
              @input="onTimeInput(`work_start${num}`, 'm', $event)">
            </time-input>

            <span class="tilda ib ml-8 mr-8">〜</span>

            <time-input :value="crews[`work_end${num}_h`]" mode="hour"
              :class="{'is-error': errors[`work_end${num}`]}"
              @input="onTimeInput(`work_end${num}`, 'h', $event)">
            </time-input>
            <span class="colon ib ml-2 mr-2">:</span>
            <time-input :value="crews[`work_end${num}_m`]" mode="minute"
              :class="{'is-error': errors[`work_end${num}`]}"
              @input="onTimeInput(`work_end${num}`, 'm', $event)">
            </time-input>
          </td>
          -->
          <td>
            <span>{{ ('0' + crews[`work_start${num}_h`]).slice(-2) }}</span>
            <span class="colon ib ml-2 mr-2">:</span>
            <span>{{ ('0' + crews[`work_start${num}_m`]).slice(-2) }}</span>

            <span class="tilda ib ml-8 mr-8">〜</span>

            <span>{{ ('0' + crews[`work_end${num}_h`]).slice(-2) }}</span>
            <span class="colon ib ml-2 mr-2">:</span>
            <span>{{ ('0' + crews[`work_end${num}_m`]).slice(-2) }}</span>
          </td>
          <td>
            <select class="ib vm bg-blue form-control"
                :class="{'is-error': errors[`inspector${num}`]}"
                style="width:170px;"
                v-model="crews[`inspector${num}`]">
              <option value=""></option>
              <option v-for="elem in inspectors"
                      v-bind:value="elem.name">
                {{elem.name}}
              </option>
            </select>
          </td>
          <td>
            <select class="ib vm bg-blue form-control"
                :class="{'is-error': errors[`driver${num}`]}"
                style="width:170px;"
                v-model="crews[`driver${num}`]">
              <option value=""></option>
              <option v-for="elem in drivers"
                      v-bind:value="elem.name">
                {{elem.name}}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <modal
    v-show="showUpdateCompleteModal"
    title="保存完了"
    :showBottomDismissButton="false"
    @close="closeUpdateCompleteModal">
    <div slot="body">
      <div class="modal-wrap1">
        <p class="p1">勤務情報を保存しました。</p>
        <p class="p1" v-if="shouldGoBackToTop">トップ画面へ戻ります。</p>
        <p class="p1" v-else>報告書を表示します。</p>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
import Vue from 'vue'
// import TimeInput from '@/components/lib/TimeInput'
import reportHeaderApi from '@/api/reportHeader'
import EventNames from '@/consts/eventNames'
import validatorMixin from '@/mixin/validatorMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'report-header',
  props: {
    isReportHeaderNew: {
      type: Boolean,
      default: true,
    },
    reportHeader: {
      type: Object,
      default() {
        return {}
      }
    },
    crewNumber: {
      type: Number,
    },
    stream: {
      type: Object,
    }
  },
  data() {
    let now = new Date()
    let timeslot = '1'
    let work_start = 900
    let work_end = 1700
    if (now.getHours() >= 17 || now.getHours() < 9) {
      timeslot = '2'
      work_start = 1700
      work_end = 900
      // 0-9時は夜勤扱いだから前日を表示
      if (now.getHours() < 9) {
        now = new Date(now.valueOf() - 86400 * 1000)
      }
    }
    const work_start_h = parseInt(work_start / 100)
    const work_start_m = work_start % 100
    const work_end_h = parseInt(work_end / 100)
    const work_end_m = work_end % 100

    return {
      rhId: -1,
      dt: now,
      timeslot: timeslot,
      crew_number: 1,
      manager_inspector_civil: '',
      manager_inspector_facility: '',
      crews: {
        work_start1_h: work_start_h,
        work_start1_m: work_start_m,
        work_end1_h: work_end_h,
        work_end1_m: work_end_m,
        inspector1: '',
        driver1: '',
        work_start2_h: work_start_h,
        work_start2_m: work_start_m,
        work_end2_h: work_end_h,
        work_end2_m: work_end_m,
        inspector2: '',
        driver2: '',
      },
      dispCrewNumbers: [1, 2],

      managerInspectorCivils: [],
      managerInspectorFacilities: [],
      inspectors: [],
      drivers: [],

      timeslots: [],
      errors: {},

      showUpdateCompleteModal: false,
      shouldGoBackToTop: false,

      rhDtMap: {},
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const acc = []
      const tmpMap = {}
      const ret = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      acc.forEach(e => {
        if (tmpMap[e]) { return }
        tmpMap[e] = true
        ret.push(e)
      })
      return ret
    },
  },
  watch: {
    reportHeader() {
      // 作成済の報告書から表示された場合.
      this.setReportHeader(this.reportHeader)
    },
    crewNumber() {
      this.crew_number = this.crewNumber
    }
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.timeslots = lovs.timeslot.vals_inuse
      this.managerInspectorCivils = lovs.manager_inspector_civil.vals_inuse
      this.managerInspectorFacilities = lovs.manager_inspector_facility.vals_inuse
      this.inspectors = lovs.inspector.vals_inuse
      this.drivers = lovs.driver.vals_inuse
    })
    this.getReportHeaders()
  },
  mixins: [validatorMixin, errorHandleMixin],
  methods: {
    getEmptyReportHeader() {
      let work_start = 900
      let work_end = 1700
      if (parseInt(this.timeslot) === 2) {
        work_start = 1700
        work_end = 900
      }

      return {
        id: -1,
        dt: this.dt,
        timeslot: this.timeslot,
        crew_number: 1,
        manager_inspector_civil: '',
        manager_inspector_facility: '',
        work_start1: work_start,
        work_end1: work_end,
        inspector1: '',
        driver1: '',
        work_start2: work_start,
        work_end2: work_end,
        inspector2: '',
        driver2: '',
      }
    },
    getReportHeaders() {
      // 基本的に当日のものしか必要ないはずだが、
      // (そんなに戻れないし)まぁなんか取得しておく.
      const obj = {
        from: new Date(new Date().valueOf() - 86400 * 30 * 1000),
        to: new Date(),
      }
      reportHeaderApi.index(obj).then(({ data }) => {
        if (!data) { return }

        const dtFormat = Vue.filter('dtFormat')
        const rhDtMap = {}
        data.forEach(e => {
          e.dt = new Date(e.dt)
          const dtConv = dtFormat(e.dt, 'yyyy-mm-dd')
          const dtKey = `${dtConv}.${e.timeslot}`
          rhDtMap[dtKey] = e
        })
        this.rhDtMap = rhDtMap

        this.setReportHeaderByDateTimeslot()
      })
    },
    setReportHeaderByDateTimeslot() {
      const dtConv = Vue.filter('dtFormat')(this.dt, 'yyyy-mm-dd')
      const dtKey = `${dtConv}.${this.timeslot}`
      if (this.rhDtMap[dtKey]) {
        this.setReportHeader(this.rhDtMap[dtKey])
      }
    },
    setReportHeader(rh) {
      if (!rh || !rh.dt) { return }

      this.rhId = rh.id
      this.dt = new Date(rh.dt)
      this.timeslot = rh.timeslot
      this.manager_inspector_civil = rh.manager_inspector_civil
      this.manager_inspector_facility = rh.manager_inspector_facility
      this.dispCrewNumbers.forEach(num => {
        let tm = rh[`work_start${num}`]
        this.crews[`work_start${num}_h`] = tm === null ? null : parseInt(tm / 100)
        this.crews[`work_start${num}_m`] = tm === null ? null : tm % 100

        tm = rh[`work_end${num}`]
        this.crews[`work_end${num}_h`] = tm === null ? null : parseInt(tm / 100)
        this.crews[`work_end${num}_m`] = tm === null ? null : tm % 100

        this.crews[`inspector${num}`] = rh[`inspector${num}`]
        this.crews[`driver${num}`] = rh[`driver${num}`]
      })
    },
    showReportBody() {
      if (!this.stream) { return }
      this.stream.send(EventNames.RH.SHOW_REPORT_BODY, {})
    },
    notifyUpdateComplete(obj) {
      if (!this.stream) { return }
      this.stream.send(EventNames.RH.UPDATE_COMPLETE, obj)
    },
    checkInputs() {
      this.errors = {}
      let prop
      let val

      prop = 'dt'
      this.checkRequired(this[prop], prop, '日付', this.errors)

      // 総括(土木)
      prop = 'manager_inspector_civil'
      this.checkRequired(this[prop], prop, '総括調査員(土木)', this.errors)
      // 総括(施設)
      prop = 'manager_inspector_facility'
      this.checkRequired(this[prop], prop, '総括調査員(施設)', this.errors)

      // 選択されてるグループについては、全項目必須
      const num = this.crew_number
      // 勤務時間
      val = this.crews[`work_start${num}_h`]
      if (val === null || val === '' || isNaN(val) || val < 0 || val > 23) {
        this.errors[`work_start${num}`] = ['勤務開始時間は必須項目です']
      }
      val = this.crews[`work_start${num}_m`]
      if (val === null || val === '' || isNaN(val) || val < 0 || val > 59) {
        this.errors[`work_start${num}`] = ['勤務開始時間は必須項目です']
      }
      val = this.crews[`work_end${num}_h`]
      if (val === null || val === '' || isNaN(val) || val < 0 || val > 23) {
        this.errors[`work_end${num}`] = ['勤務終了時間は必須項目です']
      }
      val = this.crews[`work_end${num}_m`]
      if (val === null || val === '' || isNaN(val) || val < 0 || val > 59) {
        this.errors[`work_end${num}`] = ['勤務終了時間は必須項目です']
      }

      // 点検調査員
      prop = `inspector${num}`
      this.checkRequired(this.crews[prop], prop, '点検調査員', this.errors)
      // 運転手
      prop = `driver${num}`
      this.checkRequired(this.crews[prop], prop, '運転手', this.errors)

      return Object.keys(this.errors).length === 0
    },
    getReqObj_() {
      return {
        dt: Vue.filter('dtFormat')(this.dt, 'yyyy-mm-dd'),
        timeslot: this.timeslot,
        manager_inspector_civil: this.manager_inspector_civil,
        manager_inspector_facility: this.manager_inspector_facility,
        work_start1: this.crews.work_start1_h * 100 + this.crews.work_start1_m,
        work_end1: this.crews.work_end1_h * 100 + this.crews.work_end1_m,
        inspector1: this.crews.inspector1,
        driver1: this.crews.driver1,
        work_start2: this.crews.work_start2_h * 100 + this.crews.work_start2_m,
        work_end2: this.crews.work_end2_h * 100 + this.crews.work_end2_m,
        inspector2: this.crews.inspector2,
        driver2: this.crews.driver2,
      }
    },
    createOrUpdateReportHeader({ showCompleteModal, shouldGoBackToTop } = {}) {
      if (this.rhId === -1) {
        this.createReportHeader({ showCompleteModal })
      } else {
        this.updateReportHeader({ showCompleteModal })
      }
      this.shouldGoBackToTop = shouldGoBackToTop
    },
    createReportHeader({ showCompleteModal } = {}) {
      if (!this.checkInputs()) { return }

      reportHeaderApi.create({ data: this.getReqObj_()})
        .then(({ data }) => {
          data.dt = new Date(data.dt)
          const dtConv = Vue.filter('dtFormat')(data.dt, 'yyyy-mm-dd')
          const dtKey = `${dtConv}.${data.timeslot}`
          this.rhId = data.id
          this.rhDtMap[dtKey] = data

          const data2 = Object.assign({}, data)
          data2.crew_number = this.crew_number
          this.notifyUpdateComplete(data2)
          if (showCompleteModal) {
            this.showUpdateCompleteModal = true
          } else {
            this.showReportBody()
          }
        })
        .catch(err => {
          this.handleErrorResponse(err)

          // dupだったらその旨のみ表示
          const errRes = err.response.data
          if (errRes.reason === 'dup') {
            this.errors = {
              dup: [
                'その日付、時間帯の勤務情報は既に作成済です。' +
                '画面を再読込してください。'
              ],
            }
          }
        })
    },
    updateReportHeader({ showCompleteModal } = {}) {
      if (!this.checkInputs()) { return }

      // 日付と時間帯は送っちゃうけど、サーバー側で無視してる
      reportHeaderApi.update({
        id: this.rhId,
        data: this.getReqObj_(),
      }).then(({ data }) => {
        data.crew_number = this.crew_number
        this.notifyUpdateComplete(data)
        if (showCompleteModal) {
          this.showUpdateCompleteModal = true
        } else {
          this.showReportBody()
        }
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    closeUpdateCompleteModal() {
      if (this.shouldGoBackToTop) {
        this.$router.push({ name: 'top' })
      } else {
        this.showReportBody()
        this.showUpdateCompleteModal = false
      }
    },
    onDateTimeslotInput() {
      const dtConv = Vue.filter('dtFormat')(this.dt, 'yyyy-mm-dd')
      const dtKey = `${dtConv}.${this.timeslot}`
      if (this.rhDtMap[dtKey]) {
        this.setReportHeaderByDateTimeslot()
      } else {
        this.setReportHeader(this.getEmptyReportHeader())
      }
    },
    onTimeInput(prop, subProp, arg) {
      let val = arg
      if (val instanceof Event) {
        val = arg.target.value
      }

      const valMin = 0
      const valMax = subProp === 'h' ? 23 : 59
      if (val === '' || isNaN(val) || val < valMin || val > valMax) {
        this.crews[`${prop}_h`] = null
        this.crews[`${prop}_m`] = null
        return
      }

      let hVal = 0
      let mVal = 0
      if (subProp === 'h') {
        hVal = parseInt(val)
        mVal = this.crews[`${prop}_m`] === null ? 0 : this.crews[`${prop}_m`]
      } else {
        hVal = this.crews[`${prop}_h`] === null ? 0 : this.crews[`${prop}_h`]
        mVal = parseInt(val)
      }
      this.crews[`${prop}_h`] = hVal
      this.crews[`${prop}_m`] = mVal
    },
  },
  // components: { TimeInput }
}
</script>

<style lang="scss" scoped>
  .top-bar {
    width: 210mm;
    margin: 18px auto 0;
    position: relative;
    .top-bar-lbl {
      font-size: 20px;
      font-weight: normal;
    }
    .top-left {
      position: absolute;
      top: 10px;
      left: 12px;
    }
  }
.report-header {
  width: 210mm;
  margin: 18px auto;
  background-color: #ffffff;
  padding: 12px 16px;
  border: 1px solid #dddddd;
}
.lbl {
  font-size: 16px;
  vertical-align: middle;
}
.radio-inline {
  font-size: 16px;
  margin: 0;
}
.radio2 {
  margin: 0 8px 0 0;
  cursor: pointer;
}
.row1 {
  padding: 0 0 12px 0;
  border-bottom: 1px solid #ddd;
}
.row2 {
  padding: 4px 0 12px;
  border-bottom: 1px solid #ddd;
}
.row3 {
}
.table {
  font-size: 16px;
  table-layout: fixed;
  width: 98%;
  margin: 0 auto;

  .t-header-row {
    height: 34px;
    border-bottom: 1px solid #ddd;
  }
  .t-row {
    height: 46px;

    &.unselected {
      background-color: #eee;
    }
  }
}

.err-msg {
  padding: 12px 10mm 0;
  width: 210mm;
  margin: 0 auto;
}
</style>
