<template>
<div class="highway-input1">
  <div v-for="hwObj in hwObjs">
    <div class="mb-4" v-show="showHighwayOnOffRadioButton">
      <label class="radio-inline my-radio" v-for="e in hwOnOffTypes">
        <input type="radio" :name="'radio-' + _uid" class="radio"
          :value="e.key"
          :checked="hwObj.on_off_type === e.key"
          @change="onOnOffTypeChange(hwObj, $event)">
        <i class="fa fa-circle-o fa-2x"></i>
        <i class="fa fa-dot-circle-o fa-2x"></i>
        <span class="lbl">{{e.name}}</span>
      </label>
    </div>
    <div class="mt-4 mb-4">
      <span class="lbl mr-8">路線名</span>
      <select class="ib val bg-blue form-control hw-name"
          :value="hwObj.name"
          @change="onRoadNameChange(hwObj, $event)">
        <option v-for="elem in filteredHighwayNames(hwObj)" :value="elem.key">
          {{elem.name}}
        </option>
      </select>
      <button
        class="btn btn-default btn-xs"
        style="margin:-4px 0 0 4px;"
        @click="() => { showAllHighwayNames[hwObj.num] = true }">
        絞込解除
      </button>
      <span class="lbl ml-12 mr-8">方向</span>
      <select class="ib val bg-blue form-control hw-direction"
          :value="hwObj.direction"
          @change="onDirectionChange(hwObj, $event)">
        <option value=""></option>
        <option v-for="elem in hwObj.hwDirections" :value="elem.key">
          {{elem.name}}
        </option>
      </select>
    </div>
    <div v-show="isOnHighway(hwObj)">
      <div class="mb-4">
        <div class="ib">
          <span class="lbl wd64 mr-8">車線</span>
          <select class="ib val bg-blue form-control hw-lane"
              :value="hwObj.info.lane"
              @input="onInput(hwObj, 'info.lane', $event)">
            <option value=""></option>
            <option v-for="elem in hwLanes" :value="elem.key">
              {{elem.name}}
            </option>
          </select>
        </div>
        <div class="ib ml-8">
          <span class="lbl mr-8">左右区分</span>
          <select class="ib val bg-blue form-control hw-lr"
              :value="hwObj.info.lr"
              @input="onInput(hwObj, 'info.lr', $event)">
            <option value=""></option>
            <option v-for="elem in hwLrs" :value="elem.key">
              {{elem.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-4" v-if="type !== 'regulate'">
        <span class="lbl wd64 mr-8">点検箇所（目印）</span>
        <select class="ib val vt bg-blue form-control hw-place1-type"
            :value="hwObj.info.place1_type"
            @input="onInput(hwObj, 'info.place1_type', $event)">
          <option value=""></option>
          <option v-for="elem in hwPlace1Types" :value="elem.key">
            {{elem.name}}
          </option>
        </select>
        <input type="text" class="val form-control ib bg-blue hw-place1-text ml-4"
          :value="hwObj.info.place1_text"
          @input="onInput(hwObj, 'info.place1_text', $event)">
      </div>
      <div class="mb-4">
        <span class="lbl wd64 mr-8">概略位置</span>
        <input type="text" class="val form-control ib bg-blue"
          :value="hwObj.info.other_text"
          style="width:70%;"
          @input="onInput(hwObj, 'info.other_text', $event)">
      </div>

      <div class="mb-4" v-if="civilFacility === 'facility'">
        <span class="lbl wd64 mr-8">形状</span>
        <div style="display:inline-block;margin-top: 4px;">
          <select class="ib val bg-blue form-control hw-place2-type"
              :value="hwObj.info.place2_type"
              @change="onPlace2TypeChange(hwObj, $event)">
            <option value=""></option>
            <option v-for="elem in hwPlace2Types" :value="elem.key">
              {{elem.name}}
            </option>
          </select>
          <select class="ib val bg-blue form-control hw-place2-text ml-4"
              :value="hwObj.info.place2_text"
              @input="onInput(hwObj, 'info.place2_text', $event)">
            <option value=""></option>
            <option v-for="elem in hwObj.hwPlace2Texts" :value="elem.key">
              {{elem.name}}
            </option>
          </select>
          <template v-if="placeType2TypeMapForShowPlace2TextOther[hwObj.info.place2_type]">
            <!-- PAもしくは料金所 -->
            <select class="ib val bg-blue form-control hw-place2-text-other1 ml-4"
                    :value="hwObj.info.place2_text_other1"
                    @input="onInput(hwObj, 'info.place2_text_other1', $event)">
              <option value=""></option>
              <option v-for="elem in hwPlace2TextOther1" :value="elem.name">
                {{elem.name}}
              </option>
            </select>
            <select class="ib val bg-blue form-control hw-place2-text-other2 ml-4"
                    :value="hwObj.info.place2_text_other2"
                    @input="onInput(hwObj, 'info.place2_text_other2', $event)">
              <option value=""></option>
              <option v-for="elem in hwPlace2TextOther2" :value="elem.name">
                {{elem.name}}
              </option>
            </select>
          </template>
        </div>
      </div>
    </div>
    <div v-show="isOffHighway(hwObj)">
      <div class="mb-4" v-if="type !== 'regulate'">
        <span class="lbl wd64 mr-8">点検箇所（目印）</span>
        <select class="ib val vt bg-blue form-control hw-place1-type"
            :value="hwObj.info.place1_type"
            @input="onInput(hwObj, 'info.place1_type', $event)">
          <option value=""></option>
          <option v-for="elem in hwPlace1Types" :value="elem.key">
            {{elem.name}}
          </option>
        </select>
        <input type="text" class="val form-control ib bg-blue hw-place1-text ml-4"
          :value="hwObj.info.place1_text"
          @input="onInput(hwObj, 'info.place1_text', $event)">
      </div>
      <div class="mb-4">
        <span class="lbl wd64 mr-8">住所</span>
        <input type="text" class="val form-control ib bg-blue"
          :value="hwObj.info.street_name"
          style="width:70%;"
          @input="onInput(hwObj, 'info.street_name', $event)">
      </div>
      <div class="mb-4">
        <span class="lbl wd64 mr-8">点検場所</span>
        <input type="text" class="val form-control ib bg-blue"
          :value="hwObj.info.other_text"
          style="width:70%;"
          @input="onInput(hwObj, 'info.other_text', $event)">
      </div>
    </div>

    <div v-if="type === 'regulate' && isOnHighway(hwObj)">
      <div class="mb-4">
        <div class="ib">
          <span class="lbl wd64 mr-8">規制開始</span>
          <input type="text"
            class="val form-control ib bg-blue road-regulation-kp"
            :value="hwObj.info.start_kp"
            @input="onInput(hwObj, 'info.start_kp', $event)">
          <span class="lbl ml-4">KP</span>
          <span class="lbl ml-8 mr-8">〜</span>
          <span class="lbl wd64 mr-8">規制終了</span>
          <input type="text"
            class="val form-control ib bg-blue road-regulation-kp"
            :value="hwObj.info.end_kp"
            @input="onInput(hwObj, 'info.end_kp', $event)">
          <span class="lbl ml-4">KP</span>
        </div>
      </div>
      <div class="mb-4">
        <span class="lbl wd64 mr-8">規制区分</span>
        <select class="ib val vm bg-blue form-control road-regulation-type"
            :value="hwObj.info.regulation_type"
            @input="onInput(hwObj, 'info.regulation_type', $event)">
          <option value=""></option>
          <option v-for="elem in roadRegulationTypes" :value="elem.name">
            {{elem.name}}
          </option>
        </select>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import masterMixin from '@/mixin/masterMixin'
export default {
  name: 'highway-input1',
  props: {
    report: {
      type: Object,
      required: true,
    },
    num: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      required: true,
    },
    reporteeDep1ForFilter: {
      type: Object,
    },
    reporteeDep2ForFilter: {
      type: Object,
    },
    civilFacility: {
      type: String,
      default: 'civil',
    },
    highwayOnOffOnlyOn: {
      type: Boolean,
      default: false,
    },
    highwayOnOffOnlyOff: {
      type: Boolean,
      default: false,
    },
    showHighwayOnOffRadioButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      onHighwayLiteral: 'on',
      offHighwayLiteral: 'off',
      hwObjs: [],

      hwOnOffTypes: [],
      hwNames: [],
      hwLanes: [],
      hwLrs: [],
      hwPlace1Types: [],
      hwPlace2Types: [],
      roadRegulationTypes: [],

      hwDirectionMap: {},

      hwNameDirectionRelMap: {},
      hwPlace2TextMyMap: {},

      showAllHighwayNames: {
        1: false,
        2: false,
      },

      placeType2TypeMapForShowPlace2TextOther: {
        7: true,
        8: true,
      },
      hwPlace2TextOther1: [
        { name: 'No-1' },
        { name: 'No-2' },
        { name: 'No-3' },
        { name: 'No-4' },
        { name: 'No-5' },
        { name: 'No-6' },
        { name: '生活' },
        { name: '仮眠' },
        { name: 'センター' },
        { name: 'No-7' },
        { name: '機械室' },
      ],
      hwPlace2TextOther2: [
        { name: 'レーン' },
        { name: 'ブース' },
      ]
    }
  },
  watch: {
    report() {
      this.waitForMasters().then(() => {
        this.onReport()
      })
    },
    reporteeDep1ForFilter() {
      const hwObj = this.hwObjs[0]
      if (!hwObj) { return }
      this.showAllHighwayNames[1] = false
      this.$nextTick(() => {
        hwObj.name = ''
        this.onRoadNameChange(hwObj)
      })
    },
    // hwObjs[1]が入ることはないから、意味ない.
    // 消してもいいだろう. あと、実務上は報告先2が追加されても
    // 報告先1と局は同じだろうから、絞り込みの結果は変わらないだろう.
    reporteeDep2ForFilter() {
      const hwObj = this.hwObjs[1]
      if (!hwObj) { return }
      this.showAllHighwayNames[2] = false
      this.$nextTick(() => {
        hwObj.name = ''
        this.onRoadNameChange(hwObj)
      })
    },
    highwayOnOffOnlyOn() {
      this.setHighwayOnOffTypes()
    },
    highwayOnOffOnlyOff() {
      this.setHighwayOnOffTypes()
    },
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.setHighwayOnOffTypes()
      this.hwNames = this.civilFacility === 'facility' ? lovs.highway_facility.vals_inuse : lovs.highway.vals_inuse
      this.hwLanes = lovs.highway_lane_type.vals_inuse
      this.hwLrs = lovs.highway_lr_type.vals_inuse
      this.hwPlace1Types = lovs.highway_place1_type.vals_inuse
      this.hwPlace2Types = lovs.highway_place2_type.vals_inuse
      this.roadRegulationTypes = lovs.road_regulation_type.vals_inuse
      this.hwDirectionMap = this.civilFacility === 'facility' ? lovs.highway_direction_type_facility.map_inuse : lovs.highway_direction_type.map_inuse

      let map = {}
      const nameDirRel = this.civilFacility === 'facility' ? lovs.highway_name_dir_rel_facility.vals_inuse : lovs.highway_name_dir_rel.vals_inuse
      nameDirRel.forEach(e => {
        // 路線名ごとにまとめる
        const mapKey = e.key
        // lov_devで定義できる値のカラム数が最大10個
        // (実際にはrel6までしか定義してないが.)
        const directions = []
        for (let i = 0; i < 10; i++) {
          const dirKey = e[`rel${i + 1}`]
          const dirObj = this.hwDirectionMap[dirKey]
          if (!dirObj) { continue }
          directions.push(dirObj)
        }
        map[mapKey] = directions
      })
      this.hwNameDirectionRelMap = map

      map = {}
      lovs.highway_place2_text.vals_inuse.forEach(e => {
        // 形状、路線名、方向ごとにまとめる
        const directions = [e.dir1, e.dir2]
        directions.forEach(direction => {
          if (!direction) { return }
          const mapKey = `${e.place2_type}@${e.hw}@${direction}`
          if (!map[mapKey]) { map[mapKey] = [] }
          map[mapKey].push(e)
        })
      })
      this.hwPlace2TextMap = map
    })
  },
  mixins: [masterMixin],
  methods: {
    isOnHighway(hwObj) {
      return hwObj.on_off_type === this.onHighwayLiteral
    },
    isOffHighway(hwObj) {
      return !this.isOnHighway(hwObj)
    },
    hwPropPrefix(num) {
      return `${this.type}_highway${num}`
    },
    setHighwayOnOffTypes() {
      const lovs = window.master.lovs
      if (!lovs) { return }
      this.hwOnOffTypes = lovs.highway_on_off_type.vals_inuse
        .filter(e => {
          if (this.highwayOnOffOnlyOn) {
            return e.key === this.onHighwayLiteral
          }
          if (this.highwayOnOffOnlyOff) {
            return e.key === this.offHighwayLiteral
          }
          return true
        })
        .map(e => {
          if (this.type === 'regulate') {
            if (e.key === this.offHighwayLiteral) {
              // 保安規制の帳票では「高速外」ではなく「街路上」にしたいらしい
              e.name = '街路上'
            }
          }
          return e
        })
    },
    onReport() {
      const hwObjs = []
      const arr = [this.num]
      arr.forEach(num => {
        if (!this.type) { return }
        const pfx = this.hwPropPrefix(num)
        let onOffType = this.report[`${pfx}_on_off_type`]
        if (!onOffType) {
          onOffType = this.highwayOnOffOnlyOff ? this.offHighwayLiteral : this.onHighwayLiteral
        }
        const hwName = this.report[`${pfx}_name`]
        const hwDirection = this.report[`${pfx}_direction`]
        const hwInfo = Object.assign({}, this.report[`${pfx}_info`] || {})
        const obj = {
          num: num,
          pfx: pfx,
          on_off_type: onOffType,
          name: hwName,
          direction: hwDirection,
          info: hwInfo,
          hwDirections: [],
          hwPlace2Texts: [],
        }
        this.onRoadNameChange(obj)
        hwObjs.push(obj)
      })
      this.hwObjs = hwObjs
    },
    onInput(hwObj, propStr, val) {
      if (val instanceof Event) {
        val = val.target.value
      }
      const props = propStr.split('.')
      let obj = hwObj
      while (props.length > 1) {
        const prop = props.shift()
        obj = obj[prop]
      }
      obj[props.shift()] = val
      this.$emit('change', hwObj)
    },
    onOnOffTypeChange(hwObj, evt) {
      const onOffType = evt.target.value
      hwObj.on_off_type = onOffType
      if (this.isOnHighway(hwObj)) {
        hwObj.info.street_name = null
      } else {
        hwObj.info.lane = null
        hwObj.info.lr = null
        hwObj.info.place2_type = null
        hwObj.info.place2_text = null
      }
      this.$emit('change', hwObj)
    },
    onRoadNameChange(hwObj, evt) {
      if (evt) {
        hwObj.name = evt.target.value
      }
      // 絞込の更新と値の更新
      this.updateDirections(hwObj)
      this.updatePlace2Texts(hwObj)
      this.$emit('change', hwObj)
    },
    onDirectionChange(hwObj, evt) {
      if (evt) {
        hwObj.direction = evt.target.value
      }
      // 絞込の更新と値の更新
      this.updatePlace2Texts(hwObj)
      this.$emit('change', hwObj)
    },
    onPlace2TypeChange(hwObj, evt) {
      if (evt) {
        hwObj.info.place2_type = evt.target.value
      }
      // 絞込の更新と値の更新
      this.updatePlace2Texts(hwObj)
      // 料金所とPAのときだけ現れる部分は、そうでなくなったら消す
      if (!this.placeType2TypeMapForShowPlace2TextOther[hwObj.info.place2_type]) {
        hwObj.info.place2_text_other1 = ''
        hwObj.info.place2_text_other2 = ''
      }
      this.$emit('change', hwObj)
    },
    updateDirections(hwObj) {
      const mapKey = hwObj.name
      const directions = this.hwNameDirectionRelMap[mapKey] || []
      const direction = directions.find(e => e.key === hwObj.direction) ? hwObj.direction : ''

      hwObj.hwDirections = directions
      hwObj.direction = direction
    },
    updatePlace2Texts(hwObj) {
      const mapKey = `${hwObj.info.place2_type}@${hwObj.name}@${hwObj.direction}`
      const place2Texts = this.hwPlace2TextMap[mapKey] || []
      const place2Text = place2Texts.find(e => e.key === hwObj.info.place2_text) ? hwObj.info.place2_text : ''

      hwObj.hwPlace2Texts = place2Texts
      hwObj.info.place2_text = place2Text
    },
    filteredHighwayNames(hwObj) {
      if (this.showAllHighwayNames[hwObj.num]) { return this.hwNames }

      const reporteeDep = hwObj.num === 1 ? this.reporteeDep1ForFilter : this.reporteeDep2ForFilter
      if (!reporteeDep) { return this.hwNames }

      if (reporteeDep.reportee_group === 'K') {
        // 神奈川の場合はエリアで絞り込める
        return this.hwNames.filter(e => {
          return e.reportee_group === reporteeDep.reportee_group
        })
      } else {
        // 多少残念だが、東京の場合は報告先略称を当てれば絞りこめる
        return this.hwNames.filter(e => {
          return e.reportee_dep === reporteeDep.name_short1
        })
      }
    },
    copyHighwayInfo1(idx) {
      // 報告書欄の場所を指示欄の場所にコピーする時に呼ぶやつ
      const hwObj = this.hwObjs[idx]
      if (!hwObj) { return }
      const pfx = 'inspect_highway1'
      hwObj.name = this.report[`${pfx}_name`]
      this.onRoadNameChange(hwObj)
      // 微妙過ぎるが、普通に呼び出すとなぜか方向がちゃんと表示されない
      this.$nextTick(() => {
        hwObj.direction = this.report[`${pfx}_direction`]
        this.onDirectionChange(hwObj)
        this.$nextTick(() => {
          hwObj.info = { ...this.report[`${pfx}_info`] }
          this.onPlace2TypeChange(hwObj)
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .lbl {
    font-size: 16px;
    display: inline-block;
    &.wd64 {
      width: 120px;
    }
  }
  .radio-inline {
    font-size: 16px;
    margin-right: 6px;
    padding-left: 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .form-control {
    font-size: 16px;
  }
  .hw-name {
    width: 236px;
  }
  .hw-direction {
    width: 100px;
  }
  .hw-lane {
    width: 200px;
  }
  .hw-lr {
    width: 100px;
  }
  .hw-place1-type {
    width: 130px;
  }
  .hw-place1-text {
    width: 130px;
  }
  .hw-place2-type {
    width: 100px;
  }
  .hw-place2-text {
    width: 156px;
  }
  .hw-place2-text-other1 {
    width: 110px;
  }
  .hw-place2-text-other2 {
    width: 90px;
  }
  .road-regulation-kp {
    width: 70px;
  }
  .road-regulation-type {
    width: 80px;
  }
</style>
