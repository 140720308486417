<template src="./template.html"></template>

<script>
import Vue from 'vue'
import PRT from '@/consts/imprint'
import emergencyContactReportApi from '@/api/emergencyContactReport'
import masterMixin from '@/mixin/masterMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'printEmergencyContact',
  props: ['id'],
  data() {
    return {
      reporteeGroups: [],
      reporteeGroupMap: {},
      reporteeDeps: [],
      reporteeDepMap: {},
      ranks: [],
      ranksMap: [],
      ranksTexts: {},
      ranksTextMap: {},
      targetFacilitys: [],
      facilityStates: [],
      vehicles: [],
      reports: [],
      isImprint: false,
      managerInspectorCivilImprint: null,
      managerInspectorFacilityImprint: null,

      isUpdating: false,
    }
  },
  computed: {
    reporteeDepDisp1() {
      return (this.reporteeDepMap[this.reports[0].reportee_dep1] || {}).name
    },
    reportNumPrefixDisp() {
      // 他でもこんな事するのであれば共通化するけど今はしない
      if (!this.reports[0].damage_rank) { return }

      const selectedRankPrf = this.reports[0].damage_rank.substring(0, 1)
      const reporteeDepInfo = this.reporteeDepMap[this.reports[0].reportee_dep1]
      if (!reporteeDepInfo) { return }
      const reporteeGrpInfo = this.reporteeGroupMap[reporteeDepInfo.reportee_group]
      if (!reporteeGrpInfo) { return }

      return selectedRankPrf === 'A' || selectedRankPrf === 'B' ? reporteeGrpInfo.disp1 : reporteeGrpInfo.disp2
    },
    vehiclesDisp() {
      // 他でもこんな事するのであれば共通化するけど今はしない
      const vehiclesArray = this.reports[0].vehicles_used.split(',')
      let vehicleNames = ''
      vehiclesArray.forEach(v => {
        if (v === '') { return }
        const vehiclesInfo = this.vehicles.find(e => e.key === v)
        vehicleNames = vehicleNames + vehiclesInfo.name + '、'
      })

      vehicleNames = vehicleNames.substring(0, vehicleNames.lastIndexOf('、'))
      return vehicleNames
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.reporteeGroups = lovs.reportee_group.vals_inuse
      this.reporteeGroupMap = lovs.reportee_group.map
      this.reporteeDeps = lovs.reportee_dep.vals_inuse
      this.reporteeDepMap = lovs.reportee_dep.map
      this.targetFacilitys = lovs.target_facility.vals_inuse
      this.facilityStates = lovs.facility_state.vals_inuse
      this.vehicles = lovs.vehicles_used.vals_inuse

      this.ranks = lovs.damage_rank.vals_inuse
      this.rankTexts = lovs.damage_rank_text.vals_inuse

      this.rankMap = this.ranks.map((e) => ({ key: e.key, val: e.name }))

      const map = {}
      let texts = []
      this.rankMap.forEach(e => {
        const mapKey = e.key
        texts = this.rankTexts.filter(x => x.damage_rank === mapKey)
        map[mapKey] = texts
      })
      this.ranksTexts = map
    })
    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_EMERGENCYCONTACT
  },
  mixins: [masterMixin, imprintPhotoMixin, errorHandleMixin],
  methods: {
    getReport_() {
      emergencyContactReportApi.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)

          const rh = data.report_header
          data.manager_inspector_civil = rh.manager_inspector_civil
          data.manager_inspector_facility = rh.manager_inspector_facility

          const promises = [
            this.getImprintPhoto('manager_inspector_civil', data.manager_inspector_civil, rh.dt),
            this.getImprintPhoto('manager_inspector_facility', data.manager_inspector_facility, rh.dt),
          ]
          Promise.all(promises).then((results) => {
            this.managerInspectorCivilImprint = results[0]
            this.managerInspectorFacilityImprint = results[1]
          }).catch((error) => {
            this.handleErrorResponse(error)
          })

          data.vehicles_used = data.vehicles_used || ''

          // 途中からいれたので、古い形式のデータを救済
          data.requestor_name =
            data.requestor_name.replace(/###/g, '　')

          // 途中からいれたので、古い形式のデータを救済
          const repairCompanies = []
          const repairCompanyGroup = data.repair_company_group || ''
          const repairCompanyGroups = repairCompanyGroup.split(',')
          const repairCompanyName = data.repair_company_name || ''
          const repairCompanyNames = repairCompanyName.split(',')
          const repairCompanyGroupDisp = Vue.filter('repairCompanyGroupDisp')
          Array.from({ length: 2 }).forEach((_, idx) => {
            const repairCompanyGroup = repairCompanyGroups[idx] || ''
            const repairCompanyName = repairCompanyNames[idx] || ''
            if (!repairCompanyGroup && !repairCompanyName) { return }

            const repairCompanyNameParts = repairCompanyName.split('###')
            const [part1, part2] = repairCompanyNameParts
            const repairCompanyNamePart1 = part1 || ''
            const repairCompanyNamePart2 = part2 || ''
            repairCompanies.push([
              repairCompanyGroupDisp(repairCompanyGroup),
              repairCompanyNamePart1,
              repairCompanyNamePart2,
            ].filter(e => !!e).join('　'))
          })
          data.repairCompaniesDisp = repairCompanies.join('、')

          data.other_props = data.other_props || {}

          // 表示行調整用
          const freeInputs = ['result']
          freeInputs.forEach(propPrefix => {
            const prop = `${propPrefix}_free_input_chousei`
            if (data.other_props[prop] === undefined || data.other_props[prop] === null) {
              data.other_props[prop] = 0
            }
          })

          this.reports = [data]
        })
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    async updateFreeInputChousei(report, propPrefix, val) {
      if (this.isUpdating) { return }

      this.isUpdating = true
      try {
        const otherProps = JSON.parse(JSON.stringify(report.other_props))
        const prop = `${propPrefix}_free_input_chousei`
        otherProps[prop] += val
        const obj = {
          id: report.id,
          data: { other_props: otherProps },
        }
        await emergencyContactReportApi.update(obj)
        await this.getReport_()
        this.isUpdating = false
      } catch (err) {
        await this.getReport_()
        this.isUpdating = false
      }
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
