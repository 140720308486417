<template src="./template.html"></template>

<script>
import Vue from 'vue'
import PRT from '@/consts/imprint'
import workResultTachographApi from '@/api/workResultTachograph'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'printWorkResultTachograph',
  props: ['id', 'crewNumber'],
  data() {
    return {
      reports: [],
      report_header: {},

      isImprint: false,
      managerInspectorCivilImprint: null,
      managerInspectorFacilityImprint: null,
      inspectorImprint1: null,
      driverImprint1: null,

      articleNumDisps: ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'],
    }
  },
  computed: {
    dt1Disp() {
      return this.reports[0].report_header.dt
    },
    dt2Disp() {
      return this.getNightShiftDate(
        this.reports[0].report_header.timeslot,
        this.reports[0].report_header.dt
      )
    },
    hasInputTeam2() {
      return !!this.reports[0].report_header.inspector2
    },
    inspectorDisp() {
      return this.reports[0].report_header[`inspector${this.crewNumber}`]
    },
    driverDisp() {
      return this.reports[0].report_header[`driver${this.crewNumber}`]
    },
    workStartDisp() {
      return this.reports[0].report_header[`work_start${this.crewNumber}`]
    },
    workEndDisp() {
      return this.reports[0].report_header[`work_end${this.crewNumber}`]
    },
  },
  mounted() {
    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_WORKRESULTTACHOGRAPH
  },
  mixins: [redDetailPageMixin, imprintPhotoMixin, errorHandleMixin],
  methods: {
    convReport_(data) {
      data[0].other_props = data[0].other_props || {}

      this.getNightShiftDate(data[0], 'dt2',
        data[0].report_header.timeslot,
        data[0].report_header.dt)

      // 記事
      // 1行目(高速番号)と2行目以降に分割する
      const splitByLineBreak = Vue.filter('splitByLineBreak')
      const articlePropParts = ['article1', 'article2']
      articlePropParts.forEach(propPart => {
        const prop = `${propPart}_free_input`
        const rows = splitByLineBreak(data[0][prop])
        // 1行目(高速番号)
        data[0][`${prop}1`] = rows[0]
        // 2行目以降
        data[0][`${prop}2`] = rows.slice(1).map((e, idx) => {
          return this.articleNumDisps[idx] + e
        }).join('\n')
      })

      const promises = []
      const rh = data[0].report_header
      promises.push(this.getImprintPhoto('manager_inspector_civil', rh.manager_inspector_civil, rh.dt))
      promises.push(this.getImprintPhoto('manager_inspector_facility', rh.manager_inspector_facility, rh.dt))
      promises.push(this.getImprintPhoto('inspector', rh[`inspector${this.crewNumber}`], rh.dt))
      promises.push(this.getImprintPhoto('driver', rh[`driver${this.crewNumber}`], rh.dt))
      Promise.all(promises).then((results) => {
        this.managerInspectorCivilImprint = results[0]
        this.managerInspectorFacilityImprint = results[1]
        this.inspectorImprint1 = results[2]
        this.driverImprint1 = results[3]
      }).catch((error) => {
        this.handleErrorResponse(error)
      })

      return data
    },
    getReport_() {
      workResultTachographApi.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.reports = this.convReport_([data])
        })
    },
    getFreeInputMetaParams() {
      return [
        ['inspection_route1', 4],
        ['inspection_time1', 2],
        ['mileage1', 2],
        ['article1', 3],
        ['inspection_route2', 4],
        ['inspection_time2', 2],
        ['mileage2', 2],
        ['article2', 3],
      ]
    },
    isCrew1() {
      return this.crewNumber === '1'
    },
    isCrew2() {
      return !this.isCrew1()
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
