import mediaApi from '@/api/media'

export default {
  methods: {
    getBlobUrl(url) {
      return mediaApi.getBlobData(url)
        .then(res => {
          return this.toBlobUrl(res)
        }).catch(() => {
          return {
            url: url,
          }
        })
    },
    toBlobUrl(blob) {
      let filename = ''
      const disposition = blob.headers['content-disposition']
      if (disposition && disposition.includes('filename')) {
        //  ここを見てやった。https://tools.ietf.org/html/rfc6266
        //  以下のような文字列を想定している。
        //  Attachment; filename=example.html
        //  INLINE; FILENAME= "an example.html"
        //  attachment; filename*= UTF-8'en'%e2%82%ac%20rates
        //  attachment; filename="EURO rates"; filename*=utf-8''%e2%82%ac%20rates
        const filenameRegex = /filename\*?=\s*(utf-8'.*')?("?)([^;\n]+)\2$/i
        filename = filenameRegex.exec(disposition)[3]
        if (filename) {
          filename = decodeURIComponent(filename)
        }
      }
      return {
        url: URL.createObjectURL(blob.data),
        filename: filename,
      }
    },
  }
}
