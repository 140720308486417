import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/renumbering_reports'), {
      params: obj || {}
    })
  },
  update({ data }) {
    return axios.post(utils.getApiUrl('/renumbering_reports'), data)
  },
}
