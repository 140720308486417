<template src="./template.html"></template>

<script>
import Vue from 'vue'
import complaintHandlingReportApi from '@/api/complaintHandlingReport'
import EVT from '@/consts/eventNames'
import Stream from '@/lib/Stream'
import masterMixin from '@/mixin/masterMixin'
import detailPageMixin from '@/mixin/detailPageMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'complaintHandlingDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const detailPageFlagSet = this.getDetailPageFlagSet()
    const ret = {
      // streams
      stream: new Stream(), // mine
      reportHeaderStream: new Stream(),

      report: { other_props: {} },
      reporteeDeps: [],
      reporteeDepMap: {},
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      claimTypes: [],
      transferredFroms: [],
      managerInspectorCivils: [],
      managerInspectorFacilities: [],

      reportApi: complaintHandlingReportApi,

      errors: {},
    }
    return Object.assign({}, detailPageFlagSet, ret)
  },
  computed: {
    reporteeDepDisp1() {
      return (this.reporteeDepMap[this.report.reportee_dep1] || {}).name
    },
    reporteeDep1ForFilter() {
      return this.reporteeDepMap[this.report.reportee_dep1]
    },
    isReporteeDepDisp1Civil() {
      const reporteeDepInfo = this.reporteeDepMap[this.report.reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'civil'
    },
    isReporteeDepDisp1Facility() {
      const reporteeDepInfo = this.reporteeDepMap[this.report.reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'facility'
    },
    showInspector() {
      // 出動有りの場合は点検調査員を表示
      return this.report.service_yes_no === '有'
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  mounted() {
    this.getMasters()
    this.setListeners()

    if (this.$route.name === 'complaintHandlingNew') {
      this.setNewDetailPageMode(true)
      this.prepareNewReport_()
    } else if (this.$route.name === 'complaintHandlingCopy') {
      this.setNewDetailPageMode(true)
      this.setCopyMode(true)
      this.getCopiedNewReport_()
    } else {
      this.setNewDetailPageMode(false)
      this.getReport_()
    }

    this.connectStreams()
  },
  mixins: [masterMixin, detailPageMixin, errorHandleMixin, validatorMixin, dateMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.reporteeDeps = lovs.reportee_dep.vals_inuse
        this.reporteeDepMap = lovs.reportee_dep.map_inuse
        this.claimTypes = lovs.claim_type.vals_inuse
        this.transferredFroms = lovs.claim_tensoumoto.vals_inuse
        this.managerInspectorCivils = lovs.manager_inspector_civil.vals_inuse
        this.managerInspectorFacilities = lovs.manager_inspector_facility.vals_inuse
      })
    },
    connectStreams() {
      const reportHeader = this.reportHeaderStream
      const me = this.stream
      reportHeader.chain(EVT.RH.SHOW_REPORT_BODY).to(me).end()
      reportHeader.chain(EVT.RH.UPDATE_COMPLETE).to(me).end()
    },
    setListeners() {
      this.stream.recv(EVT.RH.SHOW_REPORT_BODY, () => {
        this.detailPageOnShowReportBody()
      })
      this.stream.recv(EVT.RH.UPDATE_COMPLETE, obj => {
        this.detailPageOnReportHeaderUpdateComplete(obj)
      })
    },
    prepareNewReport_() {
      const obj = {}

      const now = new Date()

      obj.report_num1 = ''
      obj.report_header_id = -1
      obj.report_header = {}
      this.setDateProp(obj, 'report_dt', now)
      obj.reportee_dep1 = ''
      obj.manager_inspector_civil = ''
      obj.manager_inspector_facility = ''
      obj.crew_number = 1
      obj.inspector = ''

      obj.received_by = ''
      this.setDateTimeProp(obj, 'receive_dt_from', now)
      this.setDateTimeProp(obj, 'receive_dt_to', now)
      obj.claimer_address = ''
      obj.claimer_company_name = ''
      obj.claimer_name = ''
      obj.claimer_phone_number = ''

      obj.claim_type = ''
      obj.service_yes_no = ''
      obj.transferred_from = ''

      obj.claim_dt = null

      obj.claim_highway1_on_off_type = 'on'
      obj.claim_highway1_name = ''
      obj.claim_highway1_direction = ''
      obj.claim_highway1_info = {}

      const otherProps = {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows]) => {
        otherProps[`${propPart}_free_input1_rows`] = defaultDispRows
      })
      obj.other_props = otherProps

      this.report = obj
    },
    convReport_(data) {
      this.setDateProp(data, 'report_dt', data.report_dt)

      data.manager_inspector_civil =
        data.report_header.manager_inspector_civil
      data.manager_inspector_facility =
        data.report_header.manager_inspector_facility
      data.inspector =
        data.report_header[`inspector${data.crew_number}`]

      this.setDateTimeProp(data, 'request_dt', data.request_dt)
      this.setDateTimeProp(data, 'report_dt', data.report_dt)
      this.setDateTimeProp(data, 'claim_dt', data.claim_dt)
      this.setDateTimeProp(data, 'receive_dt_from', data.receive_dt_from)
      this.setDateTimeProp(data, 'receive_dt_to', data.receive_dt_to)

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      data.other_props = data.other_props || {}
      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows]) => {
        const prop = `${propPart}_free_input1`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        const rowCountProp = `${prop}_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)
      })

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })
    },
    getCopiedNewReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.id = -1
          data.report_header_id = -1
          data.report_header = {}

          data.claim_dt = null

          const now = new Date()
          const dateProps = [
            'report_dt',
            'receive_dt_from',
            'receive_dt_to',
          ]
          dateProps.forEach(prop => {
            data[prop] = now
          })

          this.report = this.convReport_(data)
        })
    },
    onHighwayInputChange(hwObj) {
      const pfx = hwObj.pfx
      const origHwOnOffType = this.report[`${pfx}_on_off_type`]
      this.report[`${pfx}_on_off_type`] = hwObj.on_off_type
      this.report[`${pfx}_name`] = hwObj.name
      this.report[`${pfx}_direction`] = hwObj.direction
      this.report[`${pfx}_info`] = hwObj.info
      if (origHwOnOffType !== this.report[`${pfx}_on_off_type`]) {
        this.onHighwayOnOffTypeChange(hwObj)
      }
    },
    onHighwayOnOffTypeChange(hwObj) {
      // nothing to do
    },
    checkInputs({ isNew }) {
      this.errors = {}
      let prop

      prop = 'reportee_dep1'
      this.checkRequired(this.report[prop], prop, '報告先', this.errors)

      prop = 'report_dt'
      this.checkRequired(this.report[prop], prop, '報告日', this.errors)

      prop = 'receive_dt_from'
      this.checkRequired(this.report[prop], prop, '受理日時(開始)', this.errors)

      prop = 'receive_dt_to'
      this.checkRequired(this.report[prop], prop, '受理日時(終了)', this.errors)

      return Object.keys(this.errors).length === 0
    },
    async createReport() {
      if (!this.checkInputs({isNew: true})) {
        return false
      }
      // 報告日を入れる
      this.report.report_dt = this.getNearestReportDt(this.report.receive_dt_from)

      // conv date
      this.report.report_dt = Vue.filter('dtFormat')(
        this.report.report_dt, 'yyyy-mm-dd')

      const obj = { data: this.report }
      try {
        const { data } = await this.reportApi.create(obj)
        this.report = this.convReport_(data)
        this.showCreateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    async saveReport() {
      if (!this.checkInputs({isNew: false})) {
        return false
      }

      // conv date
      this.report.report_dt = Vue.filter('dtFormat')(
        this.report.report_dt, 'yyyy-mm-dd')

      // conv free inputs
      const freeInputs = ['claim_content', 'inspect_content', 'result']
      freeInputs.forEach(propPart => {
        const prop = `${propPart}_free_input1`
        this.report[prop] = (this.report[prop] || '')
      })

      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    closeCreateCompleteModal() {
      this.$router.push({
        name: 'complaintHandlingList',
      })
    },
    getFreeInputMetaParams() {
      return [
        ['claim_content', 6],
        ['inspect_content', 6],
        ['result', 6],
      ]
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
