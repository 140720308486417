function getLovDisp(lovName, key, prop) {
  const lovs = window.master.lovs
  if (!lovs || !lovs[lovName] || !key || !lovs[lovName].map[key]) { return '' }
  return lovs[lovName].map[key][prop]
}

export const reportNumDisp = (reportNum) => {
  if (!reportNum) { return '' }
  const parts = reportNum.split('-')

  let ret = ''
  if (parts.length === 4) {
    // 緊急点検、苦情処理、保安規制
    ret = parts[3]
  } else if (parts.length === 5) {
    // 緊急連絡
    ret = parts[4]
  }
  return parseInt(ret)
}

export const timeslotDisp = (key, dispProp = 'name') => {
  return getLovDisp('timeslot', key, dispProp)
}
export const recoveryActionDisp = (key, dispProp = 'name') => {
  return getLovDisp('recovery_action', key, dispProp)
}
export const requestorDisp = (key, dispProp = 'name') => {
  return getLovDisp('requestor', key, dispProp)
}
export const requestorFacilityDisp = (key, dispProp = 'name') => {
  return getLovDisp('requestor_facility', key, dispProp)
}
export const repairCompanyDisp = (key, dispProp = 'name') => {
  return getLovDisp('repair_company', key, dispProp)
}
export const repairCompanyGroupDisp = (key, dispProp = 'name') => {
  return getLovDisp('repair_company_group', key, dispProp)
}
export const repairCompanyServiceTypeDisp = (key, dispProp = 'name') => {
  return getLovDisp('repair_company_service_type', key, dispProp)
}
// 施設のダイジェストで使う用
export const repairCompanyServiceTypeDisp2 = (key, dispProp = 'name') => {
  const tmp1 = getLovDisp('repair_company_service_type', key, dispProp)
  // xxxxxxxx（当番） => (当番)　にしたい
  let tmp2 = tmp1.match(/^[^()]+\(([^()]+)\)$/)
  if (tmp2) {
    return `(${tmp2[1]})`
  }
  tmp2 = tmp1.match(/^[^（）]+（([^（）]+)）$/)
  if (tmp2) {
    return `（${tmp2[1]}）`
  }
  return tmp1
}
export const personOfCauseDisp = (key, dispProp = 'name') => {
  return getLovDisp('person_of_cause', key, dispProp)
}
export const regulationTypeDisp = (key, dispProp = 'name') => {
  return getLovDisp('regulation_type', key, dispProp)
}
export const digestResultDisp = (key, dispProp = 'name') => {
  return getLovDisp('digest_result', key, dispProp)
}
export const reporteeDisp = (key, dispProp = 'name') => {
  return getLovDisp('reportee_dep', key, dispProp)
}

export const workTimeDisp = (num, fmt) => {
  if (isNaN(num)) { return '' }
  const h = parseInt(num / 100)
  const m = num % 100

  return fmt
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h)
    .replace(/MM/g, ('0' + m).slice(-2))
    .replace(/M/g, m)
}
