<template src="./template.html"></template>

<script>
export default {
  name: 'printComplaintHandlingPhotos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
