<template>
<highway-input1
  v-bind="$props"
  type="regulate"
  @change="onChange"
></highway-input1>
</template>

<script>
export default {
  name: 'highway-input2',
  props: {
    report: {
      type: Object,
      required: true,
    },
    reporteeDep1ForFilter: {
      type: Object,
    },
  },
  methods: {
    onChange($event) {
      this.$emit('change', $event)
    }
  }
}
</script>
