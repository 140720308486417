<template src="./template.html"></template>

<script>
import Vue from 'vue'
import PRT from '@/consts/imprint'
import complaintHandlingReportApi from '@/api/complaintHandlingReport'
import masterMixin from '@/mixin/masterMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'printComplaintHandling',
  props: ['id'],
  data() {
    return {
      reporteeDepMap: {},
      reporteeDeps: [],
      received_by: [],
      receiveTerm: 0,
      reports: [],
      isImprint: false,
      managerInspectorCivilImprint: null,
      managerInspectorFacilityImprint: null,
      inspectorImprint: null,

      isUpdating: false,
    }
  },
  computed: {
    receiveTermDisp1() {
      const fromDt = new Date(this.reports[0].receive_dt_from)
      const toDt = new Date(this.reports[0].receive_dt_to)
      const min = toDt.getMinutes() - fromDt.getMinutes()
      const hour = toDt.getHours() - fromDt.getHours()
      let ret = 0

      if (hour > 0) {
        ret = hour * 60
      }

      ret = ret + min
      return ret
    },
    isReporteeDepDisp1Civil() {
      const reporteeDepInfo = this.reporteeDepMap[this.reports[0].reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'civil'
    },
    isReporteeDepDisp1Facility() {
      const reporteeDepInfo = this.reporteeDepMap[this.reports[0].reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'facility'
    },
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
      this.reporteeDeps = lovs.reportee_dep.vals_inuse
      this.received_by = lovs.requestor.vals_inuse
    })
    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_COMPLAINTHANDLING
  },
  mixins: [masterMixin, imprintPhotoMixin, errorHandleMixin],
  methods: {
    getReport_() {
      complaintHandlingReportApi.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          const inspectContentDisp = Vue.filter('inspectContentDisp')
          data.inspectContentDisp = inspectContentDisp(data)
          data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)
          data.reporteeDepDisp2 = this.reporteeDepDisp(data.reportee_dep2)

          const rh = data.report_header
          data.manager_inspector_civil = rh.manager_inspector_civil
          data.manager_inspector_facility = rh.manager_inspector_facility
          data.inspector = rh[`inspector${data.crew_number}`]
          // 出動有りの場合は点検調査員を表示
          data.showInspector = data.service_yes_no === '有'

          const promises = [
            this.getImprintPhoto('manager_inspector_civil', data.manager_inspector_civil, rh.dt),
            this.getImprintPhoto('manager_inspector_facility', data.manager_inspector_facility, rh.dt),
            this.getImprintPhoto('inspector', data.inspector, rh.dt),
          ]
          Promise.all(promises).then((results) => {
            this.managerInspectorCivilImprint = results[0]
            this.managerInspectorFacilityImprint = results[1]
            this.inspectorImprint = results[2]
          }).catch((error) => {
            this.handleErrorResponse(error)
          })

          data.other_props = data.other_props || {}

          // 表示行調整用
          const freeInputs = ['claim_content', 'inspect_content', 'result']
          freeInputs.forEach(propPrefix => {
            const prop = `${propPrefix}_free_input_chousei`
            if (data.other_props[prop] === undefined || data.other_props[prop] === null) {
              data.other_props[prop] = 0
            }
          })

          this.reports = [data]
        })
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    async updateFreeInputChousei(report, propPrefix, val) {
      if (this.isUpdating) { return }

      this.isUpdating = true
      try {
        const otherProps = JSON.parse(JSON.stringify(report.other_props))
        const prop = `${propPrefix}_free_input_chousei`
        otherProps[prop] += val
        const obj = {
          id: report.id,
          data: { other_props: otherProps },
        }
        await complaintHandlingReportApi.update(obj)
        await this.getReport_()
        this.isUpdating = false
      } catch (err) {
        await this.getReport_()
        this.isUpdating = false
      }
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
