<template>
<textarea v-model="internalValue" @keydown="onKeyDown"></textarea>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'limited-textarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    maxChars: {
      type: Number
    },
    maxLines: {
      type: Number
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    }
  },
  methods: {
    onKeyDown(evt) {
      if (this.maxChars && this.value.length >= this.maxChars) {
        evt.preventDefault()
        return
      }
      if (this.maxLines && evt.key === 'Enter') {
        const splitByLineBreak = Vue.filter('splitByLineBreak')
        if (splitByLineBreak(this.value).length >= this.maxLines) {
          evt.preventDefault()
        }
      }
    }
  }
}
</script>
