<template>
<div class="container">
  <div class="page page-top">
    <div class="ibox float-e-margins ibox1">
      <div class="ibox-content clearfix">
        <p class="dt">
          <span>{{ dt | dtFormatYMDA1 }}</span>
        </p>
      </div>
    </div>

    <div class="ibox float-e-margins ibox2">
      <div class="ibox-content clearfix">
        <div class="col-md-12">
          <div class="col-md-3">
            <router-link :to="{name:'emergencyInspectList'}" class="btn btn-lg btn-default">
              <img style="width:68px;height:68px;margin-left:-4px;" src="/static/img/top001.png" />
              <span class="btn-text">緊急点検</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'complaintHandlingList'}" class="btn btn-lg btn-default" :class="{disabled:!has_role_soukatsu}">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top002.png" />
              <span class="btn-text">苦情処理</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'roadRegulationList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top003.png" />
              <span class="btn-text">保安規制</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'emergencyContactList'}" class="btn btn-lg btn-default" :class="{disabled:!has_role_soukatsu}">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top004.png" />
              <span class="btn-text">緊急連絡</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox3">
      <div class="ibox-content clearfix">
        <div class="col-md-12 row1">
          <div class="col-md-3">
            <router-link :to="{name:'allReportsSummaryList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top005.png" />
              <span class="btn-text">業務日誌</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'workResultTableList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top006.png" />
              <span class="btn-text">工程表</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'workResultTachographList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top007.png" />
              <span class="btn-text">タコグラフ</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'emergencyContactSummaryList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top008.png" />
              <span class="btn-text">施設日誌</span><br>
            </router-link>
          </div>
        </div>
        <div class="col-md-12 row2">
          <div class="col-md-3">
            <router-link :to="{name:'securityChecklistList'}" class="btn btn-lg btn-default">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top009.png" />
              <span class="btn-text">安全管理日誌</span>
            </router-link>
          </div>
          <div class="col-md-3">
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'renumberingList'}" class="btn btn-lg btn-default color-other" v-if="has_role_soukatsu">
              <span class="btn-text">連番管理</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'masterList'}" class="btn btn-lg btn-default color-other" v-if="has_role_admin">
              <img style="width:50px;height:50px;margin-left:-8px;" src="/static/img/top010.png" />
              <span class="btn-text">マスタ</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'top',
  data() {
    return {
      dt: new Date()
    }
  },
  computed: {
    ...mapState('user', {
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_tenken: state => state.has_role_tenken,
    }),
  },
}
</script>

<style lang="scss" scoped>
  .ibox1 {
    margin-top: 24px;
  }

  .dt {
    font-size: 18px;
    margin: 0;
  }

  .btn.btn-lg {
    width: 190px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-text {

    }
  }
  @media only screen and (max-width: 991px) {
    .btn.btn-lg {
      width: 90%;
      margin: 8px auto;
    }
  }

  .ibox2 .btn {
    color: #1e43d8;
    border-color: #1e43d8;
  }
  .ibox3 .btn {
    color: #db3994;
    border-color: #db3994;

    &.color-other {
      color: #555555;
      border-color: #555555;
    }
  }

  .ibox3 .row1 {
    margin-bottom: 16px;
  }
</style>
