<template src="./template.html"></template>

<script>
import workResultTableApi from '@/api/workResultTable'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'workResultTableDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      isReady: false,

      report: {
        report_header: {},
      },
      reportApi: workResultTableApi,
      reporteeGroups: [],
      reporteeDeps: [],
      reporteeDepMap: {},

      showUpdateCompleteModal: false,

      hourIdxs: [...Array(25).keys()],
      inspectorTypeMetaArr: [
        { type1: 'mgr_inspector', type2: 'civil', name1: '総括調査員', name2: '土木' },
        { type1: 'mgr_inspector', type2: 'facility', name1: '総括調査員', name2: '施設' },
        { type1: 'inspector', type2: '1', name1: '点検調査員', name2: '１班' },
        { type1: 'inspector', type2: '2', name1: '点検調査員', name2: '２班' },
        { type1: 'driver', type2: '1', name1: '運転手', name2: '１班' },
        { type1: 'driver', type2: '2', name1: '運転手', name2: '２班' },
      ],
      workTypeMetaArr: [
        { type1: 'inside_work', type2: '1', name1: '内業', name2: '' },
        { type1: 'outside_work', type2: '1', name1: '外業', name2: '高速上' },
        { type1: 'outside_work', type2: '2', name1: '外業', name2: '高架下' },
      ],

      errors: {},
    }
  },
  computed: {
    dt1Disp() {
      return this.report.report_header.dt
    },
    dt2Disp() {
      return this.getNightShiftDate(
        this.report.report_header.timeslot,
        this.report.report_header.dt
      )
    },
    workStartDisp() {
      return this.report.report_header.work_start1
    },
    workEndDisp() {
      return this.report.report_header.work_end1
    },
  },
  async mounted() {
    await this.waitForMasters()
    this.prepareMasters()
    await this.getReport_()
    this.isReady = true
  },
  mixins: [masterMixin, redDetailPageMixin, errorHandleMixin, dateMixin],
  methods: {
    async prepareMasters() {
      const lovs = window.master.lovs
      this.reporteeGroups = lovs.reportee_group.vals_inuse
      this.reporteeDeps = lovs.reportee_dep.vals_inuse
      this.reporteeDepMap = lovs.reportee_dep.map
    },
    async getReport_() {
      const obj = { id: this.id }
      const { data } = await this.reportApi.show(obj)
      this.report = this.convReport_(data)
    },
    async saveReport() {
      const obj = {
        id: this.report.id,
        data: {
          bikou1: this.report.bikou1,
          updated_at: this.report.updated_at,
        }
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    convReport_(data) {
      data.emergency_inspect_reports_civil = this.getReporteeTypeDataArray(
        data.emergency_inspect_reports, 'civil')
      data.emergency_inspect_reports_facility = this.getReporteeTypeDataArray(
        data.emergency_inspect_reports, 'facility')

      data.complaint_handling_reports_civil = this.getReporteeTypeDataArray(
        data.complaint_handling_reports, 'civil')
      data.complaint_handling_reports_facility = this.getReporteeTypeDataArray(
        data.complaint_handling_reports, 'facility')

      data.road_regulation_reports_civil = this.getReporteeTypeDataArray(
        data.road_regulation_reports, 'civil')
      data.road_regulation_reports_facility = this.getReporteeTypeDataArray(
        data.road_regulation_reports, 'facility')

      const rh = data.report_header
      data.workResults = this.calcWorkResults(data, rh.timeslot, rh.dt)
      data.workHours = this.calcWorkHours(data, rh.timeslot, rh.dt)

      return data
    },
    getReporteeTypeDataArray(fromArray, reporteeType) {
      const toArray = []
      fromArray.forEach(fa => {
        const reporteeDepInfo = this.reporteeDepMap[fa.reportee_dep1]
        if (reporteeDepInfo.reportee_type === reporteeType) {
          toArray.push(fa)
        }
      })
      return toArray
    },
    getReportsByReportType(data, inspectorTypeMeta, isInsideWork = false) {
      let eir = []
      let chr = []
      let rrr = []

      const inspectorPropName = this.getInspectorPropName(inspectorTypeMeta)
      if (!data.report_header[inspectorPropName]) { return { eir, chr, rrr } }

      if (inspectorTypeMeta.type1 === 'mgr_inspector') {
        eir = data[`emergency_inspect_reports_${inspectorTypeMeta.type2}`]
        // 総括調査員の内業のみ
        chr = isInsideWork ? data[`complaint_handling_reports_${inspectorTypeMeta.type2}`] : []
        rrr = data[`road_regulation_reports_${inspectorTypeMeta.type2}`]
      } else {
        eir = data.emergency_inspect_reports
        chr = []
        rrr = data.road_regulation_reports
      }
      if (inspectorTypeMeta.type1 === 'inspector' || inspectorTypeMeta.type1 === 'driver') {
        // 点検調査員・運転手は班で絞り込み
        const crewNumber = parseInt(inspectorTypeMeta.type2)
        eir = eir.filter(e => e.crew_number === crewNumber)
        chr = chr.filter(e => e.crew_number === crewNumber)
        rrr = rrr.filter(e => e.crew_number === crewNumber)
      }
      return { eir, chr, rrr }
    },
    calcWorkResults(data, timeslot, dt) {
      const workResults = []
      this.inspectorTypeMetaArr.forEach((inspectorTypeMeta, inspectorTypeMetaIdx) => {
        workResults[inspectorTypeMetaIdx] = []
        this.workTypeMetaArr.forEach(workTypeMeta => {
          const isDriver = inspectorTypeMeta.type1 === 'driver'
          const isInsideWork = workTypeMeta.type1 === 'inside_work'
          // 運転手は内業なし
          if (isDriver && isInsideWork) { return }

          // 運転手の出動時間は点検調査員と同じ
          const reportsByReportType = this.getReportsByReportType(data, inspectorTypeMeta, isInsideWork)
          const inspectorType = isDriver ? 'inspector' : inspectorTypeMeta.type1
          const workType = `${workTypeMeta.type1}${workTypeMeta.type2}`
          const prop = `${inspectorType}_${workType}`
          const hitMapByReportType = this.getWorkResultHitMap(timeslot, dt, reportsByReportType, prop)
          const table = {}
          Object.keys(hitMapByReportType).forEach(key => {
            table[key] = this.hourIdxs.map(hourIdx => {
              return {
                hour: hourIdx,
                selected: hitMapByReportType[key][hourIdx] ||
                  {
                    m0: false,
                    m5: false,
                    m10: false,
                    m15: false,
                    m20: false,
                    m25: false,
                    m30: false,
                    m35: false,
                    m40: false,
                    m45: false,
                    m50: false,
                    m55: false,
                  },
              }
            })
          })
          workResults[inspectorTypeMetaIdx].push({
            inspectorTypeMeta: inspectorTypeMeta,
            inspectorPropName: this.getInspectorPropName(inspectorTypeMeta),
            workTypeMeta: workTypeMeta,
            table: table,
          })
        })
      })
      return workResults
    },
    getWorkResultHitMap(timeslot, dt, reportsByReportType, prop) {
      const hitMapByReportType = {}
      Object.keys(reportsByReportType).forEach(key => {
        hitMapByReportType[key] = {}
        reportsByReportType[key].forEach(report => {
          hitMapByReportType[key] = this._getHitMap(timeslot, dt, report, prop, hitMapByReportType[key])
        })
      })
      return hitMapByReportType
    },
    _getHitMap(timeslot, dt, report, prop, hitMap = {}) {
      const baseDateTs = new Date(dt).valueOf() + 9 * 3600 * 1000

      let fromOffset = 9999999
      let toOffset = 9999999

      let dtFrom = null
      let dtTo = null
      if (report.report_num1.startsWith('ch')) {
        dtFrom = new Date(report.receive_dt_from)
        dtTo = new Date(report.receive_dt_to)
      } else {
        dtFrom = this.timeIntegerToDate(report[`${prop}_from`], dt, timeslot)
        dtTo = this.timeIntegerToDate(report[`${prop}_to`], dt, timeslot)
      }

      if (dtFrom) {
        const msecDiff = dtFrom.valueOf() - baseDateTs
        fromOffset = msecDiff / 60 / 1000
      }
      if (dtTo) {
        const msecDiff = dtTo.valueOf() - baseDateTs
        toOffset = msecDiff / 60 / 1000
      }

      // 色をつけるidxを記録
      this.hourIdxs.forEach(hourIdx => {
        const hourOffsetMin0 = hourIdx * 60
        const hourOffsetMin5 = hourOffsetMin0 + 5
        const hourOffsetMin10 = hourOffsetMin5 + 5
        const hourOffsetMin15 = hourOffsetMin10 + 5
        const hourOffsetMin20 = hourOffsetMin15 + 5
        const hourOffsetMin25 = hourOffsetMin20 + 5
        const hourOffsetMin30 = hourOffsetMin25 + 5
        const hourOffsetMin35 = hourOffsetMin30 + 5
        const hourOffsetMin40 = hourOffsetMin35 + 5
        const hourOffsetMin45 = hourOffsetMin40 + 5
        const hourOffsetMin50 = hourOffsetMin45 + 5
        const hourOffsetMin55 = hourOffsetMin50 + 5

        const hitMin0 = fromOffset - 5 < hourOffsetMin0 &&
          hourOffsetMin0 < toOffset
        const hitMin5 = fromOffset - 5 < hourOffsetMin5 &&
          hourOffsetMin5 < toOffset
        const hitMin10 = fromOffset - 5 < hourOffsetMin10 &&
          hourOffsetMin10 < toOffset
        const hitMin15 = fromOffset - 5 < hourOffsetMin15 &&
          hourOffsetMin15 < toOffset
        const hitMin20 = fromOffset - 5 < hourOffsetMin20 &&
          hourOffsetMin20 < toOffset
        const hitMin25 = fromOffset - 5 < hourOffsetMin25 &&
          hourOffsetMin25 < toOffset
        const hitMin30 = fromOffset - 5 < hourOffsetMin30 &&
          hourOffsetMin30 < toOffset
        const hitMin35 = fromOffset - 5 < hourOffsetMin35 &&
          hourOffsetMin35 < toOffset
        const hitMin40 = fromOffset - 5 < hourOffsetMin40 &&
          hourOffsetMin40 < toOffset
        const hitMin45 = fromOffset - 5 < hourOffsetMin45 &&
          hourOffsetMin45 < toOffset
        const hitMin50 = fromOffset - 5 < hourOffsetMin50 &&
          hourOffsetMin50 < toOffset
        const hitMin55 = fromOffset - 5 < hourOffsetMin55 &&
          hourOffsetMin55 < toOffset

        if (!hitMap[hourIdx]) {
          hitMap[hourIdx] = {}
        }
        hitMap[hourIdx] = {
          m0: hitMap[hourIdx].m0 || hitMin0,
          m5: hitMap[hourIdx].m5 || hitMin5,
          m10: hitMap[hourIdx].m10 || hitMin10,
          m15: hitMap[hourIdx].m15 || hitMin15,
          m20: hitMap[hourIdx].m20 || hitMin20,
          m25: hitMap[hourIdx].m25 || hitMin25,
          m30: hitMap[hourIdx].m30 || hitMin30,
          m35: hitMap[hourIdx].m35 || hitMin35,
          m40: hitMap[hourIdx].m40 || hitMin40,
          m45: hitMap[hourIdx].m45 || hitMin45,
          m50: hitMap[hourIdx].m50 || hitMin50,
          m55: hitMap[hourIdx].m55 || hitMin55,
        }
      })
      return hitMap
    },
    calcWorkHours(data, timeslot, dt) {
      const workHours = []
      // 外業（高速上・高速外)の合計出動時間
      this.inspectorTypeMetaArr.forEach(inspectorTypeMeta => {
        const outsideWorkHourMap = {}
        this.workTypeMetaArr.filter(workTypeMeta => {
          return workTypeMeta.type1 === 'outside_work'
        }).forEach(workTypeMeta => {
          // 運転手の出動時間は点検調査員と同じ
          const reportsByReportType = this.getReportsByReportType(data, inspectorTypeMeta)
          const inspectorType = inspectorTypeMeta.type1 === 'driver' ? 'inspector' : inspectorTypeMeta.type1
          const workType = `${workTypeMeta.type1}${workTypeMeta.type2}`
          const prop = `${inspectorType}_${workType}`
          const { timeslot1, timeslot2 } = this.getWorkHourMap(timeslot, dt, reportsByReportType, prop)
          outsideWorkHourMap[workType] = {
            timeslot1: timeslot1 || 0,
            timeslot2: timeslot2 || 0,
          }
        })
        workHours.push({
          inspectorTypeMeta: inspectorTypeMeta,
          inspectorPropName: this.getInspectorPropName(inspectorTypeMeta),
          outsideWork1: outsideWorkHourMap.outside_work1,
          outsideWork2: outsideWorkHourMap.outside_work2,
        })
      })
      return workHours
    },
    getWorkHourMap(timeslot, dt, reportsByReportType, prop) {
      // 苦情処理は総括の内業扱いのため、出動時間には含まない
      const reportTypeArr = [
        'eir',
        'rrr',
      ]
      let workHoursInSeconds = 0
      reportTypeArr.forEach(reportType => {
        reportsByReportType[reportType].forEach(report => {
          workHoursInSeconds = this._getWorkHoursInSeconds(timeslot, dt, report, prop, workHoursInSeconds)
        })
      })
      const timeInt = this.secondsToTimeInteger(workHoursInSeconds)
      return { [`timeslot${timeslot}`]: timeInt }
    },
    _getWorkHoursInSeconds(timeslot, dt, report, prop, seconds = 0) {
      const dtFrom = this.timeIntegerToDate(report[`${prop}_from`], dt, timeslot)
      const dtTo = this.timeIntegerToDate(report[`${prop}_to`], dt, timeslot)
      if (!dtFrom || !dtTo) { return seconds }

      const msecDiff = dtTo.valueOf() - dtFrom.valueOf()
      if (msecDiff < 0) { return seconds }

      seconds += (msecDiff / 1000)
      return seconds
    },
    getInspectorPropName(inspectorTypeMeta) {
      const isManager = inspectorTypeMeta.type1.startsWith('mgr')
      const separator = isManager ? '_' : ''
      let propName = [inspectorTypeMeta.type1, inspectorTypeMeta.type2].join(separator)
      if (isManager) {
        propName = propName.replace('mgr', 'manager')
      }
      return propName
    },
    secondsToTimeInteger(seconds) {
      // 一旦正に戻してから計算
      const sign = seconds < 0 ? -1 : 1
      seconds *= sign

      let h = parseInt(seconds / 3600)
      h = Math.min(h, 99) // digit guard
      const tmp = seconds % 3600
      let m = parseInt(tmp / 60)

      h = ('0' + h).slice(-2)
      m = ('0' + m).slice(-2)
      return parseInt(`${h}${m}`) * sign
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
