const dayDispArr = [
  '日', '月', '火', '水', '木', '金', '土'
]

function dateStrToDate(str) {
  if (!str) { return null }
  if (str.match(/^\d{4}-\d{2}-\d{2}$/)) {
    str = `${str} 00:00:00`
  }
  if (str.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    str = str.replace(/-/g, '').replace(/:/g, '')
    return new Date(
      parseInt(str.substr(0, 4)),
      parseInt(str.substr(4, 2)) - 1,
      parseInt(str.substr(6, 2)),
      parseInt(str.substr(9, 2)),
      parseInt(str.substr(11, 2)),
      parseInt(str.substr(13, 2)),
    )
  }
  return new Date(str.replace(/ /, 'T'))
}

export const ensureDate = (dt) => {
  if (!dt) { return null }

  let ret = dt
  if (typeof dt === 'string') {
    ret = dateStrToDate(dt)
  }
  return ret
}

export const dtFormatYMDA1 = (dt) => {
  dt = ensureDate(dt)
  if (!dt || isNaN(dt.valueOf())) { return '' }

  const y = dt.getFullYear()
  const m = dt.getMonth() + 1
  const d = dt.getDate()
  const youbi = dayDispArr[dt.getDay()]

  return `${y}年${m}月${d}日(${youbi})`
}

export const dtFormatYMDA2 = (dt) => {
  dt = ensureDate(dt)
  if (!dt || isNaN(dt.valueOf())) { return '' }

  const y = dt.getFullYear()
  const m = dt.getMonth() + 1
  const d = dt.getDate()
  const youbi = dayDispArr[dt.getDay()]
  const hour = dt.getHours()
  const min = ('0' + dt.getMinutes()).slice(-2)

  return `${y}年${m}月${d}日(${youbi}) ${hour}:${min}`
}

export const dtFormat = (date, format) => {
  date = ensureDate(date)
  if (!date || isNaN(date.valueOf())) { return '' }
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()
  const h = date.getHours()
  const min = date.getMinutes()
  const s = date.getSeconds()
  const youbi = dayDispArr[date.getDay()]
  return format
    .replace(/yyyy/g, y)
    .replace(/mm/g, ('0' + m).slice(-2))
    .replace(/m/g, m)
    .replace(/dd/g, ('0' + d).slice(-2))
    .replace(/d/g, d)
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h)
    .replace(/MM/g, ('0' + min).slice(-2))
    .replace(/M/g, min)
    .replace(/SS/g, ('0' + s).slice(-2))
    .replace(/S/g, s)
    .replace(/a/g, youbi)
}
