<template>
<div class="photo-editor" ref="photoEditor">
  <div class="photo-edit">
    <div class="mb-4">
      <div class="ib mr-8">
        <button class="btn btn-default mr-2 undo" @click="undo"
            :disabled="!canUndo()">
          <i class="fa fa-undo"></i>
          戻す
        </button>
        <button class="btn btn-default redo" @click="redo"
            :disabled="!canRedo()">
          <i class="fa fa-repeat"></i>
          やり直す
        </button>
      </div>
      <div class="ib toolbox1">
        <button class="btn" :class="{ 'btn-primary': isDrawModeFree }"
            @click="setDrawMode(DRAW_MODE.FREE)">
          手書き
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeLine }"
            @click="setDrawMode(DRAW_MODE.LINE)">
          直線
        </button>
        <template v-if="isDrawModeDoubleArrow">
          <button class="btn" :class="{ 'btn-primary': isDrawModeDoubleArrow }"
              @click="setDrawMode(DRAW_MODE.DOUBLE_ARROW)">
            矢印2
            <i class="fa fa-arrows-h fa-lg"></i>
          </button>
        </template>
        <template v-else>
          <button class="btn" :class="{ 'btn-primary': isDrawModeArrow }"
              @click="setDrawMode(DRAW_MODE.ARROW)">
            矢印1
            <i class="fa fa-long-arrow-right fa-lg"></i>
          </button>
        </template>
        <button class="btn" :class="{ 'btn-primary': isDrawModeRect }"
            @click="setDrawMode(DRAW_MODE.RECT)">
          四角形
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModePolyline }"
            @click="setDrawMode(DRAW_MODE.POLYLINE)">
          多角形
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeEllipse }"
            @click="setDrawMode(DRAW_MODE.ELLIPSE)">
          円
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeText }"
          @click="setDrawMode(DRAW_MODE.TEXT)">
          文字
        </button>
        <hr>
        <button class="btn" :class="{ 'btn-primary': isColorCorrectionMode }"
          @click="onColorCorrectionClick">
          色調補正
        </button>
      </div>
      <div class="ib toolbox1">
        <button class="btn btn-danger"
          :disabled="!individualOptions"
          @click="removeSelectedObject">
          <i class="fa fa-trash"></i>
          削除
        </button>
      </div>
      <div class="memo text-warning">
        <span v-if="isDrawModeArrow || isDrawModeDoubleArrow">矢印の種類を切り替える場合は、矢印ボタンをもう一度押してください。</span>
        <span v-if="isDrawModePolyline">描画を終了する場合は、始点と終点が重なるようにクリックして図形を完成させてください。</span>
      </div>
    </div>
    <div class="main">
      <div class="canvas-wrapper">
        <fabric-canvas class="base-canvas"
            :photo-image-src="photoImage.src"
            :size-info="sizeInfo"
            :draw-mode="drawMode"
            :draw-options="drawOptions"
            :brightness="brightness"
            :contrast="contrast"
            @object-drew="onAfterObjectDrew"
            @object-selected="onObjectSelected"
            @object-deselected="onObjectDeselected"
            @brightness-changed="onBrightnessChanged"
            @contrast-changed="onContrastChanged"
            ref="canvas">
        </fabric-canvas>
      </div>
      <div class="toolbox2">
        <div class="toolbox-row" v-show="drawOptionsDisplay.strokeColor">
          <div class="toolbox-option">
            <span>色</span>
            <color-picker class="ib color-picker"
              :color="drawOptions.strokeColor"
              :allow-colorless="false"
              @color-change="setStrokeColor" />
          </div>
        </div>
        <div class="toolbox-row" v-show="drawOptionsDisplay.fillColor">
          <div class="toolbox-option">
            <span>塗りつぶし</span>
            <color-picker class="ib color-picker"
              :color="drawOptions.fillColor"
              @color-change="setFillColor" />
          </div>
        </div>
        <div class="toolbox-row" v-show="drawOptionsDisplay.lineType">
          <div class="toolbox-option">
            <span>実線/点線</span>
            <div class="ib">
              <button class="btn btn-block line-btn" @click="setLineType('dashed')"
                  v-if="drawOptions.lineType === 'solid'">
                <div style="border-top:1px solid #000;"></div>
                実線
              </button>
              <button class="btn line-btn" @click="setLineType('solid')"
                  v-if="drawOptions.lineType === 'dashed'">
                <div style="border-top:1px dashed #000;"></div>
                点線
              </button>
            </div>
          </div>
        </div>
        <div class="toolbox-row" v-show="drawOptionsDisplay.strokeWidth">
          <div class="toolbox-option">
            <span>太さ</span>
            <label>{{drawOptions.strokeWidth}}</label>
            <input type="range"
              class="range-bar"
              :value="drawOptions.strokeWidth"
              min="1"
              max="50"
              @change="setStrokeWidth">
          </div>
        </div>
        <div class="toolbox-row" v-show="drawOptionsDisplay.writingMode">
          <div class="toolbox-option">
            <span>横書き/縦書き</span>
            <div class="ib">
              <button class="btn btn-block line-btn" @click="setwritingMode('vertical')"
                  v-if="drawOptions.writingMode === 'horizontal'">
                横書き
              </button>
              <button class="btn line-btn" @click="setwritingMode('horizontal')"
                  v-if="drawOptions.writingMode === 'vertical'">
                縦書き
              </button>
            </div>
          </div>
        </div>
        <div class="toolbox-row" v-show="drawOptionsDisplay.writingMode">
          <div class="toolbox-option">
            <span>サイズ</span>
            <label>{{drawOptions.fontSize}}</label>
            <input type="range"
              class="range-bar"
              :value="drawOptions.fontSize"
              min="1"
              max="100"
              @change="setFontSize">
          </div>
        </div>
        <div class="toolbox-row" v-show="isColorCorrectionMode">
          <div class="toolbox-option">
            <span>明るさ</span>
            <input type="range"
              class="range-bar"
              step="0.05"
              min="-1"
              max="1"
              :value="brightness"
              @change="setBrightness">
          </div>
        </div>
        <div class="toolbox-row" v-show="isColorCorrectionMode">
          <div class="toolbox-option">
            <span>コントラスト</span>
            <input type="range"
              class="range-bar"
              step="0.05"
              min="-1"
              max="1"
              :value="contrast"
              @change="setContrast">
          </div>
        </div>
      </div>
    </div>
    <div class="photo-edit-bottom-bar ta-center">
      <button class="btn btn-lg btn-primary"
          :disabled="isUploadingPhoto"
          @click="savePhoto">
        <i class="fa fa-spinner fa-spin"
          v-show="isUploadingPhoto"></i>
        保存
      </button>
      <button class="btn btn-lg btn-default ml-8" @click="onCancel">
        キャンセル
      </button>
    </div>
  </div>
</div>
</template>

<script>
import imageMixin from '@/mixin/imageMixin'
import mediaMixin from '@/mixin/mediaMixin'
import FabricCanvas from '@/components/lib/FabricCanvas'
import ColorPicker from '@/components/lib/ColorPicker'
let api

export default {
  name: 'photo-editor',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    photo: {
      type: Object,
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isUploadingPhoto: false,
      photoImage: {src: ''},
      sizeInfo: {
        width: 0,
        height: 0,
        realWidth: 0,
        realHeight: 0,
      },
      showStrokeColorPalette: false,
      showFillColorPalette: false,

      drawMode: 1,
      isColorCorrectionMode: false,

      globalOptions: {
        strokeColor: '#000',
        fillColor: null,
        strokeWidth: 4,
        lineType: 'solid',
        fontSize: 20,
        writingMode: 'horizontal',
      },
      individualOptions: null,
      drawOptionsDisplay: {
        strokeColor: true,
        fillColor: true,
        lineType: true,
        strokeWidth: true,
        writingMode: true,
        fontSize: true,
      },

      brightness: 0.0,
      contrast: 0.0,
      DRAW_MODE: {
        NONE: -1,
        FREE: 1,
        LINE: 11,
        ARROW: 12,
        DOUBLE_ARROW: 13,
        RECT: 21,
        ELLIPSE: 32,
        POLYLINE: 33,
        TEXT: 41,
      },
    }
  },
  computed: {
    isDrawModeNone() {
      return this.drawMode === this.DRAW_MODE.NONE
    },
    isDrawModeFree() {
      return this.drawMode === this.DRAW_MODE.FREE
    },
    isDrawModeLine() {
      return this.drawMode === this.DRAW_MODE.LINE
    },
    isDrawModeArrow() {
      return this.drawMode === this.DRAW_MODE.ARROW
    },
    isDrawModeDoubleArrow() {
      return this.drawMode === this.DRAW_MODE.DOUBLE_ARROW
    },
    isDrawModeRect() {
      return this.drawMode === this.DRAW_MODE.RECT
    },
    isDrawModeEllipse() {
      return this.drawMode === this.DRAW_MODE.ELLIPSE
    },
    isDrawModePolyline() {
      return this.drawMode === this.DRAW_MODE.POLYLINE
    },
    isDrawModeText() {
      return this.drawMode === this.DRAW_MODE.TEXT
    },
    drawOptions() {
      const currentOptions = this.individualOptions !== null
        ? this.individualOptions
        : this.globalOptions
      return {
        strokeColor: currentOptions.strokeColor,
        strokeWidth: currentOptions.strokeWidth,
        fillColor: currentOptions.fillColor,
        lineType: currentOptions.lineType,
        writingMode: currentOptions.writingMode,
        fontSize: currentOptions.fontSize,
      }
    },
  },
  watch: {
    photo() {
      this.loadImage()
    },
  },
  mounted() {
    api = require(`@/api/${this.apiName}`).default
    this.loadImage()
  },
  mixins: [imageMixin, mediaMixin],
  components: { FabricCanvas, ColorPicker },
  methods: {
    async loadImage() {
      const image = new Image()
      image.onload = () => {
        this.photoImage = image
        this.sizeInfo.realHeight = image.height
        this.sizeInfo.realWidth = image.width

        const maxHeight = window.innerHeight - 280
        const maxWidth = this.$refs.photoEditor.offsetWidth
        let h = image.height
        let w = image.width
        if (h > maxHeight) {
          h = maxHeight
          w = h * image.width / image.height
        }
        if (w > maxWidth) {
          w = maxWidth
          h = w * image.height / image.width
        }
        this.sizeInfo.height = h
        this.sizeInfo.width = w
      }
      const { url } = await this.getBlobUrl(this.photo.photo_url_original)
      image.src = url
    },
    setDrawMode(drawMode) {
      if (drawMode !== this.DRAW_MODE.NONE) {
        this.isColorCorrectionMode = false
        Object.keys(this.drawOptionsDisplay).forEach(key => {
          this.drawOptionsDisplay[key] = true
        })
      }
      if (this.drawMode === drawMode) {
        if (drawMode === this.DRAW_MODE.ARROW) {
          this.drawMode = this.DRAW_MODE.DOUBLE_ARROW
        } else if (drawMode === this.DRAW_MODE.DOUBLE_ARROW) {
          this.drawMode = this.DRAW_MODE.ARROW
        }
        return
      }
      this.drawMode = drawMode
    },
    setStrokeColor(colorStr) {
      this.setOption('strokeColor', colorStr)
      this.showStrokeColorPalette = false
    },
    setFillColor(colorStr) {
      this.setOption('fillColor', colorStr)
      this.showFillColorPalette = false
    },
    setLineType(lineType) {
      this.setOption('lineType', lineType)
    },
    setStrokeWidth(e) {
      const strokeWidth = parseInt(e.target.value)
      this.setOption('strokeWidth', strokeWidth)
    },
    setwritingMode(writingMode) {
      this.setOption('writingMode', writingMode)
    },
    setFontSize(e) {
      const fontSize = parseInt(e.target.value)
      this.setOption('fontSize', fontSize)
    },
    setOption(prop, val) {
      if (this.individualOptions) {
        this.individualOptions[prop] = val
      } else {
        this.globalOptions[prop] = val
      }
    },
    setBrightness(e) {
      this.brightness = parseFloat(e.target.value)
    },
    setContrast(e) {
      this.contrast = parseFloat(e.target.value)
    },
    undo() {
      this.$refs.canvas.undo()
    },
    redo() {
      this.$refs.canvas.redo()
    },
    savePhoto() {
      const formData = new FormData()
      const ts = new Date().valueOf()
      const dataURL = this.$refs.canvas.toDataURL()
      formData.append(
        'photos[]',
        this.canvasDataURLToBlob(dataURL),
        `${this.photo.id}_edit_${ts}.jpeg`
      )

      this.isUploadingPhoto = true
      const obj = {
        id: this.id,
        formData: formData
      }
      api.addPhotos(obj).then(({ data }) => {
        this.isUploadingPhoto = false
        this.$emit('done', data)
      }).catch(err => {
        this.$emit('should-handle-error-response', err)
        this.isUploadingPhoto = false
      })
    },
    onCancel() {
      this.$emit('cancel')
    },
    canUndo() {
      return this.$refs.canvas && this.$refs.canvas.canUndo
    },
    canRedo() {
      return this.$refs.canvas && this.$refs.canvas.canRedo
    },
    removeSelectedObject() {
      this.$refs.canvas.removeSelectedObject()
    },
    onAfterObjectDrew() {
      this.setDrawMode(this.DRAW_MODE.NONE)
    },
    onObjectSelected(data) {
      Object.keys(this.drawOptionsDisplay).forEach(key => {
        this.drawOptionsDisplay[key] = false
      })
      this.drawOptionsDisplay.strokeColor = true
      if (data.drawMode !== this.DRAW_MODE.TEXT) {
        this.drawOptionsDisplay.strokeWidth = true
        this.drawOptionsDisplay.lineType = true
      }
      if ([this.DRAW_MODE.RECT,
        this.DRAW_MODE.ELLIPSE,
        this.DRAW_MODE.POLYLINE,
        this.DRAW_MODE.TEXT].includes(data.drawMode)
      ) {
        this.drawOptionsDisplay.fillColor = true
      }
      if (data.drawMode === this.DRAW_MODE.TEXT) {
        this.drawOptionsDisplay.fontSize = true
        this.drawOptionsDisplay.writingMode = true
      }
      this.individualOptions = {
        strokeColor: data.strokeColor,
        strokeWidth: data.strokeWidth,
        fillColor: data.fillColor,
        lineType: data.lineType,
        fontSize: data.fontSize,
        writingMode: data.writingMode,
      }
    },
    onObjectDeselected() {
      Object.keys(this.drawOptionsDisplay).forEach(key => {
        this.drawOptionsDisplay[key] = true
      })
      this.individualOptions = null
    },
    onColorCorrectionClick() {
      Object.keys(this.drawOptionsDisplay).forEach(key => {
        this.drawOptionsDisplay[key] = false
      })
      this.isColorCorrectionMode = true
      this.setDrawMode(this.DRAW_MODE.NONE)
    },
    onBrightnessChanged(brightness) {
      this.brightness = brightness
    },
    onContrastChanged(contrast) {
      this.contrast = contrast
    },
  },
}
</script>

<style lang="scss" scoped>
.undo, .redo {
  height: 42px;
  border-color: #ddd;
}
.btn.move-complete {
  height: 42px;
}
.main {
  display: flex;
}
.toolbox1, .toolbox2, .toolbox-row {
  position: relative;
  padding: 4px 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  .toolbox-option {
    display: flex;
    align-items: center;
    background: #efefef;
    width: 320px;
    padding: 4px 4px;
    span {
      display: inline-block;
      margin: 0px 4px 0px 16px;
      white-space: nowrap;
      min-width: 92px;
      font-size: 14px;
    }
    button {
      border: 1px solid #ddd;
      border-radius: 4px;
    }
    .color-picker {
      position: relative;
    }
    .range-bar {
      display: inline-block;
      width: 160px;
    }
    label {
      width: 30px;
      margin-bottom: 0px;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
    }
  }
}
.toolbox2 {
  margin: 0px 0px 0px 20px;
  padding: 0px;
  border: none;
  .toolbox-row {
    margin: 0px 0px 6px 0px;
    .toolbox-option {
      height: 45px;
    }
  }
}
hr {
  display: inline-block;
  background: #ddd;
  vertical-align: middle;
  width: 1px;
  height: 33px;
  margin: 0px 4px 0px 4px;
}
.canvas-wrapper {
  position: relative;

  .base-canvas {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-family: monospace;
  }
}
.photo-edit-bottom-bar {
  margin-top: 4px;
}
.memo {
  margin: 8px 0px;
  font-size: 14px;
}
</style>
