var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"page page-master-list"},[_c('div',{staticClass:"ibox float-e-margins"},[_c('div',{staticClass:"ibox-content clearfix"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table table-striped font-md v-middle"},[_c('tbody',[_vm._l((_vm.list),function(e){return _c('tr',[_c('td',[_c('router-link',{attrs:{"to":{
                      name:'masterDetail',
                      params: {id: e.id},
                    }}},[_vm._v(" "+_vm._s(e.name)+" ")])],1)])}),_c('tr',[_c('td',[_c('router-link',{attrs:{"to":{
                      name:'damageTypeTemplateDetail',
                    }}},[_vm._v(" 点検結果定型文(緊急点検) ")])],1)])],2)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"ib"},[_vm._v("マスタ一覧")])])
}]

export { render, staticRenderFns }