import Vue from 'vue'

function getLovDisp(lovName, key, prop) {
  const lovs = window.master.lovs
  if (!lovs || !lovs[lovName] || !key || !lovs[lovName].map[key]) { return '' }
  return lovs[lovName].map[key][prop]
}

export const highwayNameDisp = (key, dispProp = 'name') => {
  return getLovDisp('highway', key, dispProp)
}
export const highwayDirectionDisp = (key, dispProp = 'name') => {
  return getLovDisp('highway_direction_type', key, dispProp)
}
export const highwayLaneDisp = (key, dispProp = 'disp1') => {
  return getLovDisp('highway_lane_type', key, dispProp)
}
export const highwayLrDisp = (key, dispProp = 'name') => {
  return getLovDisp('highway_lr_type', key, dispProp)
}
export const highwayPlace1TypeDisp = (key, dispProp = 'prefix') => {
  return getLovDisp('highway_place1_type', key, dispProp)
}
export const highwayPlace2TextDisp = (place2Type, key, dispProp = 'name') => {
  let ret = getLovDisp('highway_place2_text', key, dispProp)
  if (place2Type === '7') {
    ret += '料金所'
  } else if (place2Type === '8') {
    ret += 'ＰＡ'
  }
  return ret
}

// 緊急点検
export const highwayDisp1 = (report, prefix, opts = {}) => {
  // 高速上/高速外
  const onOffType = report[prefix + '_on_off_type']
  if (!onOffType) { return '' }
  // 路線名
  const roadName = highwayNameDisp(report[prefix + '_name'])
  if (!roadName) { return '' }
  // 方向
  const direction = highwayDirectionDisp(report[prefix + '_direction'])
  // その他
  const info = report[prefix + '_info'] || {}

  // 車線区分
  const lane = highwayLaneDisp(info.lane)
  // 左右区分
  const lr = highwayLrDisp(info.lr)
  // 点検個所
  const place1Type = highwayPlace1TypeDisp(info.place1_type)
  const place1Text = info.place1_text || ''
  // 形状
  const place2Text = highwayPlace2TextDisp(info.place2_type, info.place2_text)
  // 街路名
  const streetName = info.street_name
  // 点検場所
  const otherText = info.other_text

  const arr = []
  if (onOffType === 'on') {
    if (!opts.hideOnOffLabel) { arr.push('高速上：') }
    arr.push(roadName)
    arr.push(direction)
    if (lane) {
      arr.push(lane)
    }
    if (lr) {
      arr.push(lr)
    }
    if (place1Type) {
      arr.push(`${place1Type}${place1Text}`)
    } else if (place1Text) {
      arr.push(place1Text)
    }
    if (place2Text) {
      arr.push(place2Text)
    } else {
      arr.push(otherText)
    }
  } else {
    if (!opts.hideOnOffLabel) { arr.push('高速外：') }
    arr.push(roadName)
    arr.push(direction)
    if (place1Type) {
      arr.push(`${place1Type}${place1Text}`)
    } else if (place1Text) {
      arr.push(place1Text)
    }
    if (streetName) {
      arr.push(streetName)
    }
    if (otherText) {
      arr.push(otherText)
    }
  }
  return arr.filter(e => !!e).join(' ')
}

// 保安規制
export const highwayDisp2 = (report, prefix) => {
  // 高速上/高速外
  const onOffType = report[prefix + '_on_off_type']
  if (!onOffType) { return '' }
  // 路線名
  const roadName = highwayNameDisp(report[prefix + '_name'])
  if (!roadName) { return '' }
  // 方向
  const direction = highwayDirectionDisp(report[prefix + '_direction'])
  // その他
  const info = report[prefix + '_info'] || {}

  // 車線区分
  const lane = highwayLaneDisp(info.lane)
  // 左右区分
  const lr = highwayLrDisp(info.lr)
  // 点検個所
  // const place1Type = highwayPlace1TypeDisp(info.place1_type)
  // const place1Text = info.place1_text || ''
  // 形状
  const place2Text = highwayPlace2TextDisp(info.place2_type, info.place2_text)
  // 街路名
  const streetName = info.street_name
  // 点検場所
  const otherText = info.other_text

  // 開始KP
  const startKP = info.start_kp
  // 終了KP
  const endKP = info.end_kp
  // 規制延長
  let regulationLength = 0
  if (!isNaN(startKP) && !isNaN(endKP)) {
    regulationLength = (parseFloat(startKP) * 1000 - parseFloat(endKP) * 1000) / 1000
    regulationLength = Math.abs(regulationLength)
    // m単位
    regulationLength = Math.round(regulationLength * 1000)
  }
  // 規制区分
  const regulationType = info.regulation_type

  const arr = []
  if (onOffType === 'on') {
    arr.push(roadName)
    arr.push(direction)
    if (lane) {
      arr.push(lane)
    }
    if (lr) {
      arr.push(lr)
    }
    // if (place1Type) {
    //   arr.push(`${place1Type}${place1Text}`)
    // } else if (place1Text) {
    //   arr.push(place1Text)
    // }
  } else {
    arr.push('街路上：')
    arr.push(roadName)
    arr.push(direction)
    // if (place1Type) {
    //   arr.push(`${place1Type}${place1Text}`)
    // } else if (place1Text) {
    //   arr.push(place1Text)
    // }
  }

  if (onOffType === 'on') {
    arr.push('規制開始')
    arr.push(startKP)
    arr.push('KP')
    arr.push('〜')
    arr.push('規制終了')
    arr.push(endKP)
    arr.push('KP')
  }

  // 規制場所概略
  arr.push('（')
  if (onOffType === 'on') {
    if (place2Text) {
      arr.push(place2Text)
    } else {
      arr.push(otherText)
    }
  } else {
    if (streetName) {
      arr.push(streetName)
    }
    if (otherText) {
      arr.push(otherText)
    }
  }
  arr.push('）')

  if (onOffType === 'on') {
    arr.push('規制延長')
    arr.push(`${regulationLength}m`)
    arr.push('　規制区分')
    arr.push(regulationType)
  }

  return arr.filter(e => !!e).join(' ')
}

export const highwayNameFacilityDisp = (key, dispProp = 'name') => {
  return getLovDisp('highway_facility', key, dispProp)
}
export const highwayDirectionFacilityDisp = (key, dispProp = 'name') => {
  return getLovDisp('highway_direction_type_facility', key, dispProp)
}

// 緊急連絡
export const highwayDisp3 = (report, prefix) => {
  // 高速上/高速外
  const onOffType = report[prefix + '_on_off_type']
  if (!onOffType) { return '' }
  // 路線名
  const roadName = highwayNameFacilityDisp(report[prefix + '_name'])
  if (!roadName) { return '' }
  // 方向
  const direction = highwayDirectionFacilityDisp(report[prefix + '_direction'])
  // その他
  const info = report[prefix + '_info'] || {}

  // 車線区分
  const lane = highwayLaneDisp(info.lane)
  // 左右区分
  const lr = highwayLrDisp(info.lr)
  // 点検個所
  const place1Type = highwayPlace1TypeDisp(info.place1_type)
  const place1Text = info.place1_text || ''
  // 形状
  const place2Text = highwayPlace2TextDisp(info.place2_type, info.place2_text)
  // 街路名
  const streetName = info.street_name
  // 概略位置
  const otherText = info.other_text

  const arr = []
  if (onOffType === 'on') {
    arr.push(roadName)
    if (direction) {
      arr.push(`(${direction})`)
    }
    if (place2Text) {
      arr.push(place2Text)
      let tmpText = ''
      if (info.place2_text_other1) {
        tmpText += info.place2_text_other1
      }
      if (info.place2_text_other2) {
        tmpText += info.place2_text_other2
      }
      if (tmpText) { arr.push(tmpText) }
    } else if (otherText) {
      arr.push(otherText)
    }
    if (place1Type) {
      arr.push(`${place1Type}${place1Text}`)
    } else if (place1Text) {
      arr.push(place1Text)
    }
    if (lane) {
      arr.push(`(${lane}`)
      arr.push(`${lr})`)
    }
  } else {
    arr.push(roadName)
    if (direction) {
      arr.push(`(${direction})`)
    }
    if (streetName) {
      arr.push(streetName)
    }
    if (otherText) {
      arr.push(otherText)
    }
    if (place1Type) {
      arr.push(`${place1Type}${place1Text}`)
    } else if (place1Text) {
      arr.push(place1Text)
    }
  }
  return arr.filter(e => !!e).join(' ')
}

export const inspectContentDisp = (report) => {
  const arr = []
  const NL = '\n'
  const splitByLineBreak = Vue.filter('splitByLineBreak')

  if (report.inspect_free_input_pre1) {
    arr.push(report.inspect_free_input_pre1)
  }
  if (report.inspect_free_input_method1) {
    arr.push('＜調査方法＞')
    arr.push(splitByLineBreak(report.inspect_free_input_method1).join(NL))
  }
  if (report.inspect_free_input_scope1) {
    arr.push('＜調査範囲＞')
    arr.push(splitByLineBreak(report.inspect_free_input_scope1).join(NL))
  }
  if (report.inspect_free_input_result1) {
    arr.push('＜調査結果＞')
    arr.push(splitByLineBreak(report.inspect_free_input_result1).join(NL))
  }
  if (report.inspect_audio_input_contents) {
    const audioInputs = report.inspect_audio_input_contents
    Object.keys(audioInputs)
      .map(k => parseInt(k))
      .sort()
      .forEach(k => {
        arr.push(audioInputs[k])
      })
  }
  if (report.inspect_free_input_first_aid1) {
    arr.push('＜応急処理＞')
    arr.push(splitByLineBreak(report.inspect_free_input_first_aid1).join(NL))
  }
  if (report.inspect_free_input_post1) {
    arr.push(report.inspect_free_input_post1)
  }
  return arr.join(NL)
}

// 緊急点検業務日誌:高速上/高速外ラベル表示
export const highwayOnOffTypeDisp = (report, prefix) => {
  // 高速上/高速外
  const onOffType = report[prefix + '_on_off_type']
  if (!onOffType) { return '' }

  const arr = []
  if (onOffType === 'on') {
    arr.push('高速上')
  } else {
    arr.push('高速外')
  }
  return arr.filter(e => !!e).join(' ')
}

function highwayDispAllReportSummaryCheck(report, prefix) {
  // 高速上/高速外
  const onOffType = report[prefix + '_on_off_type']
  if (!onOffType) { return false }
  // 路線名
  const roadName = highwayNameDisp(report[prefix + '_name'])
  if (!roadName) { return false }
  return true
}

// 緊急点検業務日誌:緊急点検/緊急連絡/苦情処理共通
export const highwayDispAllReportSummary1 = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  return highwayNameDisp(report[prefix + '_name'])
}

export const highwayDispAllReportSummary2_1 = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  return highwayDirectionDisp(report[prefix + '_direction'])
}

export const highwayDispAllReportSummary2_2 = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  const info = report[prefix + '_info'] || {}
  return highwayLaneDisp(info.lane)
}

export const highwayDispAllReportSummary2_3 = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  const info = report[prefix + '_info'] || {}
  // 点検箇所
  const place1Type = highwayPlace1TypeDisp(info.place1_type) || ''
  const place1Text = info.place1_text || ''
  if (place1Type) {
    return `${place1Type}${place1Text}`
  } else if (place1Text) {
    return place1Text
  }
  return ''
}

export const highwayDispAllReportSummary3 = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  const onOffType = report[prefix + '_on_off_type']
  const info = report[prefix + '_info'] || {}
  // 形状
  const place2Text = highwayPlace2TextDisp(info.place2_type, info.place2_text)
  // 街路名
  const streetName = info.street_name
  // 点検場所
  const otherText = info.other_text

  const arr = []
  if (onOffType === 'on') {
    if (place2Text) {
      arr.push(place2Text)
    } else {
      arr.push(otherText)
    }
  } else {
    if (streetName) {
      arr.push(streetName)
    }
    // if (otherText) {
    //   arr.push(otherText)
    // }
  }
  return arr.filter(e => !!e).join(' ')
}

export const highwayDispAllReportSummary_2_3_RRR = (report, prefix) => {
  if (!highwayDispAllReportSummaryCheck(report, prefix)) {
    return ''
  }
  const info = report[prefix + '_info'] || {}
  if (!info.start_kp) { return '' }
  return `Kp${info.start_kp}〜`
}
