const reportNumDisp = (reportNum) => {
  if (!reportNum) { return '' }
  const parts = reportNum.split('-')

  let ret = ''
  if (parts.length === 4) {
    // 緊急点検、苦情処理、保安規制
    ret = parts[3]
  } else if (parts.length === 5) {
    // 緊急連絡
    ret = parts[4]
  }
  return parseInt(ret)
}

export const reportNumPaddedDisp = (reportNum) => {
  return ('000' + reportNumDisp(reportNum)).slice(-3)
}
