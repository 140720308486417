<template>
<input class="time-input form-control ib" :style="{width:width}"
  :class="{'bg-blue': !readonly}"
  maxlength="2" v-model="inputValue" :readonly="readonly"
  @change="onChange">
</template>

<script>
export default {
  name: 'time-input',
  props: {
    value: {
      type: [String, Number],
    },
    mode: {
      type: String,
      required: true,
      validator(val) {
        return ['hour', 'minute'].includes(val)
      },
    },
    dataType: {
      type: String,
      default: 'string',
      validator(val) {
        return ['string', 'number'].includes(val)
      },
    },
    width: {
      type: String,
      default: '48px',
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      inputValue: this.convVal(this.value),
    }
  },
  watch: {
    value() {
      this.inputValue = this.convVal(this.value)
    },
  },
  methods: {
    adjustValRange(val) {
      if (val === null || val === undefined) { return val }
      const valStr = val.toString()
      let valInt = parseInt(val)
      if (!valStr.match(/^\d+$/)) { return val }
      if (isNaN(valInt)) { return val }

      if (this.mode === 'hour') {
        if (valInt < 0) { valInt = 0 }
        if (valInt > 23) { valInt = 23 }
      } else if (this.mode === 'minute') {
        if (valInt < 0) { valInt = 0 }
        if (valInt > 59) { valInt = 59 }
      }
      return valInt
    },
    formatVal(val) {
      if (val === null || val === undefined) { return val }
      if (this.dataType === 'string') {
        val = val.toString()
        if (val.match(/^\d+$/)) {
          val = ('0' + val).slice(-2)
        }
      } else if (this.dataType === 'number') {
        val = parseInt(val)
        if (isNaN(val)) { val = 0 }
      }
      return val
    },
    convVal(val) {
      return this.formatVal(this.adjustValRange(val))
    },
    onChange($event) {
      this.inputValue = this.convVal(this.inputValue)
      this.$emit('input', this.inputValue)
    }
  },
}
</script>

<style lang="scss" scoped>
  .time-input.form-control {
    padding: 6px 12px;
  }
</style>
