import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/road_regulation_reports'), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl('/road_regulation_reports'), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/road_regulation_reports/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/road_regulation_reports/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/road_regulation_reports/${id}`))
  },
  addPhotos({ id, formData }) {
    return axios.post(utils.getApiUrl(`/road_regulation_reports/${id}/photos`), formData)
  },
  updatePhotos({ id, data }) {
    return axios.patch(utils.getApiUrl(`/road_regulation_reports/${id}/photos`), data)
  },
  deletePhoto({ id, photo }) {
    return axios.delete(utils.getApiUrl(`/road_regulation_reports/${id}/photos/${photo.id}`))
  },
  downloadCSV({ data }) {
    return axios.get('/road_regulation_reports/download_csv.csv', {
      params: data,
      responseType: 'blob',
    })
  },
}
