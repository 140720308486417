import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import user from './modules/user'
import localStorage from './modules/localStorage'

Vue.use(Vuex)

const store = new Vuex.Store({
  getters,
  actions,
  modules: {
    user,
    localStorage
  }
})

export default store
