import Vue from 'vue'
import Router from 'vue-router'

import Top from '@/components/Top'
import Login from '@/components/Login'

import EmergencyInspectList from '@/components/emergencyInspect/EmergencyInspectList'
import EmergencyInspectDetail from '@/components/emergencyInspect/EmergencyInspectDetail'
import EmergencyInspectPhotos from '@/components/emergencyInspect/EmergencyInspectPhotos'
import PrintEmergencyInspect from '@/components/emergencyInspect/PrintEmergencyInspect'
import PrintEmergencyInspectPhotos from '@/components/emergencyInspect/PrintEmergencyInspectPhotos'

import ComplaintHandlingList from '@/components/complaintHandling/ComplaintHandlingList'
import ComplaintHandlingDetail from '@/components/complaintHandling/ComplaintHandlingDetail'
import ComplaintHandlingPhotos from '@/components/complaintHandling/ComplaintHandlingPhotos'
import PrintComplaintHandling from '@/components/complaintHandling/PrintComplaintHandling'
import PrintComplaintHandlingPhotos from '@/components/complaintHandling/PrintComplaintHandlingPhotos'

import RoadRegulationList from '@/components/roadRegulation/RoadRegulationList'
import RoadRegulationDetail from '@/components/roadRegulation/RoadRegulationDetail'
import RoadRegulationPhotos from '@/components/roadRegulation/RoadRegulationPhotos'
import PrintRoadRegulation from '@/components/roadRegulation/PrintRoadRegulation'
import PrintRoadRegulationPhotos from '@/components/roadRegulation/PrintRoadRegulationPhotos'

import EmergencyContactList from '@/components/emergencyContact/EmergencyContactList'
import EmergencyContactDetail from '@/components/emergencyContact/EmergencyContactDetail'
import EmergencyContactPhotos from '@/components/emergencyContact/EmergencyContactPhotos'
import PrintEmergencyContact from '@/components/emergencyContact/PrintEmergencyContact'
import PrintEmergencyContactPhotos from '@/components/emergencyContact/PrintEmergencyContactPhotos'

import AllReportsSummaryList from '@/components/red/allReportsSummary/AllReportsSummaryList'
import AllReportsSummaryDetail from '@/components/red/allReportsSummary/AllReportsSummaryDetail'
import PrintAllReportsSummary from '@/components/red/allReportsSummary/PrintAllReportsSummary'

import WorkResultTableList from '@/components/red/workResultTable/WorkResultTableList'
import WorkResultTableDetail from '@/components/red/workResultTable/WorkResultTableDetail'
import PrintWorkResultTable from '@/components/red/workResultTable/PrintWorkResultTable'

import WorkResultTachographList from '@/components/red/workResultTachograph/WorkResultTachographList'
import WorkResultTachographDetail from '@/components/red/workResultTachograph/WorkResultTachographDetail'
import PrintWorkResultTachograph from '@/components/red/workResultTachograph/PrintWorkResultTachograph'

import EmergencyContactSummaryList from '@/components/red/emergencyContactSummary/EmergencyContactSummaryList'
import EmergencyContactSummaryDetail from '@/components/red/emergencyContactSummary/EmergencyContactSummaryDetail'
import PrintEmergencyContactSummary from '@/components/red/emergencyContactSummary/PrintEmergencyContactSummary'

import SecurityChecklistList from '@/components/red/securityChecklist/SecurityChecklistList'
import SecurityChecklistDetail from '@/components/red/securityChecklist/SecurityChecklistDetail'
import PrintSecurityChecklist from '@/components/red/securityChecklist/PrintSecurityChecklist'

import MasterList from '@/components/master/MasterList'
import MasterDetail from '@/components/master/MasterDetail'
import DamageTypeTemplateDetail from '@/components/master/DamageTypeTemplateDetail'

import RenumberingList from '@/components/dataMaintenance/RenumberingList'

import Dummy from '@/components/Dummy'

Vue.use(Router)

export const appRouter = new Router({
  mode: 'history',
  scrollBehavior(/* to, from, savedPosition */) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    // 緊急点検報告書
    {
      path: '/emergency-inspect-list',
      name: 'emergencyInspectList',
      component: EmergencyInspectList
    },
    {
      path: '/emergency-inspect-detail/new',
      name: 'emergencyInspectNew',
      component: EmergencyInspectDetail
    },
    {
      path: '/emergency-inspect-detail/:id/copy',
      name: 'emergencyInspectCopy',
      component: EmergencyInspectDetail,
      props: true,
    },
    {
      path: '/emergency-inspect-detail/:id',
      name: 'emergencyInspectDetail',
      component: EmergencyInspectDetail,
      props: true,
    },
    {
      path: '/emergency-inspect-detail/:id/photos',
      name: 'emergencyInspectPhotos',
      component: EmergencyInspectPhotos,
      props: true,
    },
    // 苦情処理
    {
      path: '/complaint-handling-list',
      name: 'complaintHandlingList',
      component: ComplaintHandlingList,
    },
    {
      path: '/complaint-handling-detail/new',
      name: 'complaintHandlingNew',
      component: ComplaintHandlingDetail
    },
    {
      path: '/complaint-handling-detail/:id/copy',
      name: 'complaintHandlingCopy',
      component: ComplaintHandlingDetail,
      props: true,
    },
    {
      path: '/complaint-handling-detail/:id',
      name: 'complaintHandlingDetail',
      component: ComplaintHandlingDetail,
      props: true,
    },
    {
      path: '/complaint-handling-detail/:id/photos',
      name: 'complaintHandlingPhotos',
      component: ComplaintHandlingPhotos,
      props: true,
    },
    // 保安規制
    {
      path: '/road-regulation-list',
      name: 'roadRegulationList',
      component: RoadRegulationList,
    },
    {
      path: '/road-regulation-detail/new',
      name: 'roadRegulationNew',
      component: RoadRegulationDetail
    },
    {
      path: '/road-regulation-detail/:id/copy',
      name: 'roadRegulationCopy',
      component: RoadRegulationDetail,
      props: true,
    },
    {
      path: '/road-regulation-detail/:id',
      name: 'roadRegulationDetail',
      component: RoadRegulationDetail,
      props: true,
    },
    {
      path: '/road-regulation-detail/:id/photos',
      name: 'roadRegulationPhotos',
      component: RoadRegulationPhotos,
      props: true,
    },
    // 緊急連絡報告書
    {
      path: '/emergency-contact-list',
      name: 'emergencyContactList',
      component: EmergencyContactList,
    },
    {
      path: '/emergency-contact-detail/new',
      name: 'emergencyContactNew',
      component: EmergencyContactDetail
    },
    {
      path: '/emergency-contact-detail/:id/copy',
      name: 'emergencyContactCopy',
      component: EmergencyContactDetail,
      props: true,
    },
    {
      path: '/emergency-contact-detail/:id',
      name: 'emergencyContactDetail',
      component: EmergencyContactDetail,
      props: true,
    },
    {
      path: '/emergency-contact-detail/:id/photos',
      name: 'emergencyContactPhotos',
      component: EmergencyContactPhotos,
      props: true,
    },
    // 緊急点検業務日誌
    {
      path: '/all-reports-summary-list',
      name: 'allReportsSummaryList',
      component: AllReportsSummaryList,
    },
    {
      path: '/all-reports-summary-detail/:id',
      name: 'allReportsSummaryDetail',
      component: AllReportsSummaryDetail,
      props: true,
    },
    // 緊急点検出動報告書(工程表)
    {
      path: '/work-result-table-list',
      name: 'workResultTableList',
      component: WorkResultTableList,
    },
    {
      path: '/work-result-table-detail/:id',
      name: 'workResultTableDetail',
      component: WorkResultTableDetail,
      props: true,
    },
    // 緊急点検出動報告書(タコグラフ)
    {
      path: '/work-result-tachograph-list',
      name: 'workResultTachographList',
      component: WorkResultTachographList,
    },
    {
      path: '/work-result-tachograph-detail/:id',
      name: 'workResultTachographDetail',
      component: WorkResultTachographDetail,
      props: true,
    },
    // 緊急連絡施設日誌(連絡表)
    {
      path: '/emergency-contact-summary-list',
      name: 'emergencyContactSummaryList',
      component: EmergencyContactSummaryList,
    },
    {
      path: '/emergency-contact-summary-detail/:id',
      name: 'emergencyContactSummaryDetail',
      component: EmergencyContactSummaryDetail,
      props: true,
    },
    // 安全管理日誌
    {
      path: '/security-checklist-list',
      name: 'securityChecklistList',
      component: SecurityChecklistList,
    },
    {
      path: '/security-checklist-detail/:id',
      name: 'securityChecklistDetail',
      component: SecurityChecklistDetail,
      props: true,
    },
    // マスタ登録
    {
      path: '/masters',
      name: 'masterList',
      component: MasterList,
    },
    {
      path: '/masters/damage_type_template',
      name: 'damageTypeTemplateDetail',
      component: DamageTypeTemplateDetail,
    },
    {
      path: '/masters/:id',
      name: 'masterDetail',
      component: MasterDetail,
      props: true,
    },
    // 連番振替リスト
    {
      path: '/renumbering-list',
      name: 'renumberingList',
      component: RenumberingList,
    },
    // 緊急点検報告書
    {
      path: '/print/emergency-inspect/:id',
      name: 'printEmergencyInspect',
      component: Dummy,
      props: true,
    },
    {
      path: '/print/emergency-inspect/:id/photos',
      name: 'printEmergencyInspectPhotos',
      component: Dummy,
      props: true,
    },
    // 苦情処理
    {
      path: '/print/complaint-handling/:id',
      name: 'printComplaintHandling',
      component: Dummy,
      props: true,
    },
    {
      path: '/print/complaint-handling/:id/photos',
      name: 'printComplaintHandlingPhotos',
      component: Dummy,
      props: true,
    },
    // 保安規制
    {
      path: '/print/road-regulation-detail/:id',
      name: 'printRoadRegulation',
      component: Dummy,
      props: true,
    },
    {
      path: '/print/road-regulation-detail/:id/photos',
      name: 'printRoadRegulationPhotos',
      component: Dummy,
      props: true,
    },
    // 緊急連絡報告書
    {
      path: '/print/emergency-contact-detail/:id',
      name: 'printEmergencyContact',
      component: Dummy,
      props: true,
    },
    {
      path: '/print/emergency-contact-detail/:id/photos',
      name: 'printEmergencyContactPhotos',
      component: Dummy,
      props: true,
    },
    // 緊急点検業務日誌
    {
      path: '/print/all-reports-summary-detail/:id',
      name: 'printAllReportsSummary',
      component: Dummy,
      props: true,
    },
    // 緊急点検出動報告書(工程表)
    {
      path: '/print/work-result-table-detail/:id',
      name: 'printWorkResultTable',
      component: Dummy,
      props: true,
    },
    // 緊急点検出動報告書(タコグラフ)
    {
      path: '/print/work-result-tachograph-detail/:id/:crewNumber?',
      name: 'printWorkResultTachograph',
      component: Dummy,
      props: true,
    },
    // 緊急連絡施設日誌(連絡表)
    {
      path: '/print/emergency-contact-summary-detail/:id',
      name: 'printEmergencyContactSummary',
      component: Dummy,
      props: true,
    },
    // 安全管理日誌
    {
      path: '/print/security-checklist-detail/:id/:crewNumber?',
      name: 'printSecurityChecklist',
      component: Dummy,
      props: true,
    },
    // catch all
    {
      path: '*',
      redirect: {name: 'login'},
    },
  ]
})

export const printRouter = new Router({
  mode: 'history',
  scrollBehavior(/* to, from, savedPosition */) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    // 緊急点検報告書
    {
      path: '/print/emergency-inspect/:id',
      name: 'printEmergencyInspect',
      component: PrintEmergencyInspect,
      props: true,
    },
    {
      path: '/print/emergency-inspect/:id/photos',
      name: 'printEmergencyInspectPhotos',
      component: PrintEmergencyInspectPhotos,
      props: true,
    },
    // 苦情処理
    {
      path: '/print/complaint-handling/:id',
      name: 'printComplaintHandling',
      component: PrintComplaintHandling,
      props: true,
    },
    {
      path: '/print/complaint-handling/:id/photos',
      name: 'printComplaintHandlingPhotos',
      component: PrintComplaintHandlingPhotos,
      props: true,
    },
    // 保安規制
    {
      path: '/print/road-regulation-detail/:id',
      name: 'printRoadRegulation',
      component: PrintRoadRegulation,
      props: true,
    },
    {
      path: '/print/road-regulation-detail/:id/photos',
      name: 'printRoadRegulationPhotos',
      component: PrintRoadRegulationPhotos,
      props: true,
    },
    // 緊急連絡報告書
    {
      path: '/print/emergency-contact-detail/:id',
      name: 'printEmergencyContact',
      component: PrintEmergencyContact,
      props: true,
    },
    {
      path: '/print/emergency-contact-detail/:id/photos',
      name: 'printEmergencyContactPhotos',
      component: PrintEmergencyContactPhotos,
      props: true,
    },
    // 緊急点検業務日誌
    {
      path: '/print/all-reports-summary-detail/:id',
      name: 'printAllReportsSummary',
      component: PrintAllReportsSummary,
      props: true,
    },
    // 緊急点検出動報告書(工程表)
    {
      path: '/print/work-result-table-detail/:id',
      name: 'printWorkResultTable',
      component: PrintWorkResultTable,
      props: true,
    },
    // 緊急点検出動報告書(タコグラフ)
    {
      path: '/print/work-result-tachograph-detail/:id/:crewNumber?',
      name: 'printWorkResultTachograph',
      component: PrintWorkResultTachograph,
      props: true,
    },
    // 緊急連絡施設日誌(連絡表)
    {
      path: '/print/emergency-contact-summary-detail/:id',
      name: 'printEmergencyContactSummary',
      component: PrintEmergencyContactSummary,
      props: true,
    },
    // 安全管理日誌
    {
      path: '/print/security-checklist-detail/:id/:crewNumber?',
      name: 'printSecurityChecklist',
      component: PrintSecurityChecklist,
      props: true,
    },
    // catch all
    {
      path: '*',
      redirect: {name: 'login'},
    },
  ]
})
