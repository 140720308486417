<template src="./template.html"></template>

<script>
import Stream from '@/lib/Stream'
import EVT from '@/consts/eventNames'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'
let api

export default {
  name: 'detail-photos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sheetWidth: 210, // mm
      sheetHeight: 296, // mm
      sheetScale: 10,
      minSheetScale: 3,
      maxSheetScale: 10,

      reporteeDepMap: {},

      meta: {},

      photos: [],
      photoMap: [],
      pages: [],

      currentPage: 1,
      maxPage: 30,

      selectedPhoto: null,
      photoSelectorModalTitle: '写真選択',
      showPhotoSelectorModal: false,

      stream: new Stream(),
      photoSelectorStream: new Stream(),

      draggedPhoto: null,
      isDraggable: true,

      errors: {},

      confirmBeforeDiscardModal: {
        show: false,
        onClose: () => {},
        onDismiss: () => { this.confirmBeforeDiscardModal.show = false },
      },
    }
  },
  computed: {
    hasPage() {
      return this.pages.length > 0
    },
    hasMaxPage() {
      return this.pages.length >= this.maxPage
    },
    printPageRouteName() {
      const routeName = this.$route.name
      const first = routeName[0]
      const rest = routeName.slice(1)
      return `print${first.toUpperCase()}${rest}`
    },
    showHeader() {
      return [
        'emergencyInspectReport',
      ].includes(this.apiName)
    },
    hasChangedPhotoCaptions() {
      return this.photos.filter(e => e.caption !== e.orig_caption).length > 0
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  async mounted() {
    api = require(`@/api/${this.apiName}`).default
    if (this.apiName === 'emergencyInspectReport') {
      await this.waitForMasters()
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
    }
    await this.getData()
    this.connectStreams()
    this.toLastPage()
  },
  mixins: [masterMixin, errorHandleMixin],
  methods: {
    connectStreams() {
      const photoSelector = this.photoSelectorStream
      const me = this.stream
      me.chain(EVT.PHOTO.SELECTOR_RESET).to(photoSelector).end()
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    getData() {
      return api.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          data.photos.forEach(photo => {
            if (photo.used_index !== null) {
              photo.idx = photo.used_index
            }
            photo.orig_caption = photo.caption
          })
          if (this.apiName === 'emergencyInspectReport') {
            data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)
            if (data.reportee_dep2) {
              data.reporteeDepDisp2 = this.reporteeDepDisp(data.reportee_dep2)
            }
          }
          this.meta = data
          this.photos = data.photos
          this.photoMap = this.photos.reduce((obj, photo) => {
            obj[photo.id] = photo; return obj
          }, {})
          this.pages = this.getPages(data)
        })
    },
    getEmptyPage(idx) {
      return {
        idx: idx,
        is_empty: true,
        caption: '',
        orig_caption: '',
      }
    },
    getPages(data) {
      const ret = []
      const usedPhotoIds = this.photos.reduce((arr, photo) => {
        if (photo.used_index !== null) {
          arr[photo.used_index] = photo.id
        }
        return arr
      }, [])
      const len = usedPhotoIds.length
      if (len === 0) {
        ret.push({
          photos: [
            this.getEmptyPage(0), this.getEmptyPage(1),
          ]
        })
        return ret
      }

      const halfLen = Math.ceil(len / 2)
      for (let i = 0; i < halfLen; i++) {
        const idxBase = i * 2
        // const page = []
        let photo1 = this.photoMap[usedPhotoIds[idxBase]]
        if (!photo1) {
          photo1 = this.getEmptyPage(idxBase)
        }

        let photo2 = (idxBase + 1 < len)
          ? this.photoMap[usedPhotoIds[idxBase + 1]]
          : this.getEmptyPage(idxBase + 1)
        if (!photo2) {
          photo2 = this.getEmptyPage(idxBase + 1)
        }

        ret.push({photos: [photo1, photo2]})
      }
      return ret
    },
    toLastPage() {
      this.currentPage = this.pages.length
    },
    addNewPage() {
      const nextIdx = this.pages.length * 2
      this.pages.push({
        photos: [
          this.getEmptyPage(nextIdx), this.getEmptyPage(nextIdx + 1),
        ]
      })
    },
    toNewPage() {
      this.addNewPage()
      this.toLastPage()
    },
    tryShowPhotoEditor(photo) {
      if (this.hasChangedPhotoCaptions) {
        this.confirmBeforeDiscardModal.onClose = () => {
          this.confirmBeforeDiscardModal.show = false
          this.showPhotoEditor(photo)
        }
        this.confirmBeforeDiscardModal.onDismiss = () => {
          this.confirmBeforeDiscardModal.show = false
        }
        this.confirmBeforeDiscardModal.show = true
      } else {
        this.showPhotoEditor(photo)
      }
    },
    showPhotoEditor(photo) {
      this.selectedPhoto = photo
      this.stream.send(EVT.PHOTO.SELECTOR_RESET)
      this.showPhotoSelectorModal = true
    },
    onPhotoSelectorModeChange({ mode, title }) {
      this.photoSelectorModalTitle = title
    },
    async onPhotoShouldRefresh() {
      await this.getData()
    },
    onPhotoSelectorError(err) {
      this.handleErrorResponse(err)
      this.showPhotoSelectorModal = false
    },
    async onUsePhoto(photo) {
      try {
        const photos = []
        photos.push({
          id: photo.id,
          caption: this.selectedPhoto.orig_caption,
          used_index: this.selectedPhoto.idx,
        })
        // 付け替えの場合は元のやつも更新する
        if (
          !this.selectedPhoto.is_empty &&
          this.selectedPhoto.id !== photo.id
        ) {
          let caption = ''
          let usedIndex = null
          if (photo.used_index !== null) {
            caption = photo.orig_caption
            usedIndex = photo.idx
          }
          photos.push({
            id: this.selectedPhoto.id,
            caption: caption,
            used_index: usedIndex,
          })
        }
        const reqObj = {
          id: this.meta.id,
          data: { photos: photos }
        }
        await api.updatePhotos(reqObj)
        await this.getData()
        // 空の追加ページがある場合は
        // currentPageまでたどり着かないので増やす
        let pageDiff = this.currentPage - this.pages.length
        while (pageDiff > 0) {
          this.addNewPage()
          pageDiff--
        }

        // ドラッグアンドドロップの場合は終了
        if (this.isDraggedPhoto(photo)) { return }

        // 次の写真を選択する
        const nextPhotoIdx = this.selectedPhoto.idx + 1
        const nextPhotoPageIdx = parseInt(nextPhotoIdx / 2)
        if (nextPhotoPageIdx > this.pages.length - 1) {
          // 最大ページ数に達している場合は終了
          if (this.hasMaxPage) {
            this.showPhotoSelectorModal = false
            return
          }

          this.toNewPage()
        }
        const nextPhoto = this.pages[nextPhotoPageIdx].photos[nextPhotoIdx % 2]
        if (nextPhoto.is_empty) {
          // 次の写真が未選択の場合は続けて選択
          this.showPhotoEditor(nextPhoto)
        } else {
          // 次の写真が選択済の場合は終了
          this.showPhotoSelectorModal = false
        }
      } catch (err) {
        this.handleErrorResponse(err)
        this.showPhotoSelectorModal = false
      }
    },
    async onUnusePhoto(photo) {
      try {
        await api.updatePhotos({
          id: this.meta.id,
          data: {
            photos: [{
              id: photo.id,
              caption: '',
              used_index: null,
            }]
          }
        })
        await this.getData()
        // 最後のページから削除してた場合は
        // currentPageまでたどり着かないので増やす
        let pageDiff = this.currentPage - this.pages.length
        while (pageDiff > 0) {
          this.addNewPage()
          pageDiff--
        }
        this.showPhotoSelectorModal = false
      } catch (err) {
        this.handleErrorResponse(err)
        this.showPhotoSelectorModal = false
      }
    },
    async savePhotoCaptions() {
      try {
        const photos = []
        for (const photo of this.photos) {
          if (photo.used_index === null) { continue }
          if (photo.caption === photo.orig_caption) { continue }
          photos.push({
            id: photo.id,
            caption: photo.caption,
          })
        }

        const reqObj = {
          id: this.meta.id,
          data: { photos: photos }
        }
        await api.updatePhotos(reqObj)
        await this.getData()
        return true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
    },
    async onClickShowPrintPage() {
      const checkResult = await this.savePhotoCaptions()
      if (!checkResult) { return }
      const router = this.$router.resolve({
        name: this.printPageRouteName,
        params: { id: this.id },
      })
      window.open(router.href, '_blank')
    },
    resetDragState() {
      this.draggedPhoto = null
      this.selectedPhoto = null
    },
    onDragstart(evt, photo) {
      if (!this.isDraggable) { return }

      this.resetDragState()
      const ghostEle = document.createElement('div')
      document.body.appendChild(ghostEle)
      evt.dataTransfer.setDragImage(ghostEle, 0, 0)
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.dropEfect = 'move'
      this.draggedPhoto = photo
    },
    onDragover(photo) {
      if (!this.isDraggable) { return }
      this.selectedPhoto = photo
    },
    onDragleave() {
      if (!this.isDraggable) { return }
      this.selectedPhoto = null
    },
    async tryDroppingDraggedPhoto(evt) {
      if (!this.isDraggable) { return }
      if (!this.draggedPhoto || !this.selectedPhoto || this.isDraggedPhoto(this.selectedPhoto)) {
        this.resetDragState()
        return
      }

      if (this.hasChangedPhotoCaptions) {
        this.confirmBeforeDiscardModal.onClose = async() => {
          this.confirmBeforeDiscardModal.show = false
          await this.dropDraggedPhoto()
        }
        this.confirmBeforeDiscardModal.onDismiss = () => {
          this.resetDragState()
          this.confirmBeforeDiscardModal.show = false
        }
        this.confirmBeforeDiscardModal.show = true
      } else {
        await this.dropDraggedPhoto()
      }
    },
    async dropDraggedPhoto() {
      this.isDraggable = false
      await this.onUsePhoto(this.draggedPhoto)
      this.resetDragState()
      this.isDraggable = true
    },
    isDraggedPhoto(photo) {
      if (!this.draggedPhoto) { return false }
      return this.draggedPhoto.idx === photo.idx
    },
    isDroppingArea(photo) {
      if (!this.draggedPhoto || !this.selectedPhoto) { return false }
      return this.selectedPhoto.idx === photo.idx
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
