<template src="./template.html"></template>

<script>
import Vue from 'vue'
import securityChecklistApi from '@/api/securityChecklist'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import securityChecklistMixin from '@/mixin/securityChecklistMixin'

export default {
  name: 'securityChecklistDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const ret = {
      report: {
        other_props: {},
        report_header: {},
        before_inspection_checklist_infos1: [],
        before_inspection_checklist_infos2: [],
        during_inspection_checklist_infos1: [],
        during_inspection_checklist_infos2: [],
      },
      showUpdateCompleteModal: false,
      checklistValueCands: ['○', '－'],
      beforeInspectionChecklistItemCountMax: 10,
      beforeInspectionChecklistItems: [],
      beforeInspectionChecklistItemMap: {},
      duringInspectionChecklistItemCountMax: 3,
      duringInspectionChecklistItems: [],
      duringInspectionChecklistItemMap: {},
      precautionItemCountMax: 2,
      precautionItems: [],
      emptyItemCountMax: 12,
      emptyItems: [],
      reportApi: securityChecklistApi,
      weathers: [],

      crewNumber: '1',

      errors: {},
    }
    return Object.assign({}, ret)
  },
  computed: {
    dtDisp() {
      return this.report.report_header.dt
    },
    beforeInspectionChecklistItemRows() {
      return this.getChecklistItemsByChecklistInfos(
        this.report[`before_inspection_checklist_infos${this.crewNumber}`],
        this.beforeInspectionChecklistItemMap,
        this.beforeInspectionChecklistItemCountMax
      )
    },
    duringInspectionChecklistItemRows() {
      return this.getChecklistItemsByChecklistInfos(
        this.report[`during_inspection_checklist_infos${this.crewNumber}`],
        this.duringInspectionChecklistItemMap,
        this.duringInspectionChecklistItemCountMax
      )
    },
    precautionItemRows() {
      return this.precautionItems
    },
    emptyItemRows() {
      return this.emptyItems
    },
    hasInputTeam2() {
      return !!this.report.report_header.inspector2
    },
    inspectorDisp() {
      return this.report.report_header[`inspector${this.crewNumber}`]
    },
    driverDisp() {
      return this.report.report_header[`driver${this.crewNumber}`]
    },
    workStartDisp() {
      return this.report.report_header[`work_start${this.crewNumber}`]
    },
    workEndDisp() {
      return this.report.report_header[`work_end${this.crewNumber}`]
    },
  },
  mounted() {
    this.getMasters()
    this.getReport_()
  },
  mixins: [masterMixin, redDetailPageMixin, errorHandleMixin, securityChecklistMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.weathers = lovs.weather.vals_inuse
        this.beforeInspectionChecklistItems =
          lovs.security_checklist_before_inspection_checklist_item.vals_inuse
        this.beforeInspectionChecklistItemMap =
          lovs.security_checklist_before_inspection_checklist_item.map
        this.duringInspectionChecklistItems =
          lovs.security_checklist_during_inspection_checklist_item.vals_inuse
        this.duringInspectionChecklistItemMap =
          lovs.security_checklist_during_inspection_checklist_item.map
      })
    },
    convReport_(data) {
      data.other_props = data.other_props || {}

      if (!data.before_inspection_checklist_infos1) {
        this.setChecklistInfos(data, 'before_inspection_checklist_infos1', this.beforeInspectionChecklistItems)
      }
      if (!data.before_inspection_checklist_infos2) {
        this.setChecklistInfos(data, 'before_inspection_checklist_infos2', this.beforeInspectionChecklistItems)
      }
      if (!data.during_inspection_checklist_infos1) {
        this.setChecklistInfos(data, 'during_inspection_checklist_infos1', this.duringInspectionChecklistItems)
      }
      if (!data.during_inspection_checklist_infos2) {
        this.setChecklistInfos(data, 'during_inspection_checklist_infos2', this.duringInspectionChecklistItems)
      }

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })

      this.prepareDispArrays()
    },
    async saveReport() {
      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    onClickSwitchTeam() {
      Vue.set(this, 'crewNumber', this.crewNumber === '1' ? '2' : '1')
    },
    isCrew1() {
      return this.crewNumber === '1'
    },
    isCrew2() {
      return !this.isCrew1()
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
