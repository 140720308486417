import axios from 'axios'
import * as utils from './apiUtils'

export default {
  getAll() {
    return axios.get(utils.getApiUrl('/masters'))
  },
  create({ lovName, lovKey, data }) {
    return axios.post(utils.getApiUrl(`/masters/${lovName}/${lovKey}`), data)
  },
  update({ lovName, lovKey, data }) {
    return axios.put(utils.getApiUrl(`/masters/${lovName}/${lovKey}`), data)
  },
  delete({ lovName, lovKey }) {
    return axios.delete(utils.getApiUrl(`/masters/${lovName}/${lovKey}`))
  },
  getPhoto({ lovName, lovKey }) {
    return axios.get(utils.getApiUrl(`/masters/${lovName}/${lovKey}/get_photo`))
  },
  getLovPhoto({ lovName, lovVal, dt }) {
    return axios.get(utils.getApiUrl(`/masters/${lovName}/${lovVal}/get_lov_photo`), {
      params: { dt }
    })
  },
  addPhoto({ lovName, lovKey, formData }) {
    return axios.post(utils.getApiUrl(`/masters/${lovName}/${lovKey}/add_photo`), formData)
  },
  deletePhoto({ lovName, lovKey }) {
    return axios.delete(utils.getApiUrl(`/masters/${lovName}/${lovKey}/delete_photo`))
  },
  downloadCSV({ lovName, data }) {
    return axios.get(`/masters/${lovName}/download_csv.csv`, {
      params: data,
      responseType: 'blob',
    })
  },
}
