<template src="./template.html"></template>

<script>
import PRT from '@/consts/imprint'
import securityChecklistApi from '@/api/securityChecklist'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import securityChecklistMixin from '@/mixin/securityChecklistMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'printSecurityChecklist',
  props: ['id', 'crewNumber'],
  data() {
    return {
      reports: [],
      report_header: {},
      beforeInspectionChecklistItemCountMax: 10,
      beforeInspectionChecklistItems: [],
      beforeInspectionChecklistItemMap: {},
      duringInspectionChecklistItemCountMax: 3,
      duringInspectionChecklistItems: [],
      duringInspectionChecklistItemMap: {},
      precautionItemCountMax: 2,
      precautionItems: [],
      emptyItemCountMax: 12,
      emptyItems: [],

      isImprint: false,
      managerInspectorCivilImprint: null,
      inspectorImprint1: null,
      driverImprint1: null,
    }
  },
  computed: {
    dtDisp() {
      return this.reports[0].report_header.dt
    },
    beforeInspectionChecklistItemRows() {
      return this.getChecklistItemsByChecklistInfos(
        this.reports[0][`before_inspection_checklist_infos${this.crewNumber}`],
        this.beforeInspectionChecklistItemMap,
        this.beforeInspectionChecklistItemCountMax
      )
    },
    duringInspectionChecklistItemRows() {
      return this.getChecklistItemsByChecklistInfos(
        this.reports[0][`during_inspection_checklist_infos${this.crewNumber}`],
        this.duringInspectionChecklistItemMap,
        this.duringInspectionChecklistItemCountMax
      )
    },
    precautionItemRows() {
      return this.precautionItems
    },
    emptyItemRows() {
      return this.emptyItems
    },
    hasInputTeam2() {
      return !!this.reports[0].report_header.inspector2
    },
    weatherDisp() {
      return this.reports[0][`weather${this.crewNumber}`]
    },
    inspectorDisp() {
      return this.reports[0].report_header[`inspector${this.crewNumber}`]
    },
    driverDisp() {
      return this.reports[0].report_header[`driver${this.crewNumber}`]
    },
    workStartDisp() {
      return this.reports[0].report_header[`work_start${this.crewNumber}`]
    },
    workEndDisp() {
      return this.reports[0].report_header[`work_end${this.crewNumber}`]
    },
  },
  mounted() {
    this.getMasters()
    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_SECURITYCHECKLIST
  },
  mixins: [masterMixin, redDetailPageMixin, securityChecklistMixin, imprintPhotoMixin, errorHandleMixin],
  methods: {
    getMasters() {
      // nothing to do
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.beforeInspectionChecklistItems =
          lovs.security_checklist_before_inspection_checklist_item.vals_inuse
        this.beforeInspectionChecklistItemMap =
          lovs.security_checklist_before_inspection_checklist_item.map
        this.duringInspectionChecklistItems =
          lovs.security_checklist_during_inspection_checklist_item.vals_inuse
        this.duringInspectionChecklistItemMap =
          lovs.security_checklist_during_inspection_checklist_item.map
      })
    },
    getReport_() {
      const obj = {id: this.id}
      securityChecklistApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.prepareDispArrays()

          if (!data.before_inspection_checklist_infos1) {
            this.setChecklistInfos(data, 'before_inspection_checklist_infos1', this.beforeInspectionChecklistItems)
          }
          if (!data.before_inspection_checklist_infos2) {
            this.setChecklistInfos(data, 'before_inspection_checklist_infos2', this.beforeInspectionChecklistItems)
          }
          if (!data.during_inspection_checklist_infos1) {
            this.setChecklistInfos(data, 'during_inspection_checklist_infos1', this.duringInspectionChecklistItems)
          }
          if (!data.during_inspection_checklist_infos2) {
            this.setChecklistInfos(data, 'during_inspection_checklist_infos2', this.duringInspectionChecklistItems)
          }

          this.reports = [data]

          const promises = []
          const rh = data.report_header
          promises.push(this.getImprintPhoto('manager_inspector_civil', rh.manager_inspector_civil, rh.dt))
          promises.push(this.getImprintPhoto('inspector', rh[`inspector${this.crewNumber}`], rh.dt))
          promises.push(this.getImprintPhoto('driver', rh[`driver${this.crewNumber}`], rh.dt))
          Promise.all(promises).then((results) => {
            this.managerInspectorCivilImprint = results[0]
            this.inspectorImprint1 = results[1]
            this.driverImprint1 = results[2]
          }).catch((error) => {
            this.handleErrorResponse(error)
          })
        })
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
