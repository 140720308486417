<template>
<div class="work-time-input ta-left">
  <div class="ib block-left">
    <div>
      <span class="lbl ib mb-8">総括調査員作業時間記入欄</span>
      <div class="mb-4">
        <div class="ib lbl2 ta-right">
          高速上
        </div>
        <div class="ib">
          <time-input :value="mgr_inspector_outside_work1_from_h" mode="hour"
            class="ml-8"
            :class="{'is-error': this.errors.mgr_inspector_outside_work1_from_h}"
            @input="onInput('mgr_inspector_outside_work1_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_outside_work1_from_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_outside_work1_from_m}"
            @input="onInput('mgr_inspector_outside_work1_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="mgr_inspector_outside_work1_to_h" mode="hour"
            :class="{'is-error': this.errors.mgr_inspector_outside_work1_to_h}"
            @input="onInput('mgr_inspector_outside_work1_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_outside_work1_to_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_outside_work1_to_m}"
            @input="onInput('mgr_inspector_outside_work1_to', 'm', $event)">
          </time-input>
        </div>
      </div>
      <div>
        <div class="ib lbl2 ta-right">
          高速外
        </div>
        <div class="ib">
          <time-input :value="mgr_inspector_outside_work2_from_h" mode="hour"
            class="ml-8"
            :class="{'is-error': this.errors.mgr_inspector_outside_work2_from_h}"
            @input="onInput('mgr_inspector_outside_work2_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_outside_work2_from_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_outside_work2_from_m}"
            @input="onInput('mgr_inspector_outside_work2_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="mgr_inspector_outside_work2_to_h" mode="hour"
            :class="{'is-error': this.errors.mgr_inspector_outside_work2_to_h}"
            @input="onInput('mgr_inspector_outside_work2_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_outside_work2_to_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_outside_work2_to_m}"
            @input="onInput('mgr_inspector_outside_work2_to', 'm', $event)">
          </time-input>
        </div>
      </div>
    </div>
    <div>
      <div class="lbl ib mt-12 mb-4">
        <span class=" mr-8">総括調査員報告書作成時間記入欄</span>
        <button class="btn btn-sm btn-default"
            @click="$emit('update-mgr-inspector-inside-work-time')">
          自動入力
        </button>
      </div>
      <div>
        <div class="ib lbl2 ta-right">
        </div>
        <div class="ib">
          <time-input :value="mgr_inspector_inside_work1_from_h" mode="hour"
            class="ml-8"
            :class="{'is-error': this.errors.mgr_inspector_inside_work1_from_h}"
            @input="onInput('mgr_inspector_inside_work1_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_inside_work1_from_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_inside_work1_from_m}"
            @input="onInput('mgr_inspector_inside_work1_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="mgr_inspector_inside_work1_to_h" mode="hour"
            :class="{'is-error': this.errors.mgr_inspector_inside_work1_to_h}"
            @input="onInput('mgr_inspector_inside_work1_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="mgr_inspector_inside_work1_to_m" mode="minute"
            :class="{'is-error': this.errors.mgr_inspector_inside_work1_to_m}"
            @input="onInput('mgr_inspector_inside_work1_to', 'm', $event)">
          </time-input>
        </div>
      </div>
    </div>
  </div>
  <div class="ib block-right">
    <div>
      <span class="lbl ib mb-8">点検調査員作業時間記入欄</span>
      <div class="mb-4">
        <div class="ib lbl2 ta-right">
          高速上
        </div>
        <div class="ib">
          <time-input :value="inspector_outside_work1_from_h" mode="hour"
            class="ml-8" :readonly="is_inspector_outside_work_ro"
            :class="{'is-error': this.errors.inspector_outside_work1_from_h}"
            @input="onInput('inspector_outside_work1_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_outside_work1_from_m" mode="minute"
            :class="{'is-error': this.errors.inspector_outside_work1_from_m}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work1_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="inspector_outside_work1_to_h" mode="hour"
            :class="{'is-error': this.errors.inspector_outside_work1_to_h}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work1_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_outside_work1_to_m" mode="minute"
            :class="{'is-error': this.errors.inspector_outside_work1_to_m}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work1_to', 'm', $event)">
          </time-input>
        </div>
      </div>
      <div>
        <div class="ib lbl2 ta-right">
          高速外
        </div>
        <div class="ib">
          <time-input :value="inspector_outside_work2_from_h" mode="hour"
            class="ml-8" :readonly="is_inspector_outside_work_ro"
            :class="{'is-error': this.errors.inspector_outside_work2_from_h}"
            @input="onInput('inspector_outside_work2_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_outside_work2_from_m" mode="minute"
            :class="{'is-error': this.errors.inspector_outside_work2_from_m}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work2_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="inspector_outside_work2_to_h" mode="hour"
            :class="{'is-error': this.errors.inspector_outside_work2_to_h}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work2_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_outside_work2_to_m" mode="minute"
            :class="{'is-error': this.errors.inspector_outside_work2_to_m}"
            :readonly="is_inspector_outside_work_ro"
            @input="onInput('inspector_outside_work2_to', 'm', $event)">
          </time-input>
        </div>
      </div>
    </div>
    <div>
      <div class="lbl ib mt-12 mb-4">
        <span class=" mr-8">点検調査員報告書作成時間記入欄</span>
        <button class="btn btn-sm btn-default"
            @click="$emit('update-inspector-inside-work-time')">
          自動入力
        </button>
      </div>
      <div>
        <div class="ib lbl2 ta-right">
        </div>
        <div class="ib">
          <time-input :value="inspector_inside_work1_from_h" mode="hour"
            class="ml-8"
            :class="{'is-error': this.errors.inspector_inside_work1_from_h}"
            @input="onInput('inspector_inside_work1_from', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_inside_work1_from_m" mode="minute"
            :class="{'is-error': this.errors.inspector_inside_work1_from_m}"
            @input="onInput('inspector_inside_work1_from', 'm', $event)">
          </time-input>

          <span class="tilda ib ml-12 mr-12">〜</span>

          <time-input :value="inspector_inside_work1_to_h" mode="hour"
            :class="{'is-error': this.errors.inspector_inside_work1_to_h}"
            @input="onInput('inspector_inside_work1_to', 'h', $event)">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input :value="inspector_inside_work1_to_m" mode="minute"
            :class="{'is-error': this.errors.inspector_inside_work1_to_m}"
            @input="onInput('inspector_inside_work1_to', 'm', $event)">
          </time-input>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import TimeInput from '@/components/lib/TimeInput'
export default {
  name: 'work-time-input',
  props: {
    mgr_inspector_outside_work1_from: {
      type: Number,
      default: null,
    },
    mgr_inspector_outside_work1_to: {
      type: Number,
      default: null,
    },
    mgr_inspector_outside_work2_from: {
      type: Number,
      default: null,
    },
    mgr_inspector_outside_work2_to: {
      type: Number,
      default: null,
    },
    mgr_inspector_inside_work1_from: {
      type: Number,
      default: null,
    },
    mgr_inspector_inside_work1_to: {
      type: Number,
      default: null,
    },
    inspector_outside_work1_from: {
      type: Number,
      default: null,
    },
    inspector_outside_work1_to: {
      type: Number,
      default: null,
    },
    inspector_outside_work2_from: {
      type: Number,
      default: null,
    },
    inspector_outside_work2_to: {
      type: Number,
      default: null,
    },
    inspector_inside_work1_from: {
      type: Number,
      default: null,
    },
    inspector_inside_work1_to: {
      type: Number,
      default: null,
    },
    is_inspector_outside_work_ro: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      errors: {},
    }
  },
  computed: {
    mgr_inspector_outside_work1_from_h() {
      if (this.mgr_inspector_outside_work1_from === null) { return null }
      return parseInt(this.mgr_inspector_outside_work1_from / 100)
    },
    mgr_inspector_outside_work1_from_m() {
      if (this.mgr_inspector_outside_work1_from === null) { return null }
      return this.mgr_inspector_outside_work1_from % 100
    },
    mgr_inspector_outside_work1_to_h() {
      if (this.mgr_inspector_outside_work1_to === null) { return null }
      return parseInt(this.mgr_inspector_outside_work1_to / 100)
    },
    mgr_inspector_outside_work1_to_m() {
      if (this.mgr_inspector_outside_work1_to === null) { return null }
      return this.mgr_inspector_outside_work1_to % 100
    },
    mgr_inspector_outside_work2_from_h() {
      if (this.mgr_inspector_outside_work2_from === null) { return null }
      return parseInt(this.mgr_inspector_outside_work2_from / 100)
    },
    mgr_inspector_outside_work2_from_m() {
      if (this.mgr_inspector_outside_work2_from === null) { return null }
      return this.mgr_inspector_outside_work2_from % 100
    },
    mgr_inspector_outside_work2_to_h() {
      if (this.mgr_inspector_outside_work2_to === null) { return null }
      return parseInt(this.mgr_inspector_outside_work2_to / 100)
    },
    mgr_inspector_outside_work2_to_m() {
      if (this.mgr_inspector_outside_work2_to === null) { return null }
      return this.mgr_inspector_outside_work2_to % 100
    },
    mgr_inspector_inside_work1_from_h() {
      if (this.mgr_inspector_inside_work1_from === null) { return null }
      return parseInt(this.mgr_inspector_inside_work1_from / 100)
    },
    mgr_inspector_inside_work1_from_m() {
      if (this.mgr_inspector_inside_work1_from === null) { return null }
      return this.mgr_inspector_inside_work1_from % 100
    },
    mgr_inspector_inside_work1_to_h() {
      if (this.mgr_inspector_inside_work1_to === null) { return null }
      return parseInt(this.mgr_inspector_inside_work1_to / 100)
    },
    mgr_inspector_inside_work1_to_m() {
      if (this.mgr_inspector_inside_work1_to === null) { return null }
      return this.mgr_inspector_inside_work1_to % 100
    },
    inspector_outside_work1_from_h() {
      if (this.inspector_outside_work1_from === null) { return null }
      return parseInt(this.inspector_outside_work1_from / 100)
    },
    inspector_outside_work1_from_m() {
      if (this.inspector_outside_work1_from === null) { return null }
      return this.inspector_outside_work1_from % 100
    },
    inspector_outside_work1_to_h() {
      if (this.inspector_outside_work1_to === null) { return null }
      return parseInt(this.inspector_outside_work1_to / 100)
    },
    inspector_outside_work1_to_m() {
      if (this.inspector_outside_work1_to === null) { return null }
      return this.inspector_outside_work1_to % 100
    },
    inspector_outside_work2_from_h() {
      if (this.inspector_outside_work2_from === null) { return null }
      return parseInt(this.inspector_outside_work2_from / 100)
    },
    inspector_outside_work2_from_m() {
      if (this.inspector_outside_work2_from === null) { return null }
      return this.inspector_outside_work2_from % 100
    },
    inspector_outside_work2_to_h() {
      if (this.inspector_outside_work2_to === null) { return null }
      return parseInt(this.inspector_outside_work2_to / 100)
    },
    inspector_outside_work2_to_m() {
      if (this.inspector_outside_work2_to === null) { return null }
      return this.inspector_outside_work2_to % 100
    },
    inspector_inside_work1_from_h() {
      if (this.inspector_inside_work1_from === null) { return null }
      return parseInt(this.inspector_inside_work1_from / 100)
    },
    inspector_inside_work1_from_m() {
      if (this.inspector_inside_work1_from === null) { return null }
      return this.inspector_inside_work1_from % 100
    },
    inspector_inside_work1_to_h() {
      if (this.inspector_inside_work1_to === null) { return null }
      return parseInt(this.inspector_inside_work1_to / 100)
    },
    inspector_inside_work1_to_m() {
      if (this.inspector_inside_work1_to === null) { return null }
      return this.inspector_inside_work1_to % 100
    },
  },
  methods: {
    onInput(prop, subProp, arg) {
      let val = arg
      if (val instanceof Event) {
        val = arg.target.value
      }

      const valMin = 0
      const valMax = subProp === 'h' ? 23 : 59
      if (val === '' || isNaN(val) || val < valMin || val > valMax) {
        const obj = { prop: prop, val: null }
        this.$emit('input', obj)
        return
      }

      let hVal = 0
      let mVal = 0
      if (subProp === 'h') {
        hVal = parseInt(val)
        mVal = this[`${prop}_m`] === null ? 0 : this[`${prop}_m`]
      } else {
        hVal = this[`${prop}_h`] === null ? 0 : this[`${prop}_h`]
        mVal = parseInt(val)
      }

      const emitVal = hVal * 100 + mVal
      const obj = { prop: prop, val: emitVal }
      this.$emit('input', obj)
    },
  },
  components: { TimeInput }
}
</script>

<style lang="scss" scoped>
.work-time-input {
  width: 210mm;
  background-color: #ffffff;
  padding: 12px 16px;
  border: 1px solid #dddddd;
}
.block-left, .block-right {
  width: 100mm;
}
.lbl {
  font-size: 16px;
}
.lbl2 {
  font-size: 16px;
  width: 70px;
}
.form-control {
  font-size: 17px;
}
</style>
