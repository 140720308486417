const STRM_CHAIN_START = 'STRM_CHAIN_START'
const STRM_CHAIN_MIDDLE = 'STRM_CHAIN_MIDDLE'
const STRM_CHAIN_END = 'STRM_CHAIN_END'

class StreamChain {
  constructor(eventNames, first) {
    if (!(eventNames instanceof Array)) {
      eventNames = [eventNames]
    }
    this.eventNames = eventNames
    this.chain = [{type: STRM_CHAIN_START, strm: first}]
  }

  to(strm) {
    this.chain.push({type: STRM_CHAIN_MIDDLE, strm: strm})
    return this
  }

  end() {
    if (this.chain.length < 1) { return null }
    if (this.chain.length < 2) { return this.chain[0].strm }
    // 最後のエントリのタイプを変更
    this.chain[this.chain.length - 1].type = STRM_CHAIN_END
    this.constructChain()
    return this.chain[0].strm
  }

  constructChain() {
    this.eventNames.forEach(eventName => {
      const arr = this.chain.slice()
      let prev
      arr.forEach(e => {
        const type = e.type
        const strm = e.strm
        if (type === STRM_CHAIN_START) {
          // nothing to do
        } else if (type === STRM_CHAIN_MIDDLE) {
          prev.strm.ensureObservable('out', eventName).subscribe({
            next: v => {
              // 自分に入ってくるやつ
              strm.log_(`Stream chain in evt=${eventName}, strm=${strm.name}`)
              strm.ensureObservable('in', eventName).next(v)
              // 自分から出すやつ
              strm.connectInOut(eventName)
            }
          })
        } else if (type === STRM_CHAIN_END) {
          prev.strm.ensureObservable('out', eventName).subscribe({
            next: v => {
              // 自分に入ってくるやつ
              strm.log_(`Stream chain in evt=${eventName}, strm=${strm.name}`)
              strm.ensureObservable('in', eventName).next(v)
            }
          })
        }
        prev = e
      })
    })
  }
}

export default class Stream {
  constructor(opts = {}) {
    this.outObservables = {}
    this.inObservables = {}
    this.debug = Object.prototype.hasOwnProperty.call(opts, 'debug') ? opts.debug : false
    this.name = opts.name || ''
  }

  log_(msg) {
    if (!this.debug) { return }
    console.log(msg)
  }

  ensureObservable(type, eventName) {
    const obMap = this[`${type}Observables`]
    if (!obMap[eventName]) {
      // subscribeされると、直近1つまでの値をemitしてくれる.
      obMap[eventName] = new Rx.ReplaySubject(1)
    }
    return obMap[eventName]
  }

  send(eventName, obj) {
    this.log_(`Stream send evt=${eventName} from strm=${this.name}`)
    const observable = this.ensureObservable('out', eventName)
    observable.next(obj)
  }

  recv(eventName, func) {
    const observable = this.ensureObservable('in', eventName)
    observable.subscribe({
      next: v => {
        this.log_(`Stream recv evt=${eventName} to strm=${this.name}`)
        func(v)
      }
    })
  }

  chain(eventNames) {
    return new StreamChain(eventNames, this)
  }

  connectInOut(eventName) {
    this.ensureObservable('in', eventName).subscribe({
      next: v => {
        this.log_(`Stream in->out evt=${eventName}, strm=${this.name}`)
        this.ensureObservable('out', eventName).next(v)
      }
    })
  }

  spread(eventNames, streams, opts = {}) {
    if (!(eventNames instanceof Array)) {
      eventNames = [eventNames]
    }
    if (!(streams instanceof Array)) {
      streams = [streams]
    }
    let mapper = opts.mapper
    if (!mapper) { mapper = a => a }
    eventNames.forEach(eventName => {
      this.connectInOut(eventName)
      const subject = new Rx.Subject().map(mapper)
      this.ensureObservable('out', eventName).subscribe(subject)
      streams.forEach(strm => {
        subject.subscribe({
          next: v => {
            this.log_(`Stream spread evt=${eventName}, from=${this.name}, to=${strm.name}`)
            strm.ensureObservable('in', eventName).next(v)
          }
        })
      })
    })
    // spreadしたあとは大抵一旦仕切り直し.
    // とりまthisでも返しておこう.
    return this
  }

  drain(eventNames, streams, opts = {}) {
    if (!(eventNames instanceof Array)) {
      eventNames = [eventNames]
    }
    if (!(streams instanceof Array)) {
      streams = [streams]
    }
    let mapper = opts.mapper
    if (!mapper) { mapper = a => a }
    eventNames.forEach(eventName => {
      streams.forEach(strm => {
        strm.ensureObservable('out', eventName).map(mapper).subscribe({
          next: v => {
            this.log_(`Stream drain evt=${eventName}, from=${strm.name}, to=${this.name}`)
            this.ensureObservable('in', eventName).next(v)
          }
        })
      })
      this.connectInOut(eventName)
    })
    // drainしたあとは大抵chainしたい
    return this.chain(eventNames)
  }
}
