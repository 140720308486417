<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import roadRegulationReportApi from '@/api/roadRegulationReport'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'roadRegulationList',
  data() {
    return {
      search: {
        dtFrom: null,
        dtTo: null,
      },
      hasInputError: false,

      list: [],
      listItemsPerPage: 20,
      currentPage: 1,
      isSearchResultEmpty: false,

      reportToDelete: null,
    }
  },
  computed: {
    ...mapState('user', {
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_tenken: state => state.has_role_tenken,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  async mounted() {
    const { user } = await this.ensureUserAndMasters()
    const { dtStrFrom, dtStrTo } = this.getListInitialSearchParams(user.env)
    this.search.dtFrom = dtStrFrom
    this.search.dtTo = dtStrTo
    this._doSearch()
  },
  mixins: [masterMixin, dateMixin, listPageMixin, errorHandleMixin],
  methods: {
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dtFrom)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dtTo)) {
        this.hasInputError = true
      }
      const diffSec = new Date(this.search.dtTo) - new Date(this.search.dtFrom)
      if (diffSec > 2 * 365 * 86400 * 1000) {
        this.hasInputError = true
      }
    },
    doSearch() {
      if (this.hasInputError) { return }

      this.pushQueryParamsToHistory({
        dtStrFrom: this.search.dtFrom,
        dtStrTo: this.search.dtTo,
      })
      this._doSearch()
    },
    _doSearch() {
      this.isSearchResultEmpty = false
      this.list = []
      this.currentPage = 1

      const obj = {
        from: new Date(this.search.dtFrom),
        to: new Date(this.search.dtTo)
      }
      roadRegulationReportApi.index(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            this.isSearchResultEmpty = true
            return
          }
          this.list = data
        })
    },
    async doCSVDownload() {
      try {
        const reqObj = {
          data: {
            from: new Date(this.search.dtFrom),
            to: new Date(this.search.dtTo)
          }
        }
        const { data } = await roadRegulationReportApi.downloadCSV(reqObj)
        const timestamp = Vue.filter('dtFormat')(new Date(), 'yyyymmddHHMMSS')
        const filename = `保安規制実施状況報告書一覧_${timestamp}.csv`
        this.downloadBlobAsFile(data, filename)
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    downloadBlobAsFile(blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    },
    confirmDeleteReport(e) {
      this.reportToDelete = e
    },
    deleteReport() {
      const obj = {
        id: this.reportToDelete.id,
      }
      roadRegulationReportApi.delete(obj)
        .then(() => {
          this.reportToDelete = null
          this._doSearch()
        })
    },
    reportNumPaddedDisp,
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
