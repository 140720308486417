import axios from 'axios'
import * as utils from './apiUtils'

export default {
  getAll() {
    return axios.get(utils.getApiUrl('/damage_type_templates'))
  },
  create({ data }) {
    return axios.post(utils.getApiUrl('/damage_type_templates'), data)
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/damage_type_templates/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/damage_type_templates/${id}`))
  },
  downloadCSV() {
    return axios.get('/damage_type_templates/download_csv.csv', {
      responseType: 'blob',
    })
  },
}
