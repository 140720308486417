var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-header-bar ta-center",class:{
      'top-bar': _vm.isHeaderMode,
      'bottom-bar': _vm.isFooterMode,
    }},[(_vm.isFooterMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-bottom ta-left"},_vm._l((_vm.errorMessages),function(msg){return _c('div',[_vm._v("・"+_vm._s(msg))])}),0):_vm._e(),_c('div',{staticClass:"bar-row"},[_c('button',{staticClass:"btn btn-lg btn-default top-left",on:{"click":_vm.onClickShowReportHeader}},[_vm._v(" 勤務情報 ")]),_c('div',[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 戻る ")]),(!_vm.isNew)?_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":_vm.onClickShowPrintPage}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" 印刷 ")]):_vm._e(),(_vm.isNew)?_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickCreate}},[_vm._v(" 作成 ")]):_vm._e(),(!_vm.isNew)?_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickSave}},[_vm._v(" 保存 ")]):_vm._e()]),(!_vm.isNew)?_c('router-link',{staticClass:"btn btn-lg btn-default top-right",attrs:{"to":{
      name: _vm.photoPageRouteName,
      params: {id: _vm.id},
    }}},[_c('i',{staticClass:"fa fa-photo"}),_vm._v(" 写真一覧 ")]):_vm._e(),(_vm.isCopy)?_c('div',{staticClass:"mode-copy"},[_vm._v("コピー")]):_vm._e()],1),(_vm.isHeaderMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-top ta-left"},_vm._l((_vm.errorMessages),function(msg){return _c('div',[_vm._v("・"+_vm._s(msg))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }