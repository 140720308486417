export default {
  methods: {
    isDateString(str) {
      return !isNaN(new Date(str))
    },
    // Dateが開庁日(且つ開庁時刻内)かどうか判定する
    isKaichou(dateObj) {
      // 元のロジック. 祝日対応できてないからということで、全部当日になるよう修正した.
      // const month = dateObj.getMonth() + 1
      // const date = dateObj.getDate()
      // const youbi = dateObj.getDay()
      // const hour = dateObj.getHours()
      // // 土日(6,0)は閉庁
      // if (youbi === 0 || youbi === 6) { return false }
      // // 12/29-31, 1/1-3は閉庁
      // if (month === 12 && 29 <= date && date <= 31) { return false }
      // if (month === 1 && 1 <= date && date <= 3) { return false }
      // // 平日17:00以降は閉庁
      // if (hour >= 17) { return false }
      // // それ以外は開庁
      // return true

      // https://land-japan.slack.com/archives/G01ESRVDZLN/p1716340138108279?thread_ts=1715074433.984609&cid=G01ESRVDZLN
      return true
    },
    // 次の開庁日を取得する (時刻部分は見ない)
    getNextKaichoubi(dateObj) {
      let currentDateObj = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        0, 0, 0, 0)
      do {
        currentDateObj = new Date(currentDateObj.valueOf() + 86400 * 1000)
      } while (!this.isKaichou(currentDateObj))
      return currentDateObj
    },
    getNearestReportDt(dateObj) {
      if (this.isKaichou(dateObj)) { return dateObj }
      return this.getNextKaichoubi(dateObj)
    },
    timeIntegerToDate(timeInt, dateObj, timeslot) {
      if (
        timeInt === null ||
        timeInt === undefined ||
        isNaN(timeInt)
      ) { return null }

      const dt = new Date(dateObj)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      // 夜間勤務の場合は元々17時以降(17:00~23:59)を当日扱い、それより前(00:00~16:59)を翌日扱いとしていた.
      // しかし実際には勤務時間より(1, 2時間程度)前に出動することや残業で勤務時間を超えることもあるため、
      // 15時以降(15:00~23:59)を当日扱い、それより前(00:00~14:59)を翌日扱いとすることにした.
      const dtD = timeslot === '2' && timeInt < 1500 ? (dt.getDate() + 1) : dt.getDate()

      const tmp = parseInt(timeInt)
      const dtHour = parseInt(timeInt / 100)
      const dtMinute = tmp % 100
      return new Date(dtY, dtM, dtD, dtHour, dtMinute, 0)
    },
  }
}
