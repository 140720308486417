export default {
  methods: {
    // lovに応じた表示列名などの列情報を取得する
    getLovDispColInfo(lovName, lov) {
      const uiSpec = this.getMasterDispEditSpec()[lovName]
      const dispSpec = uiSpec.disp
      const editSpec = uiSpec.edit

      // lovの表示列名
      const props = Object.keys(lov.prop_map).map(k => {
        return {
          key: k,
          disp_name: lov.prop_map[k].disp,
          order: lov.prop_map[k].order,
          disp_spec: dispSpec[k],
          edit_spec: editSpec[k] || {},
          // DB上のカラム名
          orig_prop: `val${lov.prop_map[k].order}`,
        }
      }).sort((a, b) => {
        const v1 = a.order
        const v2 = b.order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
      this.insertDispCols_(lovName, props, { dispSpec, editSpec })
      this.setEditParams(props)
      return props
    },
    insertDispCols_(lovName, arr, { dispSpec, editSpec }) {
      // 表示用に使いたいやつがあれば足す.
      // keyと表示名は常に足す.
      arr.splice(0, 0, {
        key: 'key',
        disp_name: 'ID',
        disp_spec: '',
        edit_spec: {},
        // DB上のカラム名
        orig_prop: 'key',
      })
      arr.splice(1, 0, {
        key: 'disp_order',
        disp_name: '表示順',
        disp_spec: '',
        edit_spec: { type: 'text' },
        // DB上のカラム名
        orig_prop: 'disp_order',
      })
      if (lovName === 'highway_name_dir_rel' || lovName === 'highway_name_dir_rel_facility') {
        const key = 'road_name'
        arr.splice(2, 0, {
          key: key,
          disp_name: '路線名',
          disp_spec: dispSpec[key],
          edit_spec: editSpec[key] || {},
        })
      }
    },
    setEditParams(arr) {
      // 編集用に必要な処理があれば行う
      arr.forEach(e => {
        if (e.edit_spec.type === 'select') {
          e.edit_spec.option_key = e.edit_spec.option_key || 'key'
          e.edit_spec.option_disp = e.edit_spec.option_disp || 'name'
        }
      })
    },
    convLovEntry(lovName, entry) {
      // 元のやつを汚さないよう、コピー.
      const ret = Object.assign({}, entry)
      // insertDispCols_で足したものがあればここで対応
      if (lovName === 'highway_name_dir_rel' || lovName === 'highway_name_dir_rel_facility') {
        ret.road_name = ret.key
      }
      // 日付
      ret.start_use = new Date(ret.start_use)
      ret.end_use = new Date(ret.end_use)
      // 編集モードかどうか
      ret.isEditMode = false
      return ret
    },
    getMasterDispEditSpec() {
      return {
        reportee_dep: {
          disp: {
            area: 'area.name',
            reportee_group: 'reportee_group.disp1',
            reportee_type: 'reportee_type.name',
          },
          edit: {
            name: { type: 'text' },
            area: { type: 'ro', ref_key: 'area_disp' },
            reportee_group: { type: 'ro', ref_key: 'reportee_group_disp' },
            reportee_type: { type: 'ro', ref_key: 'reportee_type_disp' },
          },
        },
        damage_rank: {
          disp: {},
          edit: {},
        },
        highway: {
          disp: {
            reportee_group: 'reportee_group.disp1',
          },
          edit: {
            name: { type: 'text' },
            reportee_group: { type: 'select', lov_name: 'reportee_group', option_disp: 'disp1' },
            sign: { type: 'text' },
            start: { type: 'text' },
            end: { type: 'text' },
          },
        },
        highway_direction_type: {
          disp: {},
          edit: {},
        },
        highway_name_dir_rel: {
          disp: {
            road_name: 'highway.name', // added
            rel1: 'highway_direction_type.name',
            rel2: 'highway_direction_type.name',
            rel3: 'highway_direction_type.name',
            rel4: 'highway_direction_type.name',
            rel5: 'highway_direction_type.name',
            rel6: 'highway_direction_type.name',
          },
          edit: {
            road_name: { type: 'ro', ref_key: 'road_name_disp' },
            rel1: { type: 'select', lov_name: 'highway_direction_type' },
            rel2: { type: 'select', lov_name: 'highway_direction_type' },
            rel3: { type: 'select', lov_name: 'highway_direction_type' },
            rel4: { type: 'select', lov_name: 'highway_direction_type' },
            rel5: { type: 'select', lov_name: 'highway_direction_type' },
            rel6: { type: 'select', lov_name: 'highway_direction_type' },
          },
        },
        highway_facility: {
          disp: {
            reportee_group: 'reportee_group.disp1',
          },
          edit: {
            name: { type: 'text' },
            reportee_group: { type: 'select', lov_name: 'reportee_group', option_disp: 'disp1' },
            sign: { type: 'text' },
            start: { type: 'text' },
            end: { type: 'text' },
          },
        },
        highway_direction_type_facility: {
          disp: {},
          edit: {},
        },
        highway_name_dir_rel_facility: {
          disp: {
            road_name: 'highway_facility.name', // added
            rel1: 'highway_direction_type.name',
            rel2: 'highway_direction_type.name',
            rel3: 'highway_direction_type.name',
            rel4: 'highway_direction_type.name',
            rel5: 'highway_direction_type.name',
            rel6: 'highway_direction_type.name',
          },
          edit: {
            road_name: { type: 'ro', ref_key: 'road_name_disp' },
            rel1: { type: 'select', lov_name: 'highway_direction_type' },
            rel2: { type: 'select', lov_name: 'highway_direction_type' },
            rel3: { type: 'select', lov_name: 'highway_direction_type' },
            rel4: { type: 'select', lov_name: 'highway_direction_type' },
            rel5: { type: 'select', lov_name: 'highway_direction_type' },
            rel6: { type: 'select', lov_name: 'highway_direction_type' },
          },
        },
        highway_lane_type: {
          disp: {},
          edit: {},
          // name:車線区分,disp1:表示用名称1
        },
        highway_lr_type: {
          disp: {},
          edit: {},
          // name:左右区分
        },
        highway_place1_type: {
          disp: {},
          edit: {},
          // name:点検個所タイプ,prefix:接頭記号
        },
        highway_place2_type: {
          disp: {},
          edit: {},
          // name:形状
        },
        highway_place2_text: {
          disp: {
            place2_type: 'highway_place2_type.name',
            hw: 'highway_facility.name',
            dir1: 'highway_direction_type_facility.name',
            dir2: 'highway_direction_type_facility.name',
          },
          edit: {
            place2_type: { type: 'select', lov_name: 'highway_place2_type' },
            name: { type: 'text' },
            hw: { type: 'select', lov_name: 'highway_facility' },
            dir1: { type: 'select', lov_name: 'highway_direction_type' },
            dir2: { type: 'select', lov_name: 'highway_direction_type' },
          },
        },
        request_content_kind: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:履行区分
        },
        manager_inspector_civil: {
          disp: {},
          edit: {
            name: { type: 'text' },
            kana: { type: 'text' },
          },
          // name:氏名,kana:かな
        },
        manager_inspector_facility: {
          disp: {},
          edit: {
            name: { type: 'text' },
            kana: { type: 'text' },
          },
          // name:氏名,kana:かな
        },
        inspector: {
          disp: {},
          edit: {
            name: { type: 'text' },
            kana: { type: 'text' },
          },
          // name:氏名,kana:かな
        },
        driver: {
          disp: {},
          edit: {
            name: { type: 'text' },
            kana: { type: 'text' },
          },
          // name:氏名,kana:かな
        },
        requestor: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:依頼元
        },
        requestor_facility: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:依頼元
        },
        requestor_keishou: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:敬称
        },
        request_content_other1: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:内容（定型文）
        },
        repair_company: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:業者名
        },
        claim_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:苦情区分
        },
        claim_tensoumoto: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:転送元
        },
        construction_name: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:工事件名(保安規制)
        },
        road_regulation_inspect_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:点検方法(保安規制)
        },
        road_regulation_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:規制区分(保安規制)
        },
        road_regulation_on_highway_checklist_item: {
          disp: {},
          edit: {
            type: { type: 'text' },
            text: { type: 'text' },
          },
          // text:チェック項目(高速上)(保安規制)
        },
        road_regulation_off_highway_checklist_item: {
          disp: {},
          edit: {
            type: { type: 'text' },
            text: { type: 'text' },
          },
          // text:チェック項目(街路上)(保安規制)
        },
        road_regulation_contractee: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:受注者(保安規制)
        },
        weather: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:天候
        },
        target_facility: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:機器
        },
        facility_state: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:状態
        },
        recovery_action: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:指示
        },
        repair_company_group: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:出動業者
        },
        repair_company_service_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:出動形態
        },
        person_of_cause: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:原因者
        },
        regulation_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:高速区分
        },
        vehicles_used: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:使用車両
        },
        damage_rank_text: {
          disp: {
            damage_rank: 'damage_rank.name',
          },
          edit: {
            damage_rank: { type: 'select', lov_name: 'damage_rank' },
            text: { type: 'text' },
          },
          // damage_rank:ランク, text:内容
        },
        digest_result: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:結果
        },
        tachograph_article: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:記事
        },
        security_checklist_before_inspection_checklist_item: {
          disp: {},
          edit: {
            text: { type: 'text' },
          },
          // text:チェック項目
        },
        security_checklist_during_inspection_checklist_item: {
          disp: {},
          edit: {
            text: { type: 'text' },
          },
          // text:チェック項目
        },
        cause_of_inspection_time_delay: {
          disp: {},
          edit: {
            text: { type: 'text' },
          },
          // text:出動時間差発生理由
        },
        special_note_on_inspection: {
          disp: {},
          edit: {
            text: { type: 'text' },
          },
          // text:特記事項
        },
      }
    },
  }
}
