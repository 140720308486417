export default {
  methods: {
    getNightShiftDate(timeslot, dt) {
      // 夜間勤務の場合は日を跨ぐ為timeslotを見て夜間なら1日足す
      if (!timeslot || !dt) { return }
      dt = new Date(dt)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      const dtD = timeslot === '2' ? (dt.getDate() + 1) : dt.getDate()
      const dtHour = dt.getHours()
      const dtMinute = dt.getMinutes()
      return new Date(dtY, dtM, dtD, dtHour, dtMinute, 0)
    },
    emergencyContactDefaultHoursDispSelect(reportHeader) {
      const hour = reportHeader.timeslot === '1' ? 9 : 17
      const reportHeaderDt = new Date(reportHeader.dt)
      const dateObj = new Date(
        reportHeaderDt.getFullYear(),
        reportHeaderDt.getMonth(),
        reportHeaderDt.getDate(),
        hour, 0, 0, 0)
      const isKaichou = this.isKaichou(dateObj)
      return `${reportHeader.timeslot}-${isKaichou ? 1 : 0}`
    },
  }
}
