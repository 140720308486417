<template>
<div class="detail-header-bar ta-center"
    :class="{
      'top-bar': isHeaderMode,
      'bottom-bar': isFooterMode,
    }">
  <div v-if="isFooterMode" v-show="hasError"
      class="err-msg err-msg-bottom ta-left">
    <div v-for="msg in errorMessages">・{{ msg }}</div>
  </div>
  <div class="bar-row">
    <button class="btn btn-lg btn-default top-left"
        @click="onClickShowReportHeader">
      勤務情報
    </button>
    <div>
      <button class="btn btn-lg btn-default mr-8"
          @click="$router.go(-1)">
        戻る
      </button>
      <button v-if="!isNew" class="btn btn-lg btn-default mr-8"
          @click="onClickShowPrintPage">
        <i class="fa fa-print"></i>
        印刷
      </button>
      <button class="btn btn-lg btn-primary"
          v-if="isNew" @click="onClickCreate">
        作成
      </button>
      <button class="btn btn-lg btn-primary"
          v-if="!isNew" @click="onClickSave">
        保存
      </button>
    </div>
    <router-link v-if="!isNew" :to="{
      name: photoPageRouteName,
      params: {id: id},
    }" class="btn btn-lg btn-default top-right">
      <i class="fa fa-photo"></i>
      写真一覧
    </router-link>
    <div class="mode-copy" v-if="isCopy">コピー</div>
  </div>
  <div v-if="isHeaderMode" v-show="hasError"
      class="err-msg err-msg-top ta-left">
    <div v-for="msg in errorMessages">・{{ msg }}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'detail-header-bar',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    mode: {
      type: String,
      default: 'header',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default() { return {} }
    },
    printPageRouteName: {
      type: String,
      required: true,
    },
    photoPageRouteName: {
      type: String,
      required: true,
    },
    beforeShowPrintPage: {
      type: Function,
      default: () => Promise.resolve(true),
    },
  },
  computed: {
    isHeaderMode() {
      return this.mode === 'header'
    },
    isFooterMode() {
      return this.mode === 'footer'
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  data() {
    return {}
  },
  methods: {
    onClickShowReportHeader() {
      this.$emit('click-show-report-header')
    },
    onClickCreate() {
      this.$emit('click-create')
    },
    onClickSave() {
      this.$emit('click-save')
    },
    async onClickShowPrintPage() {
      const checkResult = await this.beforeShowPrintPage()
      if (!checkResult) { return }
      const router = this.$router.resolve({
        name: this.printPageRouteName,
        params: { id: this.id },
      })
      window.open(router.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.bar-row {
  position: relative;
}
.top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.err-msg-top {
  padding: 12px 10mm;
  margin: 0 auto;
}
.err-msg-bottom {
  padding: 0 10mm 12px;
  margin: 0 auto;
}
.mode-copy {
  padding: 0.5em 1em;
  margin: 2em 0;
  font-weight: bold;
  color: #c05d62;
  background: #FFF;
  border: solid 3px #c05d62;
  border-radius: 10px;
}
</style>
