<template>
<div id="header"
    class="top-navigation row border-bottom white-bg"
    :class="{'no-header': hideHeader}">
  <nav class="navbar navbar-fixed-top" role="navigation">
    <div class="navbar-header">
      <button aria-controls="navbar" aria-expanded="false"
          class="navbar-toggle collapsed" type="button" @click="toggleNavBar">
        <i class="fa fa-reorder"></i>
      </button>
      <a class="opt-logo1" ui-sref="dashboard"></a>
      <a ui-sref="dashboard" class="navbar-brand" :class="{
          tyo: isAreaTokyo,
          kng: isAreaKanagawa,
        }">
        <span>緊急点検</span><br/>
        <span v-if="isAreaTokyo" class="area-disp">(東京)</span>
        <span v-if="isAreaKanagawa" class="area-disp">(神奈川)</span>
        <span class="env dev" v-if="isDev">dev</span>
        <span class="env stg" v-if="isStg">stg</span>
      </a>
    </div>
    <div class="navbar-collapse collapse" ref="navBarRef">
      <ul class="nav navbar-nav">
        <li class="nav-item" :class="{active: routeName === 'top'}">
          <router-link :to="{name:'top'}" class="page-link">
            トップ
          </router-link>
        </li>

        <template v-if="isPageGroupA">
        <li class="nav-item" :class="{active: isEmergencyInspectPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'emergencyInspectList'}" class="page-link">
            緊急点検
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isComplaintHandlingPageGroup}"
            v-if="hasRoleSoukatsu"
            @click="onClickPageLink">
          <router-link :to="{name:'complaintHandlingList'}" class="page-link">
            苦情処理
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isRoadRegulationPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'roadRegulationList'}" class="page-link">
            保安規制
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isEmergencyContactPageGroup}"
            v-if="hasRoleSoukatsu"
            @click="onClickPageLink">
          <router-link :to="{name:'emergencyContactList'}" class="page-link">
            緊急連絡
          </router-link>
        </li>
        </template>

        <template v-if="isPageGroupB">
        <li class="nav-item" :class="{active: isAllReportsSummaryPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'allReportsSummaryList'}" class="page-link">
            業務日誌
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isWorkResultTablePageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'workResultTableList'}" class="page-link">
            工程表
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isWorkResultTachographPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'workResultTachographList'}" class="page-link">
            タコグラフ
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isEmergencyContactSummaryPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'emergencyContactSummaryList'}" class="page-link">
            施設日誌
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isSecurityChecklistPageGroup}" @click="onClickPageLink">
          <router-link :to="{name:'securityChecklistList'}" class="page-link">
            安全管理
          </router-link>
        </li>
        </template>

        <template v-if="isPageGroupC">
        <li class="nav-item" :class="{active: isRenumberPageGroup}"
            v-if="hasRoleSoukatsu"
            @click="onClickPageLink">
          <router-link :to="{name:'renumberingList'}" class="page-link">
            連番管理
          </router-link>
        </li>
        <li class="nav-item" :class="{active: isMasterPageGroup}"
            v-if="hasRoleAdmin"
            @click="onClickPageLink">
          <router-link :to="{name:'masterList'}" class="page-link">
            マスタ登録
          </router-link>
        </li>
        </template>
      </ul>
      <ul class="nav navbar-top-links navbar-right">
        <li class="no-show-when-small1">
          ユーザー名: <span>{{ username }}</span>
        </li>
        <li class="logout no-show-when-small2">
          <a href="#" @click="logout">
            <i class="fa fa-sign-out"></i> ログアウト
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import routeMixin from '@/mixin/routeMixin'

export default {
  name: 'global-header',
  data() {
    return {}
  },
  computed: {
    ...mapState('user', {
      username: state => state.name,
      role: state => state.role,
      env: state => state.env,
      hasRoleSoukatsu: state => state.has_role_soukatsu,
      hasRoleAdmin: state => state.has_role_admin,
      area: state => state.area,
    }),
    ...mapGetters('user', [
      'isLoggedIn'
    ]),
    isDev() {
      return this.env === 'development'
    },
    isStg() {
      return this.env === 'staging'
    },
    routeName() {
      return this.$route.name
    },
    hideHeader() {
      return !this.isLoggedIn || this.isGuestPage() || this.isPrintPage()
    },
    isAreaTokyo() {
      return this.area === 'TYO'
    },
    isAreaKanagawa() {
      return this.area === 'KNG'
    },
    isEmergencyInspectPageGroup() {
      return [
        'emergencyInspectList',
        'emergencyInspectNew',
        'emergencyInspectCopy',
        'emergencyInspectDetail',
        'emergencyInspectPhotos',
      ].includes(this.$route.name)
    },
    isComplaintHandlingPageGroup() {
      return [
        'complaintHandlingList',
        'complaintHandlingNew',
        'complaintHandlingCopy',
        'complaintHandlingDetail',
        'complaintHandlingPhotos',
      ].includes(this.$route.name)
    },
    isRoadRegulationPageGroup() {
      return [
        'roadRegulationList',
        'roadRegulationNew',
        'roadRegulationCopy',
        'roadRegulationDetail',
        'roadRegulationPhotos',
      ].includes(this.$route.name)
    },
    isEmergencyContactPageGroup() {
      return [
        'emergencyContactList',
        'emergencyContactNew',
        'emergencyContactCopy',
        'emergencyContactDetail',
        'emergencyContactPhotos',
      ].includes(this.$route.name)
    },
    isAllReportsSummaryPageGroup() {
      return [
        'allReportsSummaryList',
        'allReportsSummaryDetail',
      ].includes(this.$route.name)
    },
    isWorkResultTablePageGroup() {
      return [
        'workResultTableList',
        'workResultTableDetail',
      ].includes(this.$route.name)
    },
    isWorkResultTachographPageGroup() {
      return [
        'workResultTachographList',
        'workResultTachographDetail',
      ].includes(this.$route.name)
    },
    isEmergencyContactSummaryPageGroup() {
      return [
        'emergencyContactSummaryList',
        'emergencyContactSummaryDetail',
      ].includes(this.$route.name)
    },
    isSecurityChecklistPageGroup() {
      return [
        'securityChecklistList',
        'securityChecklistDetail',
      ].includes(this.$route.name)
    },
    isMasterPageGroup() {
      return [
        'masterList',
        'masterDetail',
        'damageTypeTemplateDetail',
      ].includes(this.$route.name)
    },
    isRenumberPageGroup() {
      return [
        'renumberingList',
      ].includes(this.$route.name)
    },
    isPageGroupA() {
      return this.isEmergencyInspectPageGroup ||
        this.isComplaintHandlingPageGroup ||
        this.isRoadRegulationPageGroup ||
        this.isEmergencyContactPageGroup
    },
    isPageGroupB() {
      return this.isAllReportsSummaryPageGroup ||
        this.isWorkResultTablePageGroup ||
        this.isWorkResultTachographPageGroup ||
        this.isEmergencyContactSummaryPageGroup ||
        this.isSecurityChecklistPageGroup
    },
    isPageGroupC() {
      return this.isMasterPageGroup ||
        this.isRenumberPageGroup
    }
  },
  mixins: [routeMixin],
  methods: {
    toggleNavBar() {
      // 微妙な実装.
      // だが、画面が広がってるときはimportantがついてる関係で、これはいい感じに画面を狭くした場合にのみ
      // 効く処理となっている.
      // (画面が広い時はリンクはバー内に横に並んでるので、そもそも閉じたりする必要がない
      const currentDisplay = this.$refs.navBarRef.style.display
      this.$refs.navBarRef.style.display = currentDisplay === 'block' ? 'none' : 'block'
    },
    logout() {
      this.$store.dispatch('user/logout')
        .then(() => {
          // want to explicitly reload
          location.href = '/login'
        })
    },
    onClickPageLink() {
      // 閉じる
      this.$refs.navBarRef.style.display = 'none'
    }
  },
}
</script>

<style scoped lang="scss">
.navbar-brand {
  position: relative;
  text-align: center;
  padding: 5px 20px;

  &.tyo {
    background-color: #9cc3e5;
    color: #000000;
    border-bottom: 1px solid #5f9ed5;
  }
  &.kng {
    background-color: #e9c4cc;
    color: #000000;
    border-bottom: 1px solid #d48c9c;
  }

  .area-disp { font-style: italic; }

  .env {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    bottom: 2px;
    right: 2px;
    line-height: 1.3;
    border-radius: 2px;
    padding: 0 1px;
    &.dev {
      background-color: rgba(255, 255, 255, 0.7);
      color: #333333;
    }
    &.stg {
      background-color: rgba(255, 255, 255, 0.7);
      color: #ff8000;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .no-show-when-small1 {
    display: none;
  }
}
@media only screen and (max-width: 970px) {
  .no-show-when-small2 {
    display: none;
  }
}
</style>
