<template>
  <canvas id="canvas">
  </canvas>
</template>

<script>
import { fabric } from '@/lib/extFabric'
import { createDrawer } from '@/lib/fabricDrawer'

export default {
  name: 'fabric-canvas',
  props: {
    sizeInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    photoImageSrc: {
      type: String,
      default: null,
    },
    drawMode: {
      type: Number,
      default: 1,
    },
    drawOptions: {
      type: Object,
      default() {
        return {
          strokeColor: '#000',
          fillColor: null,
          strokeWidth: 4,
          fontSize: 20,
          writingMode: 'horizontal',
          lineType: 'solid',
        }
      }
    },
    brightness: {
      type: Number,
      default: 0.0,
    },
    contrast: {
      type: Number,
      default: 0.0,
    },
  },
  data() {
    return {
      fabricCanvas: null,
      actionHistory: [],
      actionHistoryIdx: -1,
      maxActionHistory: 512,
      revertingActionHistory: null,
      isForcingEndEdit: false,
      DRAW_MODE: {
        NONE: -1,
        FREE: 1,
        LINE: 11,
        ARROW: 12,
        DOUBLE_ARROW: 13,
        RECT: 21,
        ELLIPSE: 32,
        POLYLINE: 33,
        TEXT: 41,
      },
      TYPE_DRAW_MODE: {
        PATH: 1,
        LINE: 11,
        LINEARROW: 12,
        LINEDOUBLEARROW: 13,
        RECT: 21,
        ELLIPSE: 32,
        POLYLINE: 33,
        EXTITEXT: 41,
      },
      DRAW_MODE_TYPE: {
        1: 'path',
        11: 'line',
        12: 'lineArrow',
        13: 'lineDoubleArrow',
        21: 'rect',
        32: 'ellipse',
        33: 'polyline',
        41: 'ext-i-text',
      },

      ACTION: {
        ADD: 1,
        MODIFY: 2,
        REMOVE: 3,
      },
      drawer: null,
      drawingObject: null,
      backgroundImage: null,
    }
  },
  watch: {
    drawMode() {
      this.terminateDrawingPolyline()
      this.updateObjectsSelectable()
      if (!this.isDrawModeNone) {
        this.fabricCanvas.discardActiveObject()
      }
      this.fabricCanvas.isDrawingMode = this.isDrawModeFree
    },
    sizeInfo: {
      handler() {
        this.fabricCanvas.setHeight(this.sizeInfo.height)
        this.fabricCanvas.setWidth(this.sizeInfo.width)
      },
      deep: true,
    },
    drawOptions: {
      handler() {
        this.fabricCanvas.freeDrawingBrush.color = this.drawOptions.strokeColor
        this.fabricCanvas.freeDrawingBrush.width = this.drawOptions.strokeWidth
        this.fabricCanvas.freeDrawingBrush.strokeDashArray = this.strokeDashArray

        const activeObj = this.fabricCanvas.getActiveObject()
        if (!activeObj) { return }
        let shouldUpdate = false
        Object.entries(this.drawOptions).forEach(([optionName, optionValue]) => {
          const objPropName = this.getObjectPropName(activeObj, optionName)
          const newValue = objPropName === 'strokeDashArray' && optionValue === 'dashed'
            ? [10, 5]
            : objPropName === 'strokeDashArray' && optionValue === 'solid' ? [] : optionValue
          if (objPropName === null || activeObj[objPropName] === newValue ||
            (objPropName === 'strokeDashArray' && activeObj[objPropName].length === newValue.length)
          ) { return }
          if (activeObj.type === 'ext-i-text' && activeObj.getSelectedText().length > 0 &&
            (objPropName === 'fill' || objPropName === 'fontSize')
          ) {
            activeObj.setSelectionStyles({
              [objPropName]: newValue
            })
          } else if (activeObj.type === 'ext-i-text') {
            activeObj.removeStyle(objPropName)
            activeObj.set({
              [objPropName]: newValue
            })
            if (objPropName === 'writingMode') {
              activeObj.set({
                angle: newValue === 'vertical' ? 90 : 0
              })
            }
          } else {
            activeObj.set({
              [objPropName]: newValue
            })
          }
          shouldUpdate = true
        })

        if (!shouldUpdate) { return }
        this.fabricCanvas.requestRenderAll()
        this.fabricCanvas.fire('object:modified', { target: activeObj })
      },
      deep: true,
    },
    photoImageSrc() {
      fabric.Image.fromURL(this.photoImageSrc, img => {
        this.backgroundImage = img
        img.scaleToHeight(this.sizeInfo.height)
        img.scaleToWidth(this.sizeInfo.width)
        img.filters.push(
          new fabric.Image.filters.Brightness({ brightness: this.brightness })
        )
        img.filters.push(
          new fabric.Image.filters.Contrast({ contrast: this.contrast })
        )
        if (fabric.isWebglSupported()) {
          // 画面がカットされるため、textureSize(2048)を大きくするが、大きすぎると計算に時間がかかる
          fabric.textureSize = fabric.maxTextureSize > 4096 ? 4096 : fabric.maxTextureSize
        }
        img.applyFilters()
        this.fabricCanvas.setBackgroundImage(
          img,
          this.fabricCanvas.renderAll.bind(this.fabricCanvas)
        )
      })
    },
    brightness() {
      this.updateColorCorrection('brightness', this.brightness)
    },
    contrast() {
      this.updateColorCorrection('contrast', this.contrast)
    },
  },
  mounted() {
    this.fabricCanvas = new fabric.Canvas('canvas', { selection: false })
    this.fabricCanvas.isDrawingMode = true
    this.fabricCanvas.freeDrawingBrush.color = this.drawOptions.strokeColor
    this.fabricCanvas.freeDrawingBrush.width = this.drawOptions.strokeWidth
    this.fabricCanvas.freeDrawingBrush.strokeDashArray = this.strokeDashArray
    this.fabricCanvas.on('mouse:down', this.onMouseDown)
    this.fabricCanvas.on('mouse:move', this.onMouseMove)
    this.fabricCanvas.on('mouse:up', this.onMouseUp)
    this.fabricCanvas.on('object:added', this.onObjectAdded)
    this.fabricCanvas.on('object:modified', this.onObjectModified)
    this.fabricCanvas.on('object:removed', this.onObjectRemoved)
    this.fabricCanvas.on('selection:created', this.onObjectSelected)
    this.fabricCanvas.on('selection:updated', this.onObjectSelected)
    this.fabricCanvas.on('selection:cleared', this.onObjectDeselected)
    fabric.Object.prototype.cornerSize = 10
    fabric.Object.prototype.transparentCorners = false
    // キャッシングすると描画中一部表示切れが発生するためfalseにする
    fabric.Object.prototype.objectCaching = false
    // リサイズの際に、線の太さは変更しないため
    fabric.Object.prototype.strokeUniform = true
  },
  computed: {
    isDrawModeNone() {
      return this.drawMode === this.DRAW_MODE.NONE
    },
    isDrawModeFree() {
      return this.drawMode === this.DRAW_MODE.FREE
    },
    isDrawModeLine() {
      return this.drawMode === this.DRAW_MODE.LINE
    },
    isDrawModeArrow() {
      return this.drawMode === this.DRAW_MODE.ARROW
    },
    isDrawModeDoubleArrow() {
      return this.drawMode === this.DRAW_MODE.DOUBLE_ARROW
    },
    isDrawModeRect() {
      return this.drawMode === this.DRAW_MODE.RECT
    },
    isDrawModeEllipse() {
      return this.drawMode === this.DRAW_MODE.ELLIPSE
    },
    isDrawModePolyline() {
      return this.drawMode === this.DRAW_MODE.POLYLINE
    },
    isDrawModeText() {
      return this.drawMode === this.DRAW_MODE.TEXT
    },
    canUndo() {
      return this.actionHistoryIdx !== -1
    },
    canRedo() {
      return this.actionHistory.length > 0 &&
        this.actionHistoryIdx < this.actionHistory.length - 1
    },
    strokeDashArray() {
      return this.drawOptions.lineType === 'dashed' ? [10, 5] : []
    },
  },
  methods: {
    forceEndEdit() {
      const activeObject = this.fabricCanvas.getActiveObject()
      if (!activeObject || !activeObject.isEditing) { return }
      activeObject.exitEditing()
    },
    terminateDrawingPolyline() {
      if (!this.drawingObject || !this.drawer || this.drawingObject.type !== 'polyline') {
        return
      }
      this.drawer.forceDone(this.drawingObject)
      this.drawingObject = null
      this.fabricCanvas.requestRenderAll()
    },
    updateColorCorrection(filterName, value) {
      const filterIdx = filterName === 'brightness' ? 0 : 1
      if (!this.backgroundImage ||
        this.backgroundImage.filters[filterIdx][filterName] === value
      ) { return }
      this.backgroundImage._stateProperties = {
        [filterName]: this.backgroundImage.filters[filterIdx][filterName]
      }
      this.backgroundImage.filters[filterIdx][filterName] = value
      this.backgroundImage.applyFilters()
      this.fabricCanvas.requestRenderAll()
      this.backgroundImage.stateProperties.push(filterName)
      this.backgroundImage[filterName] = value
      this.fabricCanvas.fire('object:modified', { target: this.backgroundImage })
    },
    getObjectPropName(object, optionName) {
      const optionPropMap = {
        strokeColor: 'stroke',
        strokeColor_ext_i_text: 'fill',
        fillColor: 'fill',
        fillColor_ext_i_text: 'backgroundColor',
        fillColor_line: null,
        fillColor_arrow: null,
        fillColor_lineDoubleArrow: null,
        lineType: 'strokeDashArray',
        lineType_ext_i_text: null,
        fontSize_ext_i_text: 'fontSize',
        writingMode_ext_i_text: 'writingMode',
        strokeWidth: 'strokeWidth',
      }
      const type = object.type === 'ext-i-text' ? object.type.replaceAll('-', '_') : object.type
      if (optionPropMap[`${optionName}_${type}`] !== undefined) {
        return optionPropMap[`${optionName}_${type}`]
      }
      if (optionPropMap[optionName] !== undefined) {
        return optionPropMap[optionName]
      }
      return null
    },
    updateObjectsSelectable() {
      const objects = this.fabricCanvas.getObjects()
      objects.forEach(obj => {
        obj.set({
          selectable: this.isDrawModeNone,
        })
        obj.setCoords()
      })
      this.fabricCanvas.requestRenderAll()
    },
    onMouseDown(options) {
      const pointer = this.fabricCanvas.getPointer(options.e)
      const activeObj = this.fabricCanvas.getActiveObject()
      if (this.drawingObject && this.isDrawModePolyline) {
        this.drawer.addPoint(this.drawingObject, pointer.x, pointer.y)
        return
      }
      const drawer = createDrawer(this.DRAW_MODE_TYPE[this.drawMode], this.drawOptions)
      if (!drawer || activeObj) { return }
      this.drawingObject = drawer.make(pointer.x, pointer.y)
      this.fabricCanvas.add(this.drawingObject)
      this.fabricCanvas.requestRenderAll()
      this.drawer = drawer
    },
    onMouseMove(options) {
      if (!this.drawingObject || !this.drawer) { return }
      const pointer = this.fabricCanvas.getPointer(options.e)
      const updated = this.drawer.resize(this.drawingObject, pointer.x, pointer.y)
      if (updated) {
        this.fabricCanvas.requestRenderAll()
      }
    },
    onMouseUp(options) {
      if (this.isDrawModeFree) {
        this.fabricCanvas.setActiveObject(options.currentTarget)
        this.$emit('object-drew')
        return
      }
      const pointer = this.fabricCanvas.getPointer(options.e)
      if (!this.drawingObject ||
        (this.isDrawModePolyline && !this.drawer.done(this.drawingObject, pointer.x, pointer.y))
      ) { return }
      if (this.isDrawModeText && this.drawingObject) {
        this.drawingObject.enterEditing()
        this.drawingObject.hiddenTextarea.focus()
      }

      if (
        this.drawingObject.width === 0 &&
        (this.isDrawModeText || this.drawingObject.height === 0)
      ) {
        this.drawer.resize(
          this.drawingObject,
          pointer.x + this.drawer.defaultSize,
          pointer.y + this.drawer.defaultSize
        )
      }

      this.$emit('object-drew')
      // 変更前の状態を履歴管理するため、saveStateを実行する
      this.drawingObject.saveState()
      const eventArgs = {
        target: this.drawingObject
      }
      this.fabricCanvas.setActiveObject(this.drawingObject)
      // drawingObject=nullとobject:modifiedイベント発行の順番は変更不可
      this.drawingObject = null
      this.fabricCanvas.fire('object:modified', eventArgs)
    },
    onObjectAdded(e) {
      const newHistory = {
        action: this.ACTION.ADD,
        object: e.target,
        state: null,
        prevState: null,
      }
      this.updateActionHistory(newHistory)
    },
    onObjectSelected(e) {
      const selectedObj = e.selected[0]
      const objType = selectedObj.type.replaceAll('-', '').toUpperCase()
      const drawMode = this.TYPE_DRAW_MODE[objType]
      const lineType = selectedObj.strokeDashArray && selectedObj.strokeDashArray.length > 0
        ? 'dashed'
        : 'solid'
      const fill = drawMode === this.DRAW_MODE.TEXT ? selectedObj.backgroundColor : selectedObj.fill
      const strokeColor = drawMode === this.DRAW_MODE.TEXT ? selectedObj.fill : selectedObj.stroke
      const data = {
        drawMode,
        strokeColor: strokeColor || null,
        strokeWidth: selectedObj.strokeWidth || null,
        fillColor: fill || null,
        lineType,
        fontSize: selectedObj.fontSize || null,
        writingMode: selectedObj.writingMode || null,
      }
      this.$emit('object-selected', data)
    },
    onObjectDeselected(e) {
      this.$emit('object-deselected')
    },
    onObjectModified(e) {
      // mouseMoveの変更を回避する
      if (this.drawingObject) { return }
      const prevState = { ...e.target._stateProperties }
      const state = e.target.stateProperties.reduce((obj, prop) => {
        obj[prop] = e.target[prop]
        return obj
      }, {})
      const newHistory = {
        action: this.ACTION.MODIFY,
        object: e.target,
        // 個別文字のスタイル（styles）がコピーされないため、deepCloneを行う
        state: JSON.parse(JSON.stringify(state)),
        prevState,
      }
      this.updateActionHistory(newHistory)
      // 変更前の状態を履歴管理するため、saveStateを実行する
      e.target.saveState()
    },
    onObjectRemoved(e) {
      const newHistory = {
        action: this.ACTION.REMOVE,
        object: e.target,
        state: null,
        prevState: null,
      }
      this.updateActionHistory(newHistory)
    },
    updateActionHistory(newHistory) {
      if (this.revertingActionHistory &&
        ((this.revertingActionHistory.action === newHistory.action) ||
        (this.revertingActionHistory.action === this.ACTION.ADD && newHistory.action === this.ACTION.REMOVE) ||
        (this.revertingActionHistory.action === this.ACTION.REMOVE && newHistory.action === this.ACTION.ADD))
      ) {
        // 戻す/やり直す場合は履歴を更新しない
        this.revertingActionHistory = null
        return
      }
      if (this.actionHistoryIdx < this.actionHistory.length - 1) {
        // 戻る途中から編集した場合は、履歴からそれ以後のものを無くす
        this.actionHistory = this.actionHistory.slice(0, this.actionHistoryIdx + 1)
      }
      const lastHistory = this.actionHistory.at(-1)
      if (lastHistory &&
        lastHistory.action === this.ACTION.ADD &&
        newHistory.action === this.ACTION.MODIFY &&
        lastHistory.object === newHistory.object &&
        lastHistory.state === null
      ) {
        // 新規描画の場合、ADDに続きMODIFYのイベントが発生するため、一つにマージする
        lastHistory.state = newHistory.state
      } else {
        if (this.actionHistory.length >= this.maxActionHistory) {
          this.actionHistory.shift()
        }
        this.actionHistory.push(newHistory)
        this.actionHistoryIdx = this.actionHistory.length - 1
      }
    },
    undo() {
      this.terminateDrawingPolyline()
      this.forceEndEdit()
      const history = this.actionHistory[this.actionHistoryIdx]
      this.revertingActionHistory = history
      if (history.action === this.ACTION.ADD) {
        this.fabricCanvas.remove(history.object)
      } else if (history.action === this.ACTION.MODIFY) {
        this.modifyForUndoRedo(history.object, history.prevState)
      } else if (history.action === this.ACTION.REMOVE) {
        this.fabricCanvas.add(history.object)
      }
      this.fabricCanvas.requestRenderAll()
      this.actionHistoryIdx--
    },
    redo() {
      this.actionHistoryIdx++
      const history = this.actionHistory[this.actionHistoryIdx]
      this.revertingActionHistory = history
      if (history.action === this.ACTION.ADD) {
        this.fabricCanvas.add(history.object)
      } else if (history.action === this.ACTION.MODIFY) {
        this.modifyForUndoRedo(history.object, history.state)
      } else if (history.action === this.ACTION.REMOVE) {
        this.fabricCanvas.remove(history.object)
      }
      this.fabricCanvas.requestRenderAll()
    },
    modifyForUndoRedo(object, newState) {
      if (newState.brightness || newState.brightness === 0) {
        object.filters[0].brightness = newState.brightness
        object.applyFilters()
        this.$emit('brightness-changed', newState.brightness)
      } if (newState.contrast || newState.contrast === 0) {
        object.filters[1].contrast = newState.contrast
        object.applyFilters()
        this.$emit('contrast-changed', newState.contrast)
      } else {
        object.setOptions(newState)
      }
      // ADDとREMOVEに合わせるため、イベントを発行する
      this.fabricCanvas.fire('object:modified', { target: object })
    },
    removeSelectedObject() {
      const selectedObject = this.fabricCanvas.getActiveObject()
      if (!selectedObject) { return }
      // やり直す後に削除した場合は履歴を保持する
      this.revertingActionHistory = null
      this.fabricCanvas.remove(selectedObject)
    },
    toDataURL() {
      const multiplier = this.sizeInfo.realWidth / this.sizeInfo.width
      return this.fabricCanvas.toDataURL({
        format: 'jpeg',
        // default image quality of canvas.toDataURL.
        quality: 1,
        multiplier
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
