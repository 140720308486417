export default {
  methods: {
    getNendoSelect() {
      const dt = new Date()
      const now = dt.getFullYear()
      const ret = []
      let y = 0
      // 適当に数年前から
      for (let i = 0; i < 10; i++) {
        y = now - i
        ret.unshift({disp: `${y}年度`, nendo: y})
      }
      return ret
    },
    getKenmeiDisp1(nendo, reporteeDep) {
      // 年度で持ち回り. 2017年度が3らしい.
      // N -> 1, H -> 2, K -> 3
      const num1 = ((nendo + 1) % 3) + 1
      // なんか、固定らしい
      const num2 = 1
      return '構造物点検' +
        `${nendo}-${num1}-${num2}` +
        `(${nendo}年度高速道路緊急点検・巡回点検)`
    },
    prepareDispArrays() {
      this.prepareOnHighwayNoticeItems()
      this.prepareOffHighwayNoticeItems()
    },
    prepareOnHighwayNoticeItems() {
      const result = []
      const arr1 = [
        '立入点検実施は協議書等の期限変更時期の 月初めに実施すること。',
        '1～10の確認は「〇」印、未確認は「－」印を記入する。 是正事項がある場合は「是正」と記入する。',
        '「是正」とした場合は必ず是正事項・指示方法・確認欄に 記述すること。',
        '是正事項の確認欄が報告の場合は、是正日時を 現場責任者に確認すること。',
        '新規参入請負者については、十分に確認点検を行うこと。',
      ]
      arr1.forEach(e => {
        const num = result.length + 1
        result.push({num, val: e})
      })
      // 行数最大まで入れる
      const rem = this.noticeItemCountMax - result.length
      Array.from({ length: rem }).forEach(() => {
        result.push({num: '', val: ''})
      })
      this.onHighwayNoticeItems = result
    },
    prepareOffHighwayNoticeItems() {
      const result = []
      const arr1 = [
        '立入点検実施は協議書等の期限変更時期の月初めに実施すること。',
        '1～16の確認は「〇」印、未確認は「－」印を記入する。 是正事項がある場合は「是正」と記入する。',
        '「是正」とした場合は必ず是正事項・指示方法・ 確認欄に記述すること。',
        '是正事項の確認欄が報告の場合は、是正日時を 現場責任者に確認すること。',
        '新規参入請負者については、十分に確認点検を行うこと。',
      ]
      arr1.forEach(e => {
        const num = result.length + 1
        result.push({num, val: e})
      })
      // 行数最大まで入れる
      const rem = this.noticeItemCountMax - result.length
      Array.from({ length: rem }).forEach(() => {
        result.push({num: '', val: ''})
      })
      this.offHighwayNoticeItems = result
    },
    getChecklistItemsByChecklistInfos(checklistInfos, checklistItemMap, checklistItemCountMax) {
      const result = []
      checklistInfos.forEach(e => {
        const num = result.length + 1
        const item = checklistItemMap[e.key] || {}
        result.push({ num: num, val1: item.type, val2: item.text, val3: e.val })
      })
      // 行数最大まで入れる
      const rem = checklistItemCountMax - result.length
      Array.from({ length: rem }).forEach(() => {
        result.push({ num: '', val1: '', val2: '', val3: '' })
      })
      return result
    },
  }
}
