<template src="./template.html"></template>

<script>
import Vue from 'vue'
import PRT from '@/consts/imprint'
import roadRegulationReportApi from '@/api/roadRegulationReport'
import masterMixin from '@/mixin/masterMixin'
import roadRegulationMixin from '@/mixin/roadRegulationMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import printMixin from '@/mixin/printMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'printRoadRegulation',
  props: ['id'],
  data() {
    return {
      reporteeDepMap: {},
      reporteeDeps: [],
      received_by: [],
      onOffTypeDisp: '高速上',

      onHighwayChecklistItems: [],
      onHighwayChecklistItemMap: [],
      offHighwayChecklistItems: [],
      offHighwayChecklistItemMap: [],

      checklistItemCountMax: 20,
      onHighwayNoticeItems: [],
      noticeItemCountMax: 6,
      offHighwayNoticeItems: [],

      reports: [],

      isImprint: false,
      managerInspectorCivilImprint: null,
      managerInspectorFacilityImprint: null,
      inspectorImprint: null,
    }
  },
  computed: {
    isReporteeDepDisp1Civil() {
      const reporteeDepInfo = this.reporteeDepMap[this.reports[0].reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'civil'
    },
    isReporteeDepDisp1Facility() {
      const reporteeDepInfo = this.reporteeDepMap[this.reports[0].reportee_dep1]
      if (!reporteeDepInfo) { return false }
      return reporteeDepInfo.reportee_type === 'facility'
    },
    onOffTypeDisp1() {
      const hwOnOffType = this.reports[0].regulate_highway1_on_off_type
      return hwOnOffType === 'on' ? '高速上' : '街路上'
    },
    kenmeiDisp() {
      const report = this.reports[0]
      if (!report.reportee_dep1 || !report.construction_nendo) {
        return '※報告先と工事年度を選択してください'
      }
      return this.getKenmeiDisp1(
        report.construction_nendo,
        this.reporteeDepMap[report.reportee_dep1]
      )
    },
    checklistItems() {
      const checklistInfos = this.reports[0].checklist_infos || []
      const hwOnOffType = this.reports[0].regulate_highway1_on_off_type
      const checklistItemMap = hwOnOffType === 'on' ? this.onHighwayChecklistItemMap : this.offHighwayChecklistItemMap
      return this.getChecklistItemsByChecklistInfos(checklistInfos, checklistItemMap, this.checklistItemCountMax)
    },
    noticeItems() {
      const hwOnOffType = this.reports[0].regulate_highway1_on_off_type
      return hwOnOffType === 'on' ? this.onHighwayNoticeItems : this.offHighwayNoticeItems
    },
  },
  mounted() {
    this.setPrintPageSize('A4 landscape')
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
      this.reporteeDeps = lovs.reportee_dep.vals_inuse
      this.received_by = lovs.requestor.vals_inuse
      this.onHighwayChecklistItems =
        lovs.road_regulation_on_highway_checklist_item.vals_inuse
      this.onHighwayChecklistItemMap =
        lovs.road_regulation_on_highway_checklist_item.map
      this.offHighwayChecklistItems =
        lovs.road_regulation_off_highway_checklist_item.vals_inuse
      this.offHighwayChecklistItemMap =
        lovs.road_regulation_off_highway_checklist_item.map
    })

    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_ROADREGULATION

    document.querySelector('body').classList.add('print-road-regulation')
  },
  mixins: [masterMixin, roadRegulationMixin, imprintPhotoMixin, errorHandleMixin, printMixin],
  methods: {
    getReport_() {
      roadRegulationReportApi.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          const inspectContentDisp = Vue.filter('inspectContentDisp')
          data.inspectContentDisp = inspectContentDisp(data)
          data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)
          data.reporteeDepDisp2 = this.reporteeDepDisp(data.reportee_dep2)

          const rh = data.report_header
          data.manager_inspector_civil = rh.manager_inspector_civil
          data.manager_inspector_facility = rh.manager_inspector_facility
          data.inspector = rh[`inspector${data.crew_number}`]

          if (!data.checklist_infos) {
            const hwOnOffType = data.regulate_highway1_on_off_type
            const checklistItems = hwOnOffType === 'on' ? this.onHighwayChecklistItems : this.offHighwayChecklistItems
            data.checklist_infos = checklistItems.map(e => {
              return { key: e.key, val: '' }
            })
          }

          const promises = [
            this.getImprintPhoto('manager_inspector_civil', data.manager_inspector_civil, rh.dt),
            this.getImprintPhoto('manager_inspector_facility', data.manager_inspector_facility, rh.dt),
            this.getImprintPhoto('inspector', data.inspector, rh.dt)
          ]
          Promise.all(promises).then((results) => {
            this.managerInspectorCivilImprint = results[0]
            this.managerInspectorFacilityImprint = results[1]
            this.inspectorImprint = results[2]
          }).catch((error) => {
            this.handleErrorResponse(error)
          })

          this.prepareDispArrays()

          this.reports = [data]
        })
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    isOnHighway() {
      return this.reports[0].regulate_highway1_on_off_type === 'on'
    },
    isOffHighway() {
      return !this.isOnHighway()
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
