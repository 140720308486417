<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import emergencyInspectReportApi from '@/api/emergencyInspectReport'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'emergencyInspectList',
  data() {
    return {
      search: {
        dtFrom: null,
        dtTo: null,
        requestHighway1Name: '',
        requestContentKind: '',
      },
      hasInputError: false,

      list: [],
      listItemsPerPage: 20,
      currentPage: 1,
      isSearchResultEmpty: false,

      hwNames: [],
      requestContentKinds: [],

      reportToDelete: null,
    }
  },
  computed: {
    ...mapState('user', {
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_tenken: state => state.has_role_tenken,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  async mounted() {
    const { user } = await this.ensureUserAndMasters()
    this.prepareMasters()
    const { dtStrFrom, dtStrTo } = this.getListInitialSearchParams(user.env)
    this.search.dtFrom = dtStrFrom
    this.search.dtTo = dtStrTo
    this._doSearch()
  },
  mixins: [masterMixin, dateMixin, listPageMixin, errorHandleMixin],
  methods: {
    prepareMasters() {
      const lovs = window.master.lovs
      this.hwNames = lovs.highway.vals_inuse
      this.requestContentKinds = lovs.request_content_kind.vals_inuse
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dtFrom)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dtTo)) {
        this.hasInputError = true
      }
      const diffSec = new Date(this.search.dtTo) - new Date(this.search.dtFrom)
      if (diffSec > 2 * 365 * 86400 * 1000) {
        this.hasInputError = true
      }
    },
    doSearch() {
      if (this.hasInputError) { return }

      this.pushQueryParamsToHistory({
        dtStrFrom: this.search.dtFrom,
        dtStrTo: this.search.dtTo,
      })
      this._doSearch()
    },
    _doSearch() {
      this.isSearchResultEmpty = false
      this.list = []
      this.currentPage = 1

      const obj = {
        from: new Date(this.search.dtFrom),
        to: new Date(this.search.dtTo),
        request_highway1_name: this.search.requestHighway1Name,
        request_content_kind: this.search.requestContentKind,
      }
      emergencyInspectReportApi.index(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            this.isSearchResultEmpty = true
            return
          }
          this.list = data
        })
    },
    async doCSVDownload() {
      try {
        const reqObj = {
          data: {
            from: new Date(this.search.dtFrom),
            to: new Date(this.search.dtTo),
            request_highway1_name: this.search.requestHighway1Name,
            request_content_kind: this.search.requestContentKind,
          }
        }
        const { data } = await emergencyInspectReportApi.downloadCSV(reqObj)
        const timestamp = Vue.filter('dtFormat')(new Date(), 'yyyymmddHHMMSS')
        const filename = `緊急点検報告書一覧_${timestamp}.csv`
        this.downloadBlobAsFile(data, filename)
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    downloadBlobAsFile(blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    },
    confirmDeleteReport(e) {
      this.reportToDelete = e
    },
    deleteReport() {
      const obj = {
        id: this.reportToDelete.id,
      }
      emergencyInspectReportApi.delete(obj)
        .then(() => {
          this.reportToDelete = null
          this._doSearch()
        })
    },
    reportNumPaddedDisp,
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
