<template>
<div class="page page-login">
  <div class="login-header row text-center">
    <div class="logo">
      <h1>緊急点検報告書作成システム</h1>
    </div>
  </div>

  <div class="login-body row center-block">
    <div v-if="showErrorMessage" class="err-msg text-center">
      <label class="small error">
        ログインIDまたはパスワードが間違っています
      </label>
    </div>
    <form class="form-horizontal" @submit.prevent="submitLogin">
      <div class="form-group">
        <input type="text" name="id"
           class="form-control input-lg"
           v-model="loginForm.uid"
           required="required"
           placeholder="ログインID">
      </div>
      <div class="form-group">
        <input type="password" name="password"
           class="form-control input-lg"
           v-model="loginForm.password"
           required="required"
           placeholder="パスワード">
      </div>
      <div class="form-group">
        <button type="submit"
            class="btn btn-primary btn-lg btn-block login-btn">
          ログイン
        </button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'login',
  created() {
    this.$store.dispatch('user/getMe')
      .then(() => {
        // redirect to top if already logged in
        this.$router.replace({name: 'top'})
      })
      .catch(() => {
        // nothing to do
      })
  },
  data() {
    return {
      loginForm: {
        uid: '',
        password: '',
      },
      showErrorMessage: false,
    }
  },
  methods: {
    async submitLogin() {
      this.showErrorMessage = false

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers.Authorization

      try {
        const res = await this.$store.dispatch('user/login', this.loginForm)
        await this.$store.dispatch('localStorage/set', {
          key: 'serverToken',
          val: res.access_token,
        })

        // want to explicitly reload
        location.href = '/'
      } catch (e) {
        this.showErrorMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  .login-header {
    margin: 60px 0 16px 0;
  }
  .login-body {
    max-width: 320px;
  }
}
.err-msg {
  margin-right: -15px;
  margin-left: -15px;
}
</style>
