// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { appRouter, printRouter } from '@/router'
import store from '@/store'
import * as filters from '@/filter'
import App from '@/components/App/App'

import MyCalendar from '@/components/lib/MyCalendar'

import Pager from '@/components/lib/Pager'
import Modal from '@/components/lib/Modal'
import DateTimeInput from '@/components/lib/DateTimeInput'
import LimitedTextArea from '@/components/lib/LimitedTextArea'
import AutoComplete from '@/components/lib/AutoComplete'
import ReportHeader from '@/components/lib/ReportHeader'
import WorkTimeInput from '@/components/lib/WorkTimeInput'
import HighwayInput1 from '@/components/lib/HighwayInput1'
import HighwayInput2 from '@/components/lib/HighwayInput2'
import HighwayInput3 from '@/components/lib/HighwayInput3'
import ReporteeDepBadge from '@/components/lib/ReporteeDepBadge'
import DetailHeaderBar from '@/components/lib/DetailHeaderBar'
import RedDetailHeaderBar from '@/components/lib/RedDetailHeaderBar'
import PhotoSelector from '@/components/lib/PhotoSelector'
import PhotoEditor from '@/components/lib/PhotoEditor'
import DetailPhotos from '@/components/lib/detailPhotos/DetailPhotos'
import PrintPhotos from '@/components/lib/printPhotos/PrintPhotos'
import HttpImg from '@/components/lib/HttpImg'
import HttpLink from '@/components/lib/HttpLink'

import {
  calendarDefaults,
  calendarLangFunc
} from '@/consts/calendar'

Vue.config.productionTip = false

// consts
window.VueCalendarDefaults = calendarDefaults
window.VueCalendarLang = calendarLangFunc

// register filters
Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

// register global components
Vue.component('my-calendar', MyCalendar)
Vue.component('pager', Pager)
Vue.component('modal', Modal)
Vue.component('date-time-input', DateTimeInput)
Vue.component('limited-textarea', LimitedTextArea)
Vue.component('auto-complete', AutoComplete)
Vue.component('report-header', ReportHeader)
Vue.component('work-time-input', WorkTimeInput)
Vue.component('highway-input1', HighwayInput1)
Vue.component('highway-input2', HighwayInput2)
Vue.component('highway-input3', HighwayInput3)
Vue.component('reportee-dep-badge', ReporteeDepBadge)
Vue.component('detail-header-bar', DetailHeaderBar)
Vue.component('red-detail-header-bar', RedDetailHeaderBar)
Vue.component('photo-selector', PhotoSelector)
Vue.component('photo-editor', PhotoEditor)
Vue.component('detail-photos', DetailPhotos)
Vue.component('print-photos', PrintPhotos)
Vue.component('http-img', HttpImg)
Vue.component('http-link', HttpLink)

printRouter.beforeEach((to, from, next) => {
  document.title = '印刷画面'
  next()
})
const router = location.pathname.startsWith('/print/') ? printRouter : appRouter

/* eslint-disable no-new */
new Vue({
  router,
  store,
  components: { App },
  render: h => h(App),
}).$mount('#app')
