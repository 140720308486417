<template src="./template.html"></template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import mediaApi from '@/api/media'
import masterMixin from '@/mixin/masterMixin'
import mediaMixin from '@/mixin/mediaMixin'
import imageMixin from '@/mixin/imageMixin'
import masterDetailMixin from '@/mixin/masterDetailMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { mapState } from 'vuex'

export default {
  name: 'masterDetail',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lovs: null,
      info: {
        name: '',
        is_creatable: false,
        is_updatable: false,
        is_deletable: false,
        is_imprint: false,
        props: [],
      },
      list: [],
      showExpired: false,

      showCreateModal: false,
      createEntry: {},

      showDeleteConfirmModal: false,
      deleteEntry: {},

      showImprintModal: false,
      isUploadingPhoto: false,
      selectedEntry: {},
      isLoadPhoto: false,
      isFileSelected: false,

      errors: {},
    }
  },
  computed: {
    ...mapState('user', {
      hasRoleSuperAdmin: state => state.has_role_super_admin,
    }),
    filteredList() {
      return this.list.filter(e => {
        return this.showExpired || e.isInUse
      })
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const acc = []
      const tmpMap = {}
      const ret = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      acc.forEach(e => {
        if (tmpMap[e]) { return }
        tmpMap[e] = true
        ret.push(e)
      })
      return ret
    },
  },
  mounted() {
    this.waitForMasters().then(() => {
      this.lovs = window.master.lovs
      const lov = this.lovs[this.id]

      if (!lov) { return }
      if (!lov.is_displayable) { return }

      // マスタ名
      this.info.name = lov.disp_name
      // 編集とか可否
      this.info.is_creatable = lov.is_creatable
      this.info.is_updatable = lov.is_updatable
      this.info.is_deletable = lov.is_deletable

      // 印登録(総括調査員(土木)・総括調査員(施設)・点検調査員・運転者)
      if (this.$route.path === '/masters/manager_inspector_civil' ||
          this.$route.path === '/masters/manager_inspector_facility' ||
          this.$route.path === '/masters/inspector' ||
          this.$route.path === '/masters/driver') {
        this.info.is_imprint = true
      }

      // 列
      this.info.props = this.getLovDispColInfo(this.id, lov)
      this.info.props.forEach(propInfo => {
        if (propInfo.edit_spec.type === 'select') {
          propInfo.edit_spec.options =
            this.lovs[propInfo.edit_spec.lov_name].vals
        }
      })

      // 行
      this.list = this.convDataArr(lov.vals)
    })
  },
  mixins: [masterMixin, masterDetailMixin, errorHandleMixin, imageMixin, mediaMixin],
  methods: {
    convDataArr(arr) {
      return arr.map((e, i) => {
        const data = this.convData(this.id, e)
        data.origIndex = i
        return data
      })
    },
    convData(lovName, entry) {
      // 元のオブジェクトをcloneして必要なparamがあれば追加
      const ret = this.convLovEntry(lovName, entry)
      // 各列の表示文字列を変換
      this.info.props.forEach(propInfo => {
        const prop = propInfo.key
        let val = ret[prop]
        const dispRule = propInfo.disp_spec
        if (dispRule) {
          // 表示名ルールがある場合、別のlovから取ってくる.
          const [refLovName, refLovProp] = dispRule.split('.')
          const refLov = this.lovs[refLovName]
          if (!refLov) {
            // shouldn't happen
            console.error(`lovName=${refLovName} is wrong`)
          }
          const targetLovEntry = refLov.map[val]
          if (targetLovEntry) {
            val = targetLovEntry[refLovProp]
          } else {
            // 途中までしか値が入ってないことはありうる.
            // 路線名-方向 とか.
            // が、値が入ってて取れないのはおかしい.
            if (val) {
              // shouldn't happen
              console.error(`${val} not found for lovName=${refLovName}`)
            }
          }
        }
        // 全部'_disp'を付けた別のプロパティに入れる
        ret[`${prop}_disp`] = val
      })
      ret.lov_id = entry.lov_id

      // ほんとは関数の引数でもらわないとリスト全体にわたって同じタイムスタンプには
      // ならないが、まぁ実用上は問題ないだろう
      const now = new Date()
      ret.isInUse = ret.start_use <= now && now < ret.end_use

      return ret
    },
    async doCSVDownload() {
      try {
        const reqObj = {
          lovName: this.id,
        }
        if (!this.showExpired) {
          reqObj.data = { dt: new Date() }
        }
        const { data } = await masterApi.downloadCSV(reqObj)
        const timestamp = Vue.filter('dtFormat')(new Date(), 'yyyymmddHHMMSS')
        const filename = `${this.info.name}一覧_${timestamp}.csv`
        this.downloadBlobAsFile(data, filename)
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    downloadBlobAsFile(blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    },
    clearErrors() {
      this.errors = {}
    },
    checkEntry(entry) {
      this.clearErrors()
      let ret = true
      if (!entry.key_edit) {
        this.errors.key = ['IDは必須項目です']
        ret = false
      }
      if (isNaN(entry.disp_order_edit)) {
        this.errors.disp_order = ['表示順には数値を入力してください']
        ret = false
      }
      if (entry.disp_order_edit > 9999) {
        this.errors.disp_order = ['表示順には9999以下の数値を入力してください']
        ret = false
      }
      this.info.props.forEach(prop => {
        const origProp = prop.orig_prop
        // 最初のやつは必須項目
        if (origProp === 'val1') {
          const val = entry[`${prop.key}_edit`]
          if (val === null || val === undefined || val === '') {
            this.errors[prop.key] = [`${prop.disp_name}は必須項目です`]
            ret = false
          }
        }
      })
      return ret
    },
    getReqObj(entry) {
      const dataObj = {}
      this.info.props.forEach(propInfo => {
        const fromProp = `${propInfo.key}_edit`
        const toProp = propInfo.orig_prop || propInfo.key
        dataObj[toProp] = entry[fromProp]
      })
      dataObj.disp_order = parseInt(dataObj.disp_order)
      dataObj.start_use = entry.start_use_edit
      dataObj.end_use = entry.end_use_edit
      return {
        lovName: this.id,
        lovKey: dataObj.key,
        data: dataObj,
      }
    },
    reOrderList() {
      this.list = this.list.sort((a, b) => {
        const v1 = a.disp_order
        const v2 = b.disp_order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      }).map((e, i) => {
        e.origIndex = i
        return e
      })
    },
    showAddEntryModal() {
      this.clearErrors()
      this.createEntry = {
        start_use_edit: new Date(new Date().setHours(0, 0, 0, 0)),
        end_use_edit: new Date(9999, 0, 1, 0, 0, 0),
      }
      this.showCreateModal = true
    },
    doAddEntry() {
      if (!this.checkEntry(this.createEntry)) { return }
      const reqObj = this.getReqObj(this.createEntry)
      masterApi.create(reqObj)
        .then(({ data }) => {
          const dataConv = this.convData(this.id, data)
          this.list.push(dataConv)
          this.reOrderList()
          this.showCreateModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    startEditEntry(entry) {
      this.clearErrors()
      this.info.props.forEach(propInfo => {
        const key = propInfo.key
        entry[`${key}_edit`] = entry[key]
      })
      entry.start_use_edit = entry.start_use
      entry.end_use_edit = entry.end_use
      entry.isEditMode = true
    },
    saveEntry(entry) {
      if (!this.checkEntry(entry)) { return }
      const reqObj = this.getReqObj(entry)
      masterApi.update(reqObj)
        .then(({ data }) => {
          const dataConv = this.convData(this.id, data)
          this.endEditEntry(dataConv)
          Vue.set(this.list, entry.origIndex, dataConv)
          this.reOrderList()
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    endEditEntry(entry) {
      entry.isEditMode = false
    },
    confirmDeleteEntry(entry) {
      this.clearErrors()
      this.deleteEntry = entry
      this.showDeleteConfirmModal = true
    },
    doDeleteEntry() {
      const reqObj = {
        lovName: this.id,
        lovKey: this.deleteEntry.key,
      }
      masterApi.delete(reqObj)
        .then(({ data }) => {
          Vue.delete(this.list, this.deleteEntry.origIndex)
          this.deleteEntry = {}
          this.showDeleteConfirmModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    cutDisplayString(value) {
      if (value.length >= 15) {
        return value.substring(0, 15) + '...'
      }
      return value
    },
    showImprintUploadModal(entry) {
      this.isFileSelected = false
      this.previewCanvasClear()
      this.selectedEntry = entry
      this.getImprintPhoto()
      this.showImprintModal = true
    },
    addPhoto() {
      const formData = new FormData()
      formData.append('photo',
        this.canvasToBlob(this.$refs.previewCanvas, false),
        this.uploadFile.name)

      const obj = {
        lovName: this.id,
        lovKey: this.selectedEntry.key,
        formData: formData
      }

      masterApi.addPhoto(obj).then(({ data }) => {
        this.selectedEntry.imprint_photo = data
        Vue.set(this.list, this.selectedEntry.origIndex, this.selectedEntry)
        this.selectedEntry = {}
        this.showImprintModal = false
      }).catch(() => { })
    },
    deletePhoto() {
      const obj = {
        lovName: this.id,
        lovKey: this.selectedEntry.key,
      }
      masterApi.deletePhoto(obj).then(() => {
        this.selectedEntry.imprint_photo = null
        Vue.set(this.list, this.selectedEntry.origIndex, this.selectedEntry)
        this.selectedEntry = {}
        this.showImprintModal = false
      }).catch(() => { })
    },
    cancelAddPhoto() {
      this.showImprintModal = false
    },
    fileSelected(e) {
      if (!e.target.files || e.target.files.length === 0) {
        this.isFileSelected = false
        return
      }
      const image = new Image()
      const reader = new FileReader()
      const canvas = this.$refs.previewCanvas
      const f = e.target.files[0]

      this.previewCanvasClear()
      reader.readAsDataURL(f)
      reader.onload = () => {
        image.onload = function() {
          canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height)
        }
        image.src = reader.result
        this.isFileSelected = true
      }
      this.isLoadPhoto = false

      this.uploadFile = {
        name: f.name,
        type: f.type,
        file: f,
        src: window.URL.createObjectURL(f),
      }
    },
    previewCanvasClear() {
      const canvas = this.$refs.previewCanvas
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
    },
    getImprintPhoto() {
      const obj = {
        lovName: this.id,
        lovKey: this.selectedEntry.key,
      }
      masterApi.getPhoto(obj).then(({ data }) => {
        if (data) {
          mediaApi.getBlobData(data.photo_url_original).then((url) => {
            this.getBlobUrl(data.photo_url_original)
              .then(({ url }) => {
                const img = this.$refs.imprintImage
                img.src = url
                this.isLoadPhoto = true
              })
          }).catch(() => { })
        } else {
          this.isLoadPhoto = false
        }
      }).catch(() => {
        this.isLoadPhoto = false
      })
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped>
</style>
