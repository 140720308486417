<template src="./template.html"></template>

<script>
import Vue from 'vue'
import emergencyContactSummaryApi from '@/api/emergencyContactSummary'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import dateMixin from '@/mixin/dateMixin'
import {　mapState　} from 'vuex'

export default {
  name: 'emergencyContactSummaryDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const ret = {
      targetReporteeDepKey: '',
      targetReporteeDeps: [],
      reports: [],
      report: {
        report_header: {},
        reporteeDepInfo: {},
        reportsRankAB: [],
        reportsRankCD: [],
      },
      showUpdateCompleteModal: false,
      reportApi: emergencyContactSummaryApi,

      reporteeDepMap: {},
      defaultFacilityReporteeDeps: [],

      crewNumber: '1',

      errors: {},

      hoursDisps: [
        // {timeslot}-{open?}
        { key: '1-1', name: '昼間（開庁）' },
        { key: '1-0', name: '昼間（閉庁）' },
        { key: '2-1', name: '夜間（開庁）' },
        { key: '2-0', name: '夜間（閉庁）' },
      ],
    }
    return Object.assign({}, ret)
  },
  computed: {
    ...mapState('user', {
      area: state => state.area,
      env: state => state.env,
    }),
    dt1Disp() {
      return this.report.report_header.dt
    },
    dt2Disp() {
      return this.getNightShiftDate(
        this.report.report_header.timeslot,
        this.report.report_header.dt
      )
    },
    dt3Disp() {
      let ret = new Date(this.report.report_header.dt)
      if (this.report.report_header.timeslot === '2') {
        // 夜間の場合、報告日は翌日となる
        ret = new Date(ret.valueOf() + 86400 * 1000)
      }
      return ret
    },
    workStartDisp() {
      return this.report.report_header.work_start1
    },
    workEndDisp() {
      return this.report.report_header.work_end1
    },
    workTimeTermDisp1() {
      const target = String(this.report.report_header.work_start1)
      return target.substring(0, 1) === '9' ? '(8H)' : '(16H)'
    },
    workTimeTermDisp2() {
      const target = String(this.report.report_header.work_start1)
      return target.substring(0, 1) === '9' ? '昼間(開庁)' : '夜間(閉庁)'
    },
  },
  async mounted() {
    await this.ensureUserAndMasters()
    const lovs = window.master.lovs
    this.reporteeDepMap = lovs.reportee_dep.map_inuse
    // 本番は東京用環境と神奈川用環境で分かれているため、ちょっと残念な感じだが、
    // まぁしかたない.
    let defaultFacilityReporteeDeps = [
      this.reporteeDepMap[400],
      this.reporteeDepMap[502],
      this.reporteeDepMap[503],
      this.reporteeDepMap[600],
    ]
    if (this.env === 'production') {
      if (this.area === 'TYO') {
        defaultFacilityReporteeDeps = [
          this.reporteeDepMap[400],
          this.reporteeDepMap[502],
          this.reporteeDepMap[503],
        ]
      } else if (this.area === 'KNG') {
        defaultFacilityReporteeDeps = [
          this.reporteeDepMap[600],
        ]
      } else {
        defaultFacilityReporteeDeps = []
      }
    }
    this.defaultFacilityReporteeDeps = defaultFacilityReporteeDeps
    this.getReport_()
  },
  mixins: [masterMixin, redDetailPageMixin, errorHandleMixin, dateMixin],
  methods: {
    convReport_(data) {
      // { 報告先, 報告書[] } の配列を作る.
      // 西東神の施設管制は報告書がなくても作る. それ以外のとこで報告書がある場合は、足してやる.
      let targetReporteeInfos = this.defaultFacilityReporteeDeps.map(e => [e, []])
      // 必要な報告先のみを抽出 (そもそも土木に報告することがあるのかわからんが、一応)
      data.emergency_contact_reports.forEach(report => {
        const reporteeDepInfo = this.reporteeDepMap[report.reportee_dep1]
        if (reporteeDepInfo.reportee_type !== 'facility') { return }
        const idx = targetReporteeInfos.findIndex(e => e[0].key === report.reportee_dep1)
        if (idx !== -1) {
          targetReporteeInfos[idx][1].push(report)
        } else {
          targetReporteeInfos.push([this.reporteeDepMap[report.reportee_dep1], [report]])
        }
      })
      targetReporteeInfos = targetReporteeInfos.sort((a, b) => a[0].key < b[0].key ? -1 : 1)

      let reporteeDepDataSet = {}
      try {
        // どっかの誰かが配列で保存してた時期がある
        const something = JSON.parse(data.all_reportee_summaries_text)
        if (Array.isArray(reporteeDepDataSet)) {
          reporteeDepDataSet.forEach(e => {
            reporteeDepDataSet[e.dep] = e
          })
        } else {
          reporteeDepDataSet = something || {}
        }
      } catch (e) {
        reporteeDepDataSet = {}
      }

      // 報告先ごとにデータ作成
      const pageMap = targetReporteeInfos.map(([reporteeDepInfo, reports]) => {
        const ret = {
          report_header: data.report_header, // 全部同じ
          reporteeDepInfo,
          reportsRankAB: [],
          reportsRankCD: [],
          summary_text: '',
          send_page_num: 1,
          hoursDispSelect: '',
        }
        // ランクごとに配列を振り分け
        reports.slice().sort((a, b) => {
          // 念のためreport_num1の昇順に並ぶよう保証しておく
          return a.report_num1 < b.report_num1 ? -1 : 1
        }).forEach(e => {
          const rankPrefix = e.damage_rank.substring(0, 1)
          if (rankPrefix === 'A' || rankPrefix === 'B') {
            ret.reportsRankAB.push(e)
          } else {
            ret.reportsRankCD.push(e)
          }
        })
        const otherData = reporteeDepDataSet[reporteeDepInfo.key]
        ret.summary_text = otherData
          ? otherData.sum
          : '交通事故、トンネル火災、工事、障害に絡む連絡および出動管理'
        ret.send_page_num = otherData ? otherData.num : 1

        // 開庁閉庁のやつ
        ret.hoursDispSelect = otherData && otherData.hoursDispSelect
          ? otherData.hoursDispSelect
          : this.emergencyContactDefaultHoursDispSelect(ret.report_header)

        return ret
      }).reduce((acc, e) => {
        acc[e.reporteeDepInfo.key] = e
        return acc
      }, {})

      const targetReporteeDeps = targetReporteeInfos.map(e => e[0])
      return { targetReporteeDeps, pageMap }
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          const { targetReporteeDeps, pageMap } = this.convReport_(data)
          this.targetReporteeDeps = targetReporteeDeps
          this.targetReporteeDepKey = this.targetReporteeDeps[0].key
          this.pageMap = pageMap
          this.report = this.pageMap[this.targetReporteeDepKey]
        })
    },
    onReporteeDepsChange() {
      this.report = this.pageMap[this.targetReporteeDepKey]
    },
    async saveReport() {
      // よくわからんけど一応ガード
      if (this.targetReporteeDeps.length === 0) { return false }
      // 保存対象はページごとの適用、ページ番号のみ
      const dataObj = this.targetReporteeDeps.map(reporteeDepInfo => {
        const reporteeDepKey = reporteeDepInfo.key
        const page = this.pageMap[reporteeDepKey]
        const summaryText = page.summary_text
        const sendPageNum = page.send_page_num
        const hoursDispSelect = page.hoursDispSelect
        return {
          dep: reporteeDepKey,
          sum: summaryText,
          num: sendPageNum,
          hoursDispSelect
        }
      }).reduce((acc, e) => {
        acc[e.dep] = e
        return acc
      }, {})

      const obj = {
        id: this.id,
        data: { all_reportee_summaries_text: JSON.stringify(dataObj) },
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    getDigestsTime(text) {
      const arr = text.split(/\r\n|\r|\n/)
      return arr[0]
    },
    getDigestsBody(text) {
      const arr = text.split(/\r\n|\r|\n/)
      arr.shift()
      const rows = arr.join('\n')
      const lineBreakToBR = Vue.filter('lineBreakToBR')
      const ret = lineBreakToBR(rows)
      return ret
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
