<template src="./template.html"></template>

<script>
import Vue from 'vue'
import allReportsSummaryApi from '@/api/allReportsSummary'
import redDetailPageMixin from '@/mixin/redDetailPageMixin'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import roadRegulationMixin from '@/mixin/roadRegulationMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'allReportsSummaryDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    const ret = {
      report: {
        other_props: {},
        report_header: {},
      },
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,

      crewNumber: '1',
      reportApi: allReportsSummaryApi,
      reporteeGroups: [],
      reporteeDeps: [],
      reporteeDepMap: {},

      weathers: [],

      errors: {},
    }
    return Object.assign({}, ret)
  },
  computed: {
    dt1Disp() {
      return this.report.report_header.dt
    },
    dt2Disp() {
      return this.getNightShiftDate(
        this.report.report_header.timeslot,
        this.report.report_header.dt
      )
    },
    hasInputTeam2() {
      return !!this.report.report_header.inspector2
    },
    inspectorDisp() {
      return this.report.report_header[`inspector${this.crewNumber}`]
    },
    driverDisp() {
      return this.report.report_header[`driver${this.crewNumber}`]
    },
    workStartDisp() {
      return this.report.report_header[`work_start${this.crewNumber}`]
    },
    workEndDisp() {
      return this.report.report_header[`work_end${this.crewNumber}`]
    },
  },
  mounted() {
    this.getMasters()
    this.getReport_()
  },
  mixins: [masterMixin, redDetailPageMixin, errorHandleMixin, roadRegulationMixin],
  methods: {
    getMasters() {
      window.master.$promise.then(() => {
        const lovs = window.master.lovs
        this.reporteeGroups = lovs.reportee_group.vals_inuse
        this.reporteeDeps = lovs.reportee_dep.vals_inuse
        this.reporteeDepMap = lovs.reportee_dep.map
        this.weathers = lovs.weather.vals_inuse
      })
    },
    convReport_(data) {
      data.empty_rows_length = 4 - data.emergency_inspect_reports.length

      data.emergency_inspect_reports_civil = this.getReporteeTypeDataArray(
        data.emergency_inspect_reports,
        'civil'
      )
      data.emergency_inspect_reports_civil_length = data.emergency_inspect_reports_civil.length
      data.emergency_inspect_reports_facility = this.getReporteeTypeDataArray(
        data.emergency_inspect_reports,
        'facility'
      )
      data.emergency_inspect_reports_facility_length = data.emergency_inspect_reports_facility.length

      // 緊急連絡はランクA, Bの報告書のみ表示
      const emergencyContactReportsRankAB = this.getEmergencyContactReportsRankAB(data.emergency_contact_reports)
      data.emergency_contact_reports_civil = this.getReporteeTypeDataArray(
        emergencyContactReportsRankAB,
        'civil'
      )
      data.emergency_contact_reports_civil_length = data.emergency_contact_reports_civil.length
      data.emergency_contact_reports_facility = this.getReporteeTypeDataArray(
        emergencyContactReportsRankAB,
        'facility'
      )
      data.emergency_contact_reports_facility_length = data.emergency_contact_reports_facility.length

      data.complaint_handling_reports_civil = this.getReporteeTypeDataArray(
        data.complaint_handling_reports,
        'civil'
      )
      data.complaint_handling_reports_civil_length = data.complaint_handling_reports_civil.length
      data.complaint_handling_reports_facility = this.getReporteeTypeDataArray(
        data.complaint_handling_reports,
        'facility'
      )
      data.complaint_handling_reports_facility_length = data.complaint_handling_reports_facility.length

      data.road_regulation_reports_civil = this.getReporteeTypeDataArray(
        data.road_regulation_reports,
        'civil'
      )
      data.road_regulation_reports_civil_length = data.road_regulation_reports_civil.length
      data.road_regulation_reports_facility = this.getReporteeTypeDataArray(
        data.road_regulation_reports,
        'facility'
      )
      data.road_regulation_reports_facility_length = data.road_regulation_reports_facility.length

      Vue.delete(data, 'emergency_inspect_reports')
      Vue.delete(data, 'emergency_contact_reports')
      Vue.delete(data, 'complaint_handling_reports')
      Vue.delete(data, 'road_regulation_reports')

      data.other_props = data.other_props || {}

      const splitByLineBreak = Vue.filter('splitByLineBreak')

      const freeInputs = this.getFreeInputMetaParams()
      freeInputs.forEach(([propPart, defaultDispRows]) => {
        const prop = `${propPart}`
        const rows = splitByLineBreak(data[prop])
        data[prop] = rows.join('\n')
        const rowCountProp = `${prop}_rows`
        data.other_props[rowCountProp] = Math.max(rows.length, defaultDispRows)
      })

      return data
    },
    getReport_() {
      const obj = {id: this.id}
      this.reportApi.show(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.report = this.convReport_(data)
        })
    },
    getFreeInputMetaParams() {
      return [
        ['bikou1', 3],
      ]
    },
    async saveReport() {
      const obj = {
        id: this.report.id,
        data: this.report
      }
      try {
        await this.reportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
        return false
      }
      return true
    },
    closeCreateCompleteModal() {
      this.$router.push({
        name: 'allReportsSummaryList',
      })
    },
    isCrew1() {
      return this.crewNumber === '1'
    },
    isCrew2() {
      return !this.isCrew1()
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    reportNumPrefixDisp(row) {
      if (!row.damage_rank) { return }
      const selectedRankPrf = row.damage_rank.substring(0, 1)
      const reporteeDepInfo = this.reporteeDeps.find(e => e.key === row.reporteeDep)
      const reporteeGrpInfo = this.reporteeGroups.find(e => e.key === reporteeDepInfo.reportee_group)

      return selectedRankPrf === 'A' || selectedRankPrf === 'B' ? reporteeGrpInfo.disp1 : reporteeGrpInfo.disp2
    },
    receiveTermDisp1(row) {
      const fromDt = new Date(row.receive_dt_from)
      const toDt = new Date(row.receive_dt_to)
      const min = toDt.getMinutes() - fromDt.getMinutes()
      const hour = toDt.getHours() - fromDt.getHours()
      let ret = 0

      if (hour > 0) {
        ret = hour * 60
      }

      ret = ret + min
      return ret
    },
    requestContentKindDisp(row) {
      const requestContentKind = row.request_content_kind || ''
      const [requestContentKind1, requestContentKind2] = requestContentKind.split(',')

      if (row.reporteeDepNum === '1') {
        return requestContentKind1
      } else {
        return requestContentKind2 || requestContentKind1
      }
    },
    getReporteeTypeDataArray(fromArray, reporteeType) {
      const toArray = []
      fromArray.forEach(fa => {
        const reporteeDepInfo1 = this.reporteeDepMap[fa.reportee_dep1]
        if (reporteeDepInfo1.reportee_type === reporteeType) {
          const data = JSON.parse(JSON.stringify(fa))
          data.reporteeDepNum = '1'
          data.reporteeDep = fa.reportee_dep1
          data.reportNum = fa.report_num1
          toArray.push(data)
        }

        if (!fa.reportee_dep2) { return }

        const reporteeDepInfo2 = this.reporteeDepMap[fa.reportee_dep2]
        if (reporteeDepInfo2.reportee_type === reporteeType) {
          const data = JSON.parse(JSON.stringify(fa))
          data.reporteeDepNum = '2'
          data.reporteeDep = fa.reportee_dep2
          data.reportNum = fa.report_num2
          toArray.push(data)
        }
      })
      return toArray
    },
    getEmergencyContactReportsRankAB(reports) {
      return reports.filter(e => {
        const rankPrefix = e.damage_rank.substring(0, 1)
        return rankPrefix === 'A' || rankPrefix === 'B'
      })
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
