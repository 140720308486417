export default {
  methods: {
    prepareDispArrays() {
      this.preparePrecautionItems()
      this.prepareEmptyItems()
    },
    preparePrecautionItems() {
      const result = []
      const arr1 = [
        '※その他の欄には、出動前に総括調査員が特に指導した項目および点検調査員、運転者が',
        '点検時において危険を感じたこと、又は、ヒヤリ、ハット等があった場合に記載',
      ]
      arr1.forEach(e => {
        const num = result.length + 1
        result.push({num, val1: e})
      })
      // 行数最大まで入れる
      const rem = this.precautionItemCountMax - result.length
      Array.from({length: rem}).forEach(() => {
        result.push({num: '', val1: ''})
      })
      this.precautionItems = result
    },
    prepareEmptyItems() {
      const result = []
      // 行数最大まで入れる
      const rem = this.emptyItemCountMax - result.length
      Array.from({length: rem}).forEach(() => {
        const num = result.length + 1
        result.push({num: num, val1: ''})
      })
      console.log(rem, result)
      this.emptyItems = result
    },
    getChecklistItemsByChecklistInfos(checklistInfos, checklistItemMap, checklistItemCountMax) {
      const result = []
      checklistInfos.forEach(e => {
        const num = result.length + 1
        const item = checklistItemMap[e.key] || {}
        result.push({ num: num, val1: item.text, val2: e.val })
      })
      // 行数最大まで入れる
      const rem = checklistItemCountMax - result.length
      Array.from({ length: rem }).forEach(() => {
        result.push({ num: '', val1: '', val2: '' })
      })
      return result
    },
    setChecklistInfos(obj, prop, checklistItems) {
      obj[prop] = checklistItems.map(e => {
        return { key: e.key, val: '' }
      })
    },
  }
}
