<template>
  <div>
    <button class="btn color-btn"
        :style="{ 'background-color' : color }"
        @click="showColorPalette = !showColorPalette">
      {{selectedColorName(color)}}
    </button>
    <div class="row color-palette" v-if="showColorPalette">
      <button class="non-color" v-if="allowColorless"
        @click="setColor(null)">
        色なし
      </button>
      <button class="color" style="background-color:#000"
        @click="setColor('#000')"></button>
      <button class="color" style="background-color:#fff"
        @click="setColor('#fff')"></button>
      <button class="color" style="background-color:#808080"
        @click="setColor('#808080')"></button>
      <button class="color" style="background-color:#f00"
        @click="setColor('#f00')"></button>
      <button class="color" style="background-color:#ff8000"
        @click="setColor('#ff8000')"></button>
      <button class="color" style="background-color:#ff0"
        @click="setColor('#ff0')"></button>
      <button class="color" style="background-color:#0f0"
        @click="setColor('#0f0')"></button>
      <button class="color" style="background-color:#008000"
        @click="setColor('#008000')"></button>
      <button class="color" style="background-color:#00ffff"
        @click="setColor('#00ffff')"></button>
      <button class="color" style="background-color:#00f"
        @click="setColor('#00f')"></button>
      <button class="color" style="background-color:#bf00ff"
        @click="setColor('#bf00ff')"></button>
      <button class="color" style="background-color:#ff00bf"
        @click="setColor('#ff00bf')"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'color-picker',
  props: {
    color: {
      type: String,
      default: null,
    },
    allowColorless: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedColor: null,
      showColorPalette: false,
    }
  },
  methods: {
    setColor(colorStr) {
      this.showColorPalette = false
      this.$emit('color-change', colorStr)
    },
    selectedColorName(color) {
      let ret = '色なし'
      switch (color) {
        case '#000': ret = '黒'; break
        case '#fff': ret = '白'; break
        case '#808080': ret = '灰色'; break
        case '#f00': ret = '赤'; break
        case '#ff8000': ret = '橙'; break
        case '#ff0': ret = '黄'; break
        case '#0f0': ret = '緑'; break
        case '#008000': ret = '深緑'; break
        case '#00ffff': ret = '水色'; break
        case '#00f': ret = '青'; break
        case '#bf00ff': ret = '紫'; break
        case '#ff00bf': ret = 'ピンク'; break
        default: break
      }
      return ret
    },
  },
}
</script>

<style lang="scss" scoped>
.color-btn {
  border: 1px solid #ddd;
}
.color-palette {
  background-color: #fff;
  border: solid 1px #ddd;
  position: absolute;
  top: 110%;
  left: 14px;
  padding: 6px 10px;
  width: 96px;
  z-index: 101;

  .color {
    border: solid 1px #ddd;
    display: inline-block;
    height: 30px;
    width: 30px;
    margin: 2px;
    border-radius: 4px;
  }
  .non-color {
    border: solid 1px #ddd;
    display: inline-block;
    margin: 2px;
    background-color: #fff;
    border-radius: 4px;
    height: 30px;
    width: 62px;
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>
