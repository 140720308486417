<template src="./template.html"></template>

<script>
import Vue from 'vue'
import PRT from '@/consts/imprint'
import emergencyInspectReportApi from '@/api/emergencyInspectReport'
import masterMixin from '@/mixin/masterMixin'
import imprintPhotoMixin from '@/mixin/imprintPhotoMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import { reportNumPaddedDisp } from '@/lib/dispHelper'

export default {
  name: 'printEmergencyInspect',
  props: ['id'],
  data() {
    return {
      reporteeDepMap: {},
      reports: [],
      isImprint: false,
      managerInspectorCivilImprint: null,
      managerInspectorFacilityImprint: null,
      inspectorImprint: null,

      isUpdating: false,
    }
  },
  computed: {
    hasReporteeDepCivil() {
      const reporteeDepInfo1 = this.reporteeDepMap[this.reports[0].reportee_dep1]
      const reporteeDepInfo2 = this.reporteeDepMap[this.reports[0].reportee_dep2]
      return (reporteeDepInfo1 && reporteeDepInfo1.reportee_type === 'civil') ||
        (reporteeDepInfo2 && reporteeDepInfo2.reportee_type === 'civil')
    },
    hasReporteeDepFacility() {
      const reporteeDepInfo1 = this.reporteeDepMap[this.reports[0].reportee_dep1]
      const reporteeDepInfo2 = this.reporteeDepMap[this.reports[0].reportee_dep2]
      return (reporteeDepInfo1 && reporteeDepInfo1.reportee_type === 'facility') ||
        (reporteeDepInfo2 && reporteeDepInfo2.reportee_type === 'facility')
    },
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
    })
    this.getReport_()
    this.isImprint = PRT.IMPRINT_SETTINGS.ENABLE_EMERGENCYINSPECT
  },
  mixins: [masterMixin, imprintPhotoMixin, errorHandleMixin],
  methods: {
    getReport_() {
      emergencyInspectReportApi.show({id: this.id})
        .then(this.waitForMasters)
        .then(({ data }) => {
          const inspectContentDisp = Vue.filter('inspectContentDisp')
          data.inspectContentDisp = inspectContentDisp(data)
          data.reporteeDepDisp1 = this.reporteeDepDisp(data.reportee_dep1)
          data.reporteeDepDisp2 = this.reporteeDepDisp(data.reportee_dep2)

          const rh = data.report_header
          data.manager_inspector_civil = rh.manager_inspector_civil
          data.manager_inspector_facility = rh.manager_inspector_facility
          data.inspector = rh[`inspector${data.crew_number}`]

          const promises = [
            this.getImprintPhoto('manager_inspector_civil', data.manager_inspector_civil, rh.dt),
            this.getImprintPhoto('manager_inspector_facility', data.manager_inspector_facility, rh.dt),
            this.getImprintPhoto('inspector', data.inspector, rh.dt)
          ]
          Promise.all(promises).then((results) => {
            this.managerInspectorCivilImprint = results[0]
            this.managerInspectorFacilityImprint = results[1]
            this.inspectorImprint = results[2]
          }).catch((error) => {
            this.handleErrorResponse(error)
          })

          const repairCompany = data.repair_company || ''
          data.repairCompaniesDisp = repairCompany.replace(/,/g, '、')

          // 途中からいれたので、古い形式のデータを救済
          const requestorParts = data.requestor.split('###')
          if (requestorParts.length !== 3) {
            data.requestorPart1 = ''
            data.requestorPart2 = data.requestor
            data.requestorPart3 = ''
          } else {
            const [requestorPart1, requestorPart2, requestorPart3] = requestorParts
            data.requestorPart1 = requestorPart1 || ''
            data.requestorPart2 = requestorPart2 || ''
            data.requestorPart3 = requestorPart3 || ''
          }

          // 途中からいれたので、古い形式のデータを救済
          const requestContentKind = data.request_content_kind || ''
          const requestContentKinds = requestContentKind.split(',')
          const [requestContentKind1, requestContentKind2] = requestContentKinds
          data.requestContentKind1 = requestContentKind1 || ''
          data.requestContentKind2 = requestContentKind2 || ''

          // 途中からいれたので、古い形式のデータを救済
          const requestContentDetail = data.request_content_detail || ''
          data.request_content_detail = requestContentDetail.replace(/###/g, '')

          // 出動日時及び場所は出動開始日時の昇順で表示する
          let inspectDtPropsForDisp = ['inspect_dt1', 'inspect_dt2']
          let inspectHighwayPropsForDisp = ['inspect_highway1', 'inspect_highway2']
          if (
            data.inspect_dt1_from &&
            data.inspect_dt2_from &&
            data.inspect_dt1_from > data.inspect_dt2_from
          ) {
            inspectDtPropsForDisp = [inspectDtPropsForDisp[1], inspectDtPropsForDisp[0]]
            inspectHighwayPropsForDisp = [inspectHighwayPropsForDisp[1], inspectHighwayPropsForDisp[0]]
          }
          data.inspectDtPropsForDisp = inspectDtPropsForDisp
          data.inspectHighwayPropsForDisp = inspectHighwayPropsForDisp

          data.other_props = data.other_props || {}

          // 表示行調整用
          const freeInputs = ['inspect']
          freeInputs.forEach(propPrefix => {
            const prop = `${propPrefix}_free_input_chousei`
            if (data.other_props[prop] === undefined || data.other_props[prop] === null) {
              data.other_props[prop] = 0
            }
          })

          this.reports = [data]
        })
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    async updateFreeInputChousei(report, propPrefix, val) {
      if (this.isUpdating) { return }

      this.isUpdating = true
      try {
        const otherProps = JSON.parse(JSON.stringify(report.other_props))
        const prop = `${propPrefix}_free_input_chousei`
        otherProps[prop] += val
        const obj = {
          id: report.id,
          data: { other_props: otherProps },
        }
        await emergencyInspectReportApi.update(obj)
        await this.getReport_()
        this.isUpdating = false
      } catch (err) {
        await this.getReport_()
        this.isUpdating = false
      }
    },
    reportNumPaddedDisp,
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
