<template>
  <div id="app">
    <global-header></global-header>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
import axios from 'axios'
import GlobalHeader from '@/components/App/GlobalHeader'
import GlobalFooter from '@/components/App/GlobalFooter'
import routeMixin from '@/mixin/routeMixin'
import masterMixin from '@/mixin/masterMixin'
import * as apiConsts from '@/consts/api.js'

export default {
  name: 'app',
  created() {
    this.setAxios()
    this.loadMasters()

    if (!this.isGuestPage()) {
      this.$store.dispatch('user/getMe')
        .then(() => {
          // nothing to do
        })
        .catch(e => {
          this.$router.push({name: 'login'})
        })
    }
  },
  mixins: [routeMixin, masterMixin],
  methods: {
    setAxios() {
      // base url
      axios.defaults.baseURL = apiConsts.baseURL

      // token
      const token = this.$store.getters['localStorage/serverToken']
      if (token) {
        axios.defaults.headers.Authorization = `Bearer ${token}`
      }

      // go to login if 401 is returned on response
      axios.interceptors.response.use(
        response => {
          return response
        },
        error => {
          let st = 400
          if (error.response) {
            st = error.response.status
          }
          if (st === 401 && !this.isGuestPage()) {
            window.setTimeout(() => {
              location.href = '/login'
            }, 0)
          }
          return Promise.reject(error)
        }
      )
    },
  },
  components: { GlobalHeader, GlobalFooter }
}
</script>

<style lang="scss">
  @import '../../style/global_vars.scss';
  @import '../../style/app.scss';
</style>
