export default {
  methods: {
    isGuestPage() {
      const arr = [
        'login',
      ]
      return arr.indexOf(this.$route.name) !== -1
    },
    isPrintPage() {
      return this.$route.name.startsWith('print')
    },
  }
}
