import { fabric } from 'fabric'

fabric.LineArrow = fabric.util.createClass(fabric.Line, {
  type: 'lineArrow',
  initialize: function(element, options) {
    options || (options = {})
    this.callSuper('initialize', element, options)
  },
  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'))
  },
  _render: function(ctx) {
    this.callSuper('_render', ctx)
    if ((this.width === 0 && this.height === 0) || !this.visible) {
      return
    }
    ctx.save()
    const xDiff = this.x2 - this.x1
    const yDiff = this.y2 - this.y1
    const angle = Math.atan2(yDiff, xDiff)
    const multiplier = this.strokeWidth / 4
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2)
    ctx.rotate(angle)
    // リサイズ時に矢印のサイズを変更しないため
    ctx.scale(1 / this.scaleX, 1 / this.scaleY)
    ctx.beginPath()
    ctx.moveTo(5 * multiplier, 0)
    ctx.lineTo(-10 * multiplier, 10 * multiplier)
    ctx.lineTo(-10 * multiplier, -10 * multiplier)
    ctx.closePath()
    ctx.fillStyle = this.stroke
    ctx.fill()
    ctx.restore()
  }
})
fabric.LineDoubleArrow = fabric.util.createClass(fabric.LineArrow, {
  type: 'lineDoubleArrow',
  initialize: function(element, options) {
    options || (options = {})
    this.callSuper('initialize', element, options)
  },
  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'))
  },
  _render: function(ctx) {
    this.callSuper('_render', ctx)
    if ((this.width === 0 && this.height === 0) || !this.visible) {
      return
    }
    ctx.save()
    const xDiff = this.x1 - this.x2
    const yDiff = this.y1 - this.y2
    const angle = Math.atan2(yDiff, xDiff)
    const multiplier = this.strokeWidth / 4
    ctx.translate((this.x1 - this.x2) / 2, (this.y1 - this.y2) / 2)
    ctx.rotate(angle)
    // リサイズ時に矢印のサイズを変更しないため
    ctx.scale(1 / this.scaleX, 1 / this.scaleY)
    ctx.beginPath()
    ctx.moveTo(5 * multiplier, 0)
    ctx.lineTo(-10 * multiplier, 10 * multiplier)
    ctx.lineTo(-10 * multiplier, -10 * multiplier)
    ctx.closePath()
    ctx.fillStyle = this.stroke
    ctx.fill()
    ctx.restore()
  }
})
fabric.ExtIText = fabric.util.createClass(fabric.Textbox, {
  type: 'ext-i-text',
  writingMode: 'horizontal',
  _renderChars: function(method, ctx, line, left, top, lineIndex) {
    if (this.writingMode === 'horizontal') {
      this.callSuper('_renderChars', method, ctx, line, left, top, lineIndex)
      return
    }
    // 【、】など括弧の縦書きは未対応
    ctx.save()
    ctx.rotate(-90 * Math.PI / 180)
    // 文字が上にズレないよう調整
    const charHeight = this.getHeightOfChar(line, 0)
    ctx.translate(0, charHeight)
    const scaledHeight = this.getScaledHeight()
    let prevLineHeight = 0
    for (let i = 0; i < lineIndex; i++) {
      prevLineHeight += this.getHeightOfLine(i)
    }
    const maxWidth = this.__charBounds[lineIndex].reduce((max, curr) => {
      return max > curr.width ? max : curr.width
    }, 0)
    const nextLeft = -maxWidth - prevLineHeight + scaledHeight / 2
    let nextTop = left
    line.forEach((ch, idx) => {
      const charBox = this.__charBounds[lineIndex][idx]
      const fontSizeDiff = charBox.width - this.fontSize
      // 個別文字のフォントサイズが異なる場合該当文字のズレが発生するため、補完
      nextTop += fontSizeDiff
      this._renderChar(method, ctx, lineIndex, idx, ch, nextLeft, nextTop)
      // 次文字表示前に表示文字のサイズと上記補完分を戻す
      nextTop += charBox.height - fontSizeDiff
    })
    ctx.restore()
  }
})

export {
  fabric,
}
