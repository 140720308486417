<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import workResultTachographApi from '@/api/workResultTachograph'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'

export default {
  name: 'workResultTachographList',
  data() {
    return {
      api: workResultTachographApi,

      search: {
        dtFrom: null,
        dtTo: null,
      },
      hasInputError: false,

      list: [],
      listItemsPerPage: 20,
      currentPage: 1,

      reportToCreate: null,
      createComplete: false,
      showCreateErrMsg1: false,
      reportToDelete: null,

      timeslots: [],
    }
  },
  computed: {
    ...mapState('user', {
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_tenken: state => state.has_role_tenken,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmCreateModal() {
      return this.reportToCreate !== null
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  async mounted() {
    const { user } = await this.ensureUserAndMasters()
    const { dtStrFrom, dtStrTo } = this.getListInitialSearchParams(user.env, { isRed: true })
    this.search.dtFrom = dtStrFrom
    this.search.dtTo = dtStrTo

    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.timeslots = lovs.timeslot.vals_inuse
    })

    this._doSearch()
  },
  mixins: [masterMixin, dateMixin, listPageMixin],
  methods: {
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dtFrom)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dtTo)) {
        this.hasInputError = true
      }
      const diffSec = new Date(this.search.dtTo) - new Date(this.search.dtFrom)
      if (diffSec > 2 * 365 * 86400 * 1000) {
        this.hasInputError = true
      }
    },
    doSearch() {
      if (this.hasInputError) { return }
      this.pushQueryParamsToHistory({
        dtStrFrom: this.search.dtFrom,
        dtStrTo: this.search.dtTo,
      })
      this._doSearch()
    },
    _doSearch() {
      this.list = []
      this.currentPage = 1

      const obj = {
        from: new Date(this.search.dtFrom),
        to: new Date(this.search.dtTo)
      }
      this.api.index(obj)
        .then(this.waitForMasters)
        .then(({ data }) => {
          this.list = this.convData(data)
        })
    },
    convData(data) {
      const dataMap = {}
      for (const e of data) {
        const key1 = new Date(e.report_header.dt).valueOf()
        const key2 = e.report_header.timeslot
        dataMap[`${key1}-${key2}`] = e
      }

      const ret = []
      const timeslots = this.timeslots.slice().reverse()
      const dtFrom = new Date(this.search.dtFrom)
      const dtTo = new Date(this.search.dtTo)
      let dateDiff = parseInt((dtTo.valueOf() - dtFrom.valueOf()) / 86400 / 1000)
      do {
        const dt = new Date(dtFrom.valueOf() + dateDiff * 86400 * 1000)
        for (const timeslot of timeslots) {
          const key1 = dt.valueOf()
          const key2 = timeslot.key
          let obj = dataMap[`${key1}-${key2}`]
          if (!obj) {
            obj = {
              report_header: {
                dt: dt,
                timeslot: timeslot.key,
              }
            }
          }
          obj.exists = !!obj.id
          ret.push(obj)
        }
      } while (dateDiff-- > 0)
      return ret
    },
    confirmCreateReport(e) {
      this.createComplete = false
      this.showCreateErrMsg1 = false
      this.reportToCreate = e
    },
    createReport() {
      this.createComplete = false
      this.showCreateErrMsg1 = false
      const data = {
        dt: this.reportToCreate.report_header.dt,
        timeslot: this.reportToCreate.report_header.timeslot,
      }
      this.api.create({ data })
        .then(({ data }) => {
          this.reportToCreate = data
          this.createComplete = true
        })
        .catch(err => {
          const errRes = err.response.data
          if (errRes.reason === 'rh_not_found') {
            this.showCreateErrMsg1 = true
          }
        })
    },
    confirmDeleteReport(e) {
      this.reportToDelete = e
    },
    deleteReport() {
      const obj = {
        id: this.reportToDelete.id,
      }
      this.api.delete(obj)
        .then(() => {
          this.reportToDelete = null
          this._doSearch()
        })
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
