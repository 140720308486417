<template>
  <img class="http-img" :src="internalSrc" @click="onClick" draggable="false">
</template>

<script>
import mediaMixin from '@/mixin/mediaMixin'

export default {
  name: 'http-img',
  props: {
    src: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      internalSrc: '',
    }
  },
  watch: {
    src() {
      this.setImageUrl()
    }
  },
  mounted() {
    this.setImageUrl()
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.internalSrc)
  },
  mixins: [mediaMixin],
  methods: {
    setImageUrl() {
      this.internalSrc = ''
      this.getBlobUrl(this.src)
        .then(({ url }) => {
          this.internalSrc = url
        })
    },
    onClick(e) {
      this.$emit('click', e)
    }
  },
}
</script>

<style lang="scss" scoped></style>
