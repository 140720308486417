import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/report_headers'), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl('/report_headers'), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/report_headers/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/report_headers/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/report_headers/${id}`))
  },
}
