import masterApi from '@/api/master'

export default {
  methods: {
    async getImprintPhoto(listType, val, dt) {
      const obj = {
        lovName: listType,
        lovVal: val,
        dt: dt,
      }
      const { data } = await masterApi.getLovPhoto(obj)
      return data
    },
  }
}
