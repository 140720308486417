<template>
<div class="reportee-dep-badge" :class="clzObj">
  {{dispStr}}
</div>
</template>

<script>
export default {
  name: 'reportee-dep-badge',
  props: {
    reporteeDep: {
      type: String,
    },
    dispType: {
      type: String,
      default: 'disp1',
    },
  },
  data() {
    return {
      reporteeDepMap: {},
      reporteeGroupMap: {},
    }
  },
  computed: {
    dispStr() {
      const reporteeDepObj = this.reporteeDepMap[this.reporteeDep]
      if (!reporteeDepObj) { return '' }
      const groupMap = this.reporteeGroupMap[reporteeDepObj.reportee_group]
      return groupMap[this.dispType]
    },
    shouldShow() {
      return !!this.dispStr
    },
    clzObj() {
      const reporteeDepObj = this.reporteeDepMap[this.reporteeDep]
      if (!reporteeDepObj) { return { hide: true } }
      const depKey = reporteeDepObj.reportee_group.toLowerCase()
      return {
        [depKey]: true,
      }
    }
  },
  mounted() {
    window.master.$promise.then(() => {
      const lovs = window.master.lovs
      this.reporteeDepMap = lovs.reportee_dep.map
      this.reporteeGroupMap = lovs.reportee_group.map
    })
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.reportee-dep-badge {
  width: 65px;
  height: 65px;
  border: 4px solid;
  border-radius: 50%;
  line-height: 1.6;
  text-align: center;
  font-family: sans-serif;
  font-size: 36px;
  color: #777;

  &.n { border-color: #777; }
  &.h { border-color: #777; }
  &.k { border-color: #777; }

  &.hide {
    display: none;
  }
}
</style>
