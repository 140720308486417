import axios from 'axios'
import * as utils from './apiUtils'

const resourceName = 'work_result_tachographs'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl(`/${resourceName}`), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl(`/${resourceName}`), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/${resourceName}/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/${resourceName}/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/${resourceName}/${id}`))
  },
}
