import axios from 'axios'
import * as utils from './apiUtils'

export default {
  login(data) {
    // wrap data in 'user' object for devise.
    return axios.post(utils.getApiUrl('/users/sign_in'), {
      user: data
    })
  },
  logout() {
    return axios.delete(utils.getApiUrl('/users/sign_out'))
  },
  getMe() {
    return axios.get(utils.getApiUrl('/users/me'))
  }
}
