import Vue from 'vue'

export default {
  methods: {
    _getListInitialSearchParams(env, { isRed } = {}) {
      // 直近30日 (開発環境では長め)
      const dateDiff = env === 'development' ? 200 : 30
      const now = new Date()
      const dtTo = isRed ? now : new Date(now.valueOf() + (86400 * 1000))
      const dtFrom = new Date(dtTo.valueOf() - (86400 * dateDiff * 1000))

      const dtFormat = Vue.filter('dtFormat')
      const dtStrFrom = dtFormat(dtFrom, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(dtTo, 'yyyy/mm/dd')
      return { dtStrFrom, dtStrTo }
    },
    getListSearchParamsFromUrl() {
      const q = this.$route.query
      let dtStrFrom = ''
      let dtStrTo = ''
      if (q['dt-from']) {
        const str = q['dt-from'].replace(/-/g, '/')
        if (this.isDateString(str)) {
          dtStrFrom = str
        }
      }
      if (q['dt-to']) {
        const str = q['dt-to'].replace(/-/g, '/')
        if (this.isDateString(str)) {
          dtStrTo = str
        }
      }
      return { dtStrFrom, dtStrTo }
    },
    getListInitialSearchParams(env, { isRed } = {}) {
      let { dtStrFrom, dtStrTo } = this._getListInitialSearchParams(env, { isRed })
      const paramsFromUrl = this.getListSearchParamsFromUrl()
      if (paramsFromUrl.dtStrFrom) {
        dtStrFrom = paramsFromUrl.dtStrFrom
      }
      if (paramsFromUrl.dtStrTo) {
        dtStrTo = paramsFromUrl.dtStrTo
      }
      return { dtStrFrom, dtStrTo }
    },
    pushQueryParamsToHistory(params) {
      // Push state so that we are happy when
      // coming back from detail page.
      const query = {}
      if (params.dtStrFrom) {
        query['dt-from'] = params.dtStrFrom.replace(/\//g, '-')
      }
      if (params.dtStrTo) {
        query['dt-to'] = params.dtStrTo.replace(/\//g, '-')
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
  }
}
